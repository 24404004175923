/* eslint-disable no-unused-vars */
import React, { useContext } from "react";

import MKTypography from "components/MKTypography";
import TableOne from "layouts/sections/elements/tables/components/TableOne";
import MKBox from "components/MKBox";
import { Grid } from "@mui/material";

// context
import AuthContext from "context/AuthContext";

const ContactList = () => {
  const { state } = useContext(AuthContext);

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <MKTypography className="text-center" mt={2} mb={2}>
          Contact List
        </MKTypography>
        <MKBox>
          <TableOne
            contactList={state?.userProfile?.contact_list}
            contactLength={state?.userProfile?.contact_list.length}
          />
        </MKBox>
      </Grid>
    </Grid>
  );
};

export default ContactList;
