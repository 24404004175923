import React, { useContext } from "react";
import AuthContext from "context/AuthContext";

import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import MKButton from "components/MKButton";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const SubscriptionModal = ({ open, onClose, featureName }) => {
  const { dispatch } = useContext(AuthContext);
  const handleUpgrade = () => {
    dispatch.updateCurrentSection("billing");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <MKBox display="flex" alignItems="center" justifyContent="space-between">
          <MKBox display="flex" alignItems="center">
            <LockIcon sx={{ color: "warning.main", mr: 1 }} />
            <MKTypography variant="h6">Premium Feature</MKTypography>
          </MKBox>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </MKBox>
      </DialogTitle>
      <DialogContent>
        <MKBox py={2}>
          <MKTypography variant="body1" mb={2}>
            {featureName ? (
              <>
                The <strong>{featureName}</strong> feature requires a premium subscription.
              </>
            ) : (
              "This feature requires a premium subscription."
            )}
          </MKTypography>
          <MKTypography variant="body2" color="text">
            Upgrade your account to unlock all premium features:
          </MKTypography>
          <MKBox component="ul" mt={1}>
            <MKTypography component="li" variant="body2" color="text">
              Unlimited Links
            </MKTypography>
            <MKTypography component="li" variant="body2" color="text">
              Advanced Analytics
            </MKTypography>
            <MKTypography component="li" variant="body2" color="text">
              Unlimited QR Code Download
            </MKTypography>
            <MKTypography component="li" variant="body2" color="text">
              And more...
            </MKTypography>
          </MKBox>
        </MKBox>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <MKButton variant="text" color="secondary" onClick={onClose}>
          Maybe Later
        </MKButton>
        <MKButton variant="gradient" color="primary" onClick={handleUpgrade}>
          Upgrade Now
        </MKButton>
      </DialogActions>
    </Dialog>
  );
};

SubscriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  featureName: PropTypes.string,
};

export default SubscriptionModal;
