/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, useCallback } from "react";

import MKTypography from "components/MKTypography";
import MKSpinner from "components/MKSpinner";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
} from "@mui/material";

// context
import AuthContext from "context/AuthContext";

// api call
import { getUserAnalytics } from "api";

const Analytics = () => {
  const { state } = useContext(AuthContext);
  const [analyticsData, setAnalyticsData] = useState({ devices: null, locations: null });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  //const fetched = useRef(false); // Ref to track if data has been fetched

  const getUserDetails = useCallback(async () => {
    const { rand_: userId } = state.userProfile;
    if (!userId) {
      setError("User ID not available");
      setLoading(false);
      return;
    }
    if (!navigator.onLine) {
      setError("You are offline. Please check your internet connection.");
      setLoading(false);
      return;
    }
    try {
      const res = await getUserAnalytics(userId);
      if (res.success && res.response) {
        setAnalyticsData({
          devices: res.response.deviceCounts,
          locations: res.response.locationCounts,
        });
      } else {
        setError("Failed to fetch analytics data");
      }
    } catch (e) {
      setError(e.message || "An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  }, [state.userProfile]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  if (loading) return <MKSpinner color="dark" size={40} />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!analyticsData.devices || !analyticsData.locations)
    return <Typography>No data available</Typography>;

  const createDataTable = (title, data) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{ padding: "0", display: "table-header-group" }}>
          <TableRow>
            <TableCell width="50%" sx={{ fontWeight: "bold" }}>
              {title}
            </TableCell>
            <TableCell width="50%" align="right" sx={{ fontWeight: "bold" }}>
              Count
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(data).map(([key, value]) => (
            <TableRow key={key} sx={{ "&:nth-of-type(odd)": { backgroundColor: "action.hover" } }}>
              <TableCell component="th" scope="row" sx={{ py: 2 }}>
                {key}
              </TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <MKTypography className="text-center" mt={2} mb={2}>
          Analytics
        </MKTypography>
        <Stack
          sx={({ breakpoints }) => ({
            flexDirection: "column",
            [breakpoints.up("md")]: {
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
            },
          })}
          spacing={2}
        >
          {createDataTable("Location(Country,City)", analyticsData.locations)}
          {createDataTable("Devices", analyticsData.devices)}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Analytics;
