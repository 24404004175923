import React, { useState, useRef, useContext } from "react";
import {
  Card,
  Typography,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Snackbar,
  Fade,
  TextField,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Stack,
  Tooltip,
} from "@mui/material";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKSpinner from "components/MKSpinner";
import MKModal from "components/MKModal";
import GrainIcon from "@mui/icons-material/Grain";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import businessCard from "assets/images/taprr/taprr_black_card.png";
// App context
import AuthContext from "context/AuthContext";
import { customCardPrint, getCookie } from "api";

import taprrLogo from "assets/images/taprr/logo_400_400.png";
const businessCardOptionArr = ["1 x Custom Taprr NFC Card - £9.99"];

const CreditCard = ({ name, bgColor, textColor, fontFamily, position, logo, subtitle }) => {
  const getPositionStyles = () => {
    switch (position) {
      case "Top left":
        return { top: 20, left: 20 };
      case "Top right":
        return { top: 20, right: 20 };
      case "Bottom left":
        return { bottom: 20, left: 20 };
      case "Bottom right":
        return { bottom: 20, right: 20 };
      default:
        return { top: 20, left: 20 };
    }
  };

  return (
    <Card
      sx={{
        width: 305,
        height: 195,
        bgcolor: bgColor,
        color: textColor,
        padding: 2,
        position: "relative",
        border: "1px solid #000000",
        boxShadow:
          "1rem 1rem 0rem 0rem rgb(23 23 23 / 10%), 2rem 1rem 5rem 3rem rgb(80 74 74 / 0%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <MKBox>
          <Typography
            variant="h6"
            style={{
              fontFamily: fontFamily,
              color: textColor,
              position: "absolute",
              lineHeight: 1.2,
              ...getPositionStyles(),
            }}
          >
            {name}
            <br />
            <Typography variant="caption">{subtitle}</Typography>
          </Typography>
        </MKBox>
        {logo && (
          <img
            src={logo}
            alt="logo"
            style={{
              width: "50%",
              height: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </Box>
    </Card>
  );
};

const CustomizationPanel = ({
  name,
  setName,
  subtitle,
  setSubTitle,
  bgColor,
  setBgColor,
  textColor,
  setTextColor,
  fontFamily,
  setFontFamily,
  position,
  setPosition,
  logo,
  setLogo,
  setOpenToolSnack,
  setResponseMssg,
  setSaveLoading,
  saveLoading,
}) => {
  const ref = useRef();
  const jtoken = getCookie("taprr-token");
  const { state } = useContext(AuthContext);
  const fetched = useRef(false); // Ref to track if data has been fetched
  const [disableBtn, setDisableBtn] = useState(false);

  const handleBgColorChange = (e) => {
    setBgColor(e.target.value);
  };

  const handleTextColorChange = (e) => {
    setTextColor(e.target.value);
  };

  const handleFontFamilyChange = (e) => {
    setFontFamily(e.target.value);
  };

  const handlePositionChange = (e) => {
    setPosition(e.target.value);
  };

  const handleLogoChange = (e) => {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setLogo(reader.result);
      };
    }
  };

  const sendDesignApi = async () => {
    const { rand_: userId } = state.userProfile;
    if (!fetched.current) {
      const data = new FormData();
      setDisableBtn(true);
      if (logo !== taprrLogo) {
        // Check if a custom logo has been uploaded
        data.append("file", ref.current.files[0]); // Send the custom logo
      } else {
        // Send the default logo
        const defaultLogo = await fetch(taprrLogo);
        const blob = await defaultLogo.blob();
        data.append("file", blob, "taprr_logo.png");
      }
      data.append("id", userId);
      data.append("customername", name);
      data.append("namesubtitle", subtitle);
      data.append("selectedcolor", bgColor);
      data.append("selectedtextcolor", textColor);
      data.append("fontFamilycard", fontFamily);
      data.append("textposition", position);
      data.append("jwtoken", jtoken);
      //
      if (navigator.onLine) {
        setSaveLoading(!saveLoading);
        const res = await customCardPrint(data);
        if (res.success) {
          fetched.current = true;
          setSaveLoading(false);
          setResponseMssg("Successfully sent");
          setOpenToolSnack({
            open: true,
            Transition: Fade,
          });
        }
      } else {
        setSaveLoading(false);
        setError("You're offline. Please check your network connection...");
      }
    }
  };

  return (
    <MKBox>
      <MKInput
        label="Name on Card"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <MKInput
        label="Subtitle"
        value={subtitle}
        onChange={(e) => setSubTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <MKBox>
        <MKTypography textAlign="start" variant="button">
          Select Background Color
        </MKTypography>
        <MKBox>
          <FormControl fullWidth>
            <RadioGroup value={bgColor} onChange={handleBgColorChange} row>
              <FormControlLabel value="#000000" control={<Radio />} label="Black" />
              <FormControlLabel value="#FFFFFF" control={<Radio />} label="White" />
            </RadioGroup>
          </FormControl>
        </MKBox>
      </MKBox>
      <MKInput
        type="color"
        label="Select Text Color"
        value={textColor}
        fullWidth
        rows={5}
        onChange={handleTextColorChange}
        margin="normal"
        InputProps={{
          className: "customColorSelect",
        }}
      />
      {/* <MKBox>
        <FormControl fullWidth margin="normal">
          <InputLabel>Font</InputLabel>
          <Select
            value={fontFamily}
            label="Font Family"
            onChange={handleFontFamilyChange}
            sx={{ padding: "10px !important" }}
          >
            <MenuItem value="Copperplate">Copperplate</MenuItem>
            <MenuItem value="Courier">Courier</MenuItem>
            <MenuItem value="Fantasy">Fantasy</MenuItem>
            <MenuItem value="Cursive">Cursive</MenuItem>
            <MenuItem value="Monaco">Monaco</MenuItem>
            <MenuItem value="Monospace">Monospace</MenuItem>
          </Select>
        </FormControl>
      </MKBox> */}
      <MKBox mb={2}>
        <FormControl fullWidth margin="normal">
          <InputLabel>Position</InputLabel>
          <Select
            value={position}
            label="Position"
            onChange={handlePositionChange}
            sx={{ padding: "10px !important" }}
          >
            <MenuItem value="Top left">Top Left</MenuItem>
            <MenuItem value="Top right">Top Right</MenuItem>
            <MenuItem value="Bottom left">Bottom Left</MenuItem>
            <MenuItem value="Bottom right">Bottom Right</MenuItem>
          </Select>
        </FormControl>
      </MKBox>
      <input
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        style={{ display: "none" }}
        onChange={handleLogoChange}
        ref={ref}
      />
      <MKButton variant="outlined" color="dark" disabled={saveLoading}>
        <label htmlFor="contained-button-file">Upload Your Logo</label>
      </MKButton>
      <MKBox mt={3}>
        <MKButton
          variant="contained"
          color="primary"
          onClick={() => {
            sendDesignApi();
          }}
          disabled={disableBtn}
        >
          {saveLoading ? <MKSpinner color="dark" size={20} /> : "Send Designs"}
        </MKButton>
      </MKBox>
    </MKBox>
  );
};

const CustomCard = () => {
  const [name, setName] = useState("John Doe");
  const [subtitle, setSubTitle] = useState("Associate");
  const [bgColor, setBgColor] = useState("#FFFFFF");
  const [textColor, setTextColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Copperplate");
  const [position, setPosition] = useState("Bottom left");
  const [logo, setLogo] = useState(taprrLogo);
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  const [responseMssg, setResponseMssg] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [openCardRequestModal, setCardRequestModal] = useState(false);
  const [preferredCard, setPreferredCard] = useState("1 x Custom Taprr NFC Card - £9.99");

  const handleCardTypeChange = (e) => setPreferredCard(e.target.value);
  const requestCardRequest = () => {
    window.open("https://buy.stripe.com/28o8wA3i66sldIkfYY");
  };

  const handleClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  return (
    <Container>
      <Snackbar
        open={openToolSnack.open}
        onClose={handleClose}
        TransitionComponent={openToolSnack.Transition}
        message={responseMssg}
        className="snackBar_container"
      />
      <Grid mb={6} mt={5}>
        <MKBox>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={3}
            alignItems={{ xs: "flex-start", sm: "center" }}
          >
            <MKTypography mt={1} mb={2}>
              Buy a Taprr logo Digital Card
            </MKTypography>
            <MKButton
              variant="gradient"
              color="primary"
              size="small"
              onClick={() => setCardRequestModal(!openCardRequestModal)}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Buy business card
            </MKButton>
            <MKTypography
              variant="h4"
              fontWeight="bold"
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  display: "none",
                },
              })}
            >
              <Tooltip title="Taprr logo with your profile installed">
                <ContactSupportOutlinedIcon className="smallIcon" />
              </Tooltip>
            </MKTypography>
          </Stack>
        </MKBox>
        <MKModal
          title="Request Free Business Card / Tags "
          open={openCardRequestModal}
          confirm={requestCardRequest}
          cancel={setCardRequestModal}
          maxWidth={750}
        >
          <MKTypography variant="body2" mt={1}>
            Introducing the smart way to share your digital contacts to gain more leads for sales
            and marketing.
          </MKTypography>
          <MKBox mt={5} mb={2}>
            <TextField
              select
              label="Please Select"
              name="type"
              value={preferredCard}
              onChange={handleCardTypeChange}
              className="width-100"
              SelectProps={{
                className: "fanbies_input_custom_v1",
              }}
            >
              {businessCardOptionArr?.map((option) => (
                <MenuItem key={`${option}-method`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </MKBox>
          <Stack direction="row" alignItems="flex-start" spacing={2} mb={1}>
            <MKBox
              sx={({ breakpoints }) => ({
                height: "250px",
                width: "280px",
                backgroundColor: "#fff",
                [breakpoints.up("md")]: {
                  position: "relative",
                },
              })}
              component="img"
              src={businessCard}
              alt="select one of the Taprr branded items"
            />
          </Stack>
          <MKBox>
            <List className="listItem">
              <ListItem className="listItem">
                <GrainIcon className="listItem__icon primary_color" />
                <ListItemText
                  className="listItem__text_sales maxWidth-sm"
                  primary="Connect with anyone both offline and online."
                />
              </ListItem>
              <ListItem className="listItem">
                <GrainIcon className="listItem__icon primary_color" />
                <ListItemText
                  className="listItem__text_sales maxWidth-sm"
                  primary="Works on all smart phones. Both Android and IPhone"
                />
              </ListItem>
              <ListItem className="listItem">
                <GrainIcon className="listItem__icon primary_color" />
                <ListItemText
                  className="listItem__text_sales maxWidth-sm"
                  primary="NFC/RFID Enabled."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <GrainIcon className="listItem__icon primary_color" />
                  <ListItemText
                    className="listItem__text_sales maxWidth-sm"
                    primary="Ships within 24hrs."
                  />
                </ListItemIcon>
              </ListItem>
            </List>
          </MKBox>
        </MKModal>
      </Grid>
      <Stack direction="row" spacing={2}>
        <MKTypography mt={1} mb={5}>
          Build your custom card and we ship to you!
        </MKTypography>
        <MKTypography
          variant="h4"
          fontWeight="bold"
          sx={({ breakpoints }) => ({
            [breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          <Tooltip title="Additional charges may be included">
            <ContactSupportOutlinedIcon className="smallIcon" />
          </Tooltip>
        </MKTypography>
      </Stack>
      <Grid container spacing={2}>
        <MKTypography mt={10} mb={2}></MKTypography>
        <Grid item xs={12} md={12} lg={7} alignContent={"center"}>
          <CreditCard
            name={name}
            bgColor={bgColor}
            textColor={textColor}
            fontFamily={fontFamily}
            position={position}
            logo={logo}
            subtitle={subtitle}
            sx={{ alignContent: "center" }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={5}>
          <CustomizationPanel
            name={name}
            setName={setName}
            subtitle={subtitle}
            setSubTitle={setSubTitle}
            bgColor={bgColor}
            setBgColor={setBgColor}
            textColor={textColor}
            setTextColor={setTextColor}
            fontFamily={fontFamily}
            setFontFamily={setFontFamily}
            position={position}
            setPosition={setPosition}
            logo={logo}
            setLogo={setLogo}
            openToolSnack={openToolSnack}
            setOpenToolSnack={setOpenToolSnack}
            responseMssg={responseMssg}
            setResponseMssg={setResponseMssg}
            setSaveLoading={setSaveLoading}
            saveLoading={saveLoading}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomCard;
