import { useState, useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import MKButton from "components/MKButton";
import Fade from "@mui/material/Fade";

import MKAnalyticsView from "components/MKAnalyticsView";

// context
import AuthContext from "context/AuthContext";

// api
import { getCookie, sendEmailConfirm, verifyEmail, getUserProfile } from "api";

function DashboardNavigation() {
  const [searchParams] = useSearchParams();
  const { state, dispatch } = useContext(AuthContext);
  const [isRequestConfirm, setIsRequestConfirm] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [responseMssg, setResponseMssg] = useState("");
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  const navigate = useNavigate();
  const jtoken = getCookie("taprr-token");

  useEffect(() => {
    const validateEmailParam = searchParams.get("validateemail");
    const token = searchParams.get("token");

    if (validateEmailParam && token) {
      handleVerification(validateEmailParam, token);
    }
  }, [searchParams]);

  // Effect to handle state updates
  // useEffect(() => {
  //   const location = window.location;
  //   if (location.state?.reload) {
  //     delete location.state.reload;
  //     window.location.reload();
  //   }
  // }, []);

  const handleVerification = async (email, token) => {
    setIsVerifying(true);
    try {
      const result = await verifyEmail(email, token);
      if (result?.success) {
        dispatch.updateEmailVerification(true);

        setResponseMssg("Email verified successfully!");
        setOpenToolSnack({
          open: true,
          Transition: Fade,
        });

        // Update user profile in context
        const userProfileResponse = await getUserProfile({
          jtoken: jtoken,
          username: state?.userProfile?.username,
        });
        if (userProfileResponse?.success) {
          dispatch.getDetails(userProfileResponse.response);
        }

        // Clean up URL and reload state
        setTimeout(() => {
          navigate("/admin", {
            replace: true,
            state: { reload: true },
          });
          window.location.reload();
        }, 1500); // Give time for the success message to be seen
      } else {
        throw new Error(result?.message || "Verification failed");
      }
    } catch (error) {
      console.error("Verification error:", error);
      setResponseMssg(error.message || "Email verification failed");
      setOpenToolSnack({
        open: true,
        Transition: Fade,
      });
    } finally {
      setIsVerifying(false);
    }
  };

  const sendEmailConfirmation = async () => {
    setIsRequestConfirm(true);
    try {
      const result = await sendEmailConfirm(jtoken, state?.userProfile?.email);

      if (result?.success) {
        setResponseMssg("Verification email sent successfully");
      } else {
        throw new Error(result?.message || "Failed to send verification email");
      }
    } catch (error) {
      setResponseMssg("Failed to send verification email");
    } finally {
      setOpenToolSnack({
        open: true,
        Transition: Fade,
      });
      setIsRequestConfirm(false);
    }
  };

  const handleClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  return (
    <Grid container item justifyContent="center" xs={12} mx="auto">
      <Snackbar
        open={openToolSnack.open}
        onClose={handleClose}
        TransitionComponent={openToolSnack.Transition}
        message={responseMssg}
        className="snackBar_container"
      />
      <AppBar position="static" style={{ paddingTop: 10 }}>
        {isVerifying ? (
          <Stack spacing={2} mx={3} mb={2}>
            <Alert icon={false} variant="filled" color="info">
              Verifying your email...
            </Alert>
          </Stack>
        ) : state?.userProfile?.isverified == 0 ? (
          <Stack spacing={2} mx={3} mb={2}>
            <Alert
              icon={false}
              variant="filled"
              color="dark"
              action={
                <MKButton
                  color="white"
                  size="small"
                  onClick={sendEmailConfirmation}
                  disabled={isRequestConfirm}
                  variant="outlined"
                >
                  {!isRequestConfirm ? "SEND" : "Email Sent..."}
                </MKButton>
              }
            >
              Verify your email, click 'Send': {state?.userProfile?.email}
            </Alert>
          </Stack>
        ) : null}
        <MKAnalyticsView />
      </AppBar>
    </Grid>
  );
}

export default DashboardNavigation;
