import { useEffect, useReducer, useState, useMemo, useCallback } from "react";
import { HelmetProvider } from "react-helmet-async";

// react-router components
import { Route, useLocation, Routes, useNavigate } from "react-router-dom";

// @mui material components
import { SnackbarProvider } from "context/SnackbarContext";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// App Context
import AuthContext from "context/AuthContext";
import SocialMediaContext from "context/SocialMediaContext";

// api call
import { getCookie, getInAppConfig } from "api";

// Cookie preference
import CookiePreference from "components/CookiePreference";

// routes
import indexRoutes from "pageRoutes";
import "./App.css";

// Themes app
import {
  DarkTheme,
  LightTheme,
  SkyTheme,
  OverlayTheme,
  SunsetTheme,
  NatureTheme,
  SnowTheme,
  CheeseTheme,
  MineralTheme,
  BluredTheme,
} from "assets/theme";

// fake data
import social from "data";
import { defaultProfilePic } from "utils";

export const TAPRR_DISPATCH = {
  LOGIN: "LOGIN",
  FETCH_DETAILS: "FETCH_DETAILS",
  FETCH_CONTACT_LIST_DETAILS: "FETCH_CONTACT_LIST_DETAILS",
  UPDATE_CUSTOMER_LINK: "UPDATE_CUSTOMER_LINK",
  UPDATE_CUSTOMER_LINK_ANIMATION: "UPDATE_CUSTOMER_LINK_ANIMATION",
  UPDATE_EMAIL_VERIFICATION: "UPDATE_EMAIL_VERIFICATION",
  SIGN_OUT: "SIGN_OUT",
  SIGN_UP: "SIGN_UP",
  FETCH_INAPP_VIDEO_RATES: "FETCH_INAPP_VIDEO_RATES",
  FETCH_INAPP_SOCIAL_MEDIA: "FETCH_INAPP_SOCIAL_MEDIA",
  UPDATE_PROFILE_PICTURE: "UPDATE_PROFILE_PICTURE",
  UPDATE_PROFILE_COVER_IMAGE: "UPDATE_PROFILE_COVER_IMAGE",
  CHANGE_THEME: "CHANGE_THEME",
  CREATE_UPDATE_MARKETPLACE_ITEM: "CREATE_UPDATE_MARKETPLACE_ITEM",
  UPDATE_LINK_APPEARANCE: "UPDATE_LINK_APPEARANCE",
  UPDATE_CONTACT_LIST_DETAILS: "UPDATE_CONTACT_LIST_DETAILS",
  UPDATE_USER_SUBCRIPTION: "UPDATE_USER_SUBCRIPTION",
  RESET_PROFILE_VIDEO: "RESET_PROFILE_VIDEO",
};

const taprrStore = "TAPRR_STORE";

export const initialState = {
  isRequest: false,
  error: null,
  isSignout: true,
  userToken: null,
  currentSection: "addlink",
  fanbiesMessageToolrates: [],
  fanbiesSupportedSocials: "",
  userProfile: {
    active: 0,
    bio: "",
    custom_links: [],
    marketplace_item: [],
    contact_list: [],
    email: "",
    mediaUrl: null,
    mypage: false,
    name: null,
    picture: defaultProfilePic,
    profileVideo: [],
    contactnumber: "",
    rand_: 0,
    remarks: "",
    vcard: "",
    slots: 0,
    theme: "DEFAULT",
    username: "",
    usertype: 0,
    video_message_fee: 0,
    video_message_status: 0,
    isverified: false,
    pageview: 0,
    subscription: null,
    isonboard: 0,
    cover_img: "",
  },
};

export default function App() {
  const { pathname } = useLocation();
  const [socialMediaLinks, setSocialMediaLinks] = useState(social);
  const navigate = useNavigate();
  const jtoken = getCookie("taprr-token");

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case TAPRR_DISPATCH.LOGIN:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, ...action.payload },
            isSignout: false,
          };
        case TAPRR_DISPATCH.SIGN_UP:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, ...action.payload },
            isSignout: false,
          };
        case TAPRR_DISPATCH.FETCH_DETAILS:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, ...action.payload },
          };
        case TAPRR_DISPATCH.UPDATE_PROFILE_PICTURE:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, picture: action.payload },
          };
        case TAPRR_DISPATCH.UPDATE_PROFILE_COVER_IMAGE:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, cover_img: action.payload },
          };
        case TAPRR_DISPATCH.RESET_PROFILE_VIDEO:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, profileVideo: action.payload },
          };
        case TAPRR_DISPATCH.SIGN_OUT:
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            userProfile: null,
          };
        case TAPRR_DISPATCH.UPDATE_CUSTOMER_LINK:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, custom_links: action.payload },
          };
        case TAPRR_DISPATCH.UPDATE_USER_SUBCRIPTION:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, subscription: action.payload },
          };
        case TAPRR_DISPATCH.UPDATE_EMAIL_VERIFICATION:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, isverified: action.payload },
          };
        case TAPRR_DISPATCH.UPDATE_CUSTOMER_LINK_ANIMATION:
          if (action.payload.animation) {
            const link = {
              ...prevState.userProfile.custom_links.find(
                (item) => item.id === action.payload.linkId
              ),
              linkAnimation: action.payload.animation,
            };
            const indexLink = prevState.userProfile.custom_links.findIndex(
              (i) => i.id === action.payload.linkId
            );
            prevState.userProfile.custom_links.splice(indexLink, 1, link);
          }
          return {
            ...prevState,
            userProfile: {
              ...prevState.userProfile,
              custom_links: prevState.userProfile.custom_links,
            },
          };
        case TAPRR_DISPATCH.UPDATE_CURRENT_SECTION:
          return {
            ...prevState,
            currentSection: action.payload,
          };
        case TAPRR_DISPATCH.UPDATE_LINK_APPEARANCE:
          if (action.payload.appearance) {
            const link = {
              ...prevState.userProfile.custom_links.find(
                (item) => item.id === action.payload.linkId
              ),
              linkDisplayType: action.payload.appearance,
            };
            const indexLink = prevState.userProfile.custom_links.findIndex(
              (i) => i.id === action.payload.linkId
            );
            prevState.userProfile.custom_links.splice(indexLink, 1, link);
          }
          return {
            ...prevState,
            userProfile: {
              ...prevState.userProfile,
              custom_links: prevState.userProfile.custom_links,
            },
          };
        case TAPRR_DISPATCH.FETCH_INAPP_VIDEO_RATES:
          return {
            ...prevState,
            fanbiesMessageToolrates: action.payload,
          };
        case TAPRR_DISPATCH.FETCH_INAPP_SOCIAL_MEDIA:
          return {
            ...prevState,
            fanbiesSupportedSocials: action.payload,
          };
        case TAPRR_DISPATCH.CHANGE_THEME:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, theme: action.payload },
          };
        case TAPRR_DISPATCH.CREATE_UPDATE_MARKETPLACE_ITEM:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, marketplace_item: action.payload },
          };
        case TAPRR_DISPATCH.FETCH_CONTACT_LIST_DETAILS:
          return {
            ...prevState,
            userProfile: { ...prevState.userProfile, contact_list: action.payload },
          };
        case TAPRR_DISPATCH.UPDATE_CONTACT_LIST_DETAILS:
          const updatedContactList = prevState.userProfile.contact_list.filter(
            (item) => item.id !== action.payload
          );
          return {
            ...prevState,
            userProfile: {
              ...prevState.userProfile,
              contact_list: updatedContactList,
            },
          };
        default:
          return { ...prevState };
      }
    },
    initialState,
    (initial) => JSON.parse(localStorage.getItem(taprrStore)) || initial
  );
  // Keep the Application persistence
  const saveStateToLocalStorage = (currentState) => {
    const serializedState = JSON.stringify(currentState);
    localStorage.setItem(taprrStore, serializedState);
  };

  useEffect(() => {
    // This is a side-effect and belongs in an effect
    saveStateToLocalStorage(state);
    if (!jtoken) {
      saveStateToLocalStorage(initialState);
    }
  }, [state]);

  // Redirect user on /admin page when is logged if he want to access on signin, signup, reset-passwd pages
  // const redirectIfLogged = () => {
  //   if (!state?.isSignout && jtoken) {
  //     switch (pathname) {
  //       case "/signin":
  //         navigate("/admin", { replace: true });
  //         break;
  //       case "/signup":
  //         navigate("/admin", { replace: true });
  //         break;
  //       case "/resetpass":
  //         navigate("/admin", { replace: true });
  //         break;
  //       case "/forgotten":
  //         navigate("/admin", { replace: true });
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // };

  const redirectIfLogged = useCallback(() => {
    const protectedRoutes = ["/signin", "/signup", "/resetpass", "/forgotten"];

    if (!state?.isSignout && jtoken && protectedRoutes.includes(pathname)) {
      navigate("/admin", { replace: true });
    }
  }, [state?.isSignout, jtoken, pathname, navigate]);

  const authContext = useMemo(
    () => ({
      state,
      dispatch: {
        logIn: async (data) => {
          // Redirect on user profile after signin. if success and remove error message
          document.cookie = `taprr-token=${data.token};`;
          // delete token key in user object
          const tk = "token";
          // eslint-disable-next-line no-param-reassign
          delete data[tk];
          dispatch({
            type: TAPRR_DISPATCH.LOGIN,
            payload: data,
          });
        },
        signUp: (data) => {
          // delete token key in user object
          document.cookie = `taprr-token=${data.token};`;
          // delete token key in user object
          const tk = "token";
          // eslint-disable-next-line no-param-reassign
          delete data[tk];
          dispatch({ type: TAPRR_DISPATCH.SIGN_UP, payload: data });
        },
        signOut: () => {
          document.cookie = `taprr-token=; Max-Age=0; path=/; domain=${
            process.env.PUBLIC_URL
          };expires=${new Date().toLocaleDateString()}`;
          localStorage.removeItem(taprrStore);
          localStorage.removeItem("TAPRR_THEME");
          saveStateToLocalStorage({});
          dispatch({ type: TAPRR_DISPATCH.SIGN_OUT });

          navigate("/", { replace: true });
        },
        updateCustomLinks: (data) => {
          dispatch({ type: TAPRR_DISPATCH.UPDATE_CUSTOMER_LINK, payload: data });
        },
        updateUserSubscription: (data) => {
          dispatch({ type: TAPRR_DISPATCH.UPDATE_USER_SUBCRIPTION, payload: data });
        },
        updateEmailVerification: (data) => {
          dispatch({ type: TAPRR_DISPATCH.UPDATE_EMAIL_VERIFICATION, payload: data });
        },
        updateCustomLinkAnimation: (linkId, animation) => {
          dispatch({
            type: TAPRR_DISPATCH.UPDATE_CUSTOMER_LINK_ANIMATION,
            payload: { linkId, animation },
          });
        },
        updateMarketplaceItem: (data) => {
          dispatch({ type: TAPRR_DISPATCH.CREATE_UPDATE_MARKETPLACE_ITEM, payload: data });
        },
        setContactList: (data) => {
          dispatch({ type: TAPRR_DISPATCH.FETCH_CONTACT_LIST_DETAILS, payload: data });
        },
        deleteContactById: (data) => {
          dispatch({ type: TAPRR_DISPATCH.UPDATE_CONTACT_LIST_DETAILS, payload: data.id });
        },
        getInAppConfigRate: (videoRates) => {
          const rate = JSON.stringify(videoRates);
          dispatch({ type: TAPRR_DISPATCH.FETCH_INAPP_VIDEO_RATES, payload: rate });
        },
        getInAppConfigSocial: (data) => {
          dispatch({ type: TAPRR_DISPATCH.FETCH_INAPP_SOCIAL_MEDIA, payload: data });
        },
        updateCurrentSection: (section) => {
          dispatch({ type: TAPRR_DISPATCH.UPDATE_CURRENT_SECTION, payload: section });
        },
        updateLinkAppearance: (linkId, appearance) => {
          dispatch({
            type: TAPRR_DISPATCH.UPDATE_LINK_APPEARANCE,
            payload: { linkId, appearance },
          });
        },
        getDetails: async (data) => {
          dispatch({
            type: TAPRR_DISPATCH.FETCH_DETAILS,
            payload: data,
          });
        },
        updatePicture: async (data) => {
          dispatch({
            type: TAPRR_DISPATCH.UPDATE_PROFILE_PICTURE,
            payload: data,
          });
        },
        updateCoverImg: async (data) => {
          dispatch({
            type: TAPRR_DISPATCH.UPDATE_PROFILE_COVER_IMAGE,
            payload: data,
          });
        },
        resetProfileVideo: async () => {
          dispatch({
            type: TAPRR_DISPATCH.RESET_PROFILE_VIDEO,
            payload: [],
          });
        },
        changeTheme: (data) => {
          dispatch({
            type: TAPRR_DISPATCH.CHANGE_THEME,
            payload: data,
          });
        },
      },
    }),
    [state]
  );

  useEffect(() => {
    const loadFanbiesAsync = async () => {
      try {
        // Check if user is authenticated
        if (!state.isSignout && jtoken) {
          // Call the application creative tool request message rates...
          const getRequestToolRate = await getInAppConfig(jtoken, "getVideoMessageRate");
          if (getRequestToolRate.success) {
            const videoRates = getRequestToolRate?.response[0]?.value?.split(",");
            authContext.dispatch.getInAppConfigRate(videoRates);
          }
          // Get application config for supported social links
          const responseInAppSocial = await getInAppConfig(jtoken, "getSupportedSocialMedia");
          if (responseInAppSocial.success) {
            const links = responseInAppSocial?.response[0]?.value?.split(",");
            authContext.dispatch.getInAppConfigSocial(links);
          }
        }
      } catch (e) {
        window.console.warn("Error", e);
      }
    };

    loadFanbiesAsync();

    return () => null;
  }, [state?.isSignout]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    redirectIfLogged();
  }, [pathname, redirectIfLogged]);

  const getAllRoutes = (r) =>
    r.map((prop) => <Route exact path={prop.route} key={prop.name} element={prop.component} />);

  const theme = useMemo(() => {
    switch (state?.userProfile?.theme) {
      case "DARK":
        return createTheme({ ...DarkTheme });
      case "SKY":
        return createTheme({ ...SkyTheme });
      case "DEFAULT":
        return createTheme({ ...LightTheme });
      case "OVERLAY":
        return createTheme({ ...OverlayTheme });
      case "SUNSET":
        return createTheme({ ...SunsetTheme });
      case "NATURE":
        return createTheme({ ...NatureTheme });
      case "SNOW":
        return createTheme({ ...SnowTheme });
      case "CHEESE":
        return createTheme({ ...CheeseTheme });
      case "MINERAL":
        return createTheme({ ...MineralTheme });
      case "BLURED":
        return createTheme({ ...BluredTheme });
      default:
        return createTheme({ ...LightTheme });
    }
  }, [state?.userProfile?.theme]);

  return (
    <AuthContext.Provider value={authContext}>
      <HelmetProvider>
        <SocialMediaContext.Provider value={{ socialMediaLinks, setSocialMediaLinks }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
              <Routes>{getAllRoutes(indexRoutes)}</Routes>
              {/* <CookiePreference /> */}
            </SnackbarProvider>
          </ThemeProvider>
        </SocialMediaContext.Provider>
      </HelmetProvider>
    </AuthContext.Provider>
  );
}
