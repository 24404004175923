import React from "react";

// @mui material components
import { Container, Grid, Card, CardContent } from "@mui/material";
// Material Kit 2 PRO React components
import FreeQRGenerator from "./freeqrcodegen";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import CenteredFooter from "molecules/Footers/CenteredFooter";

// Routes
//import { HeaderRoutes } from "footer.routes";
import { useHeaderRoutes } from "hooks/useHeaderRoutes";
function QRGenerator() {
  const headerRoutes = useHeaderRoutes();
  return (
    <>
      <DefaultNavbar routes={headerRoutes} sticky />
      <MKBox component="section" pt={20} pb={6}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card sx={{ borderWidth: 0 }}>
                <CardContent>
                  <MKBox
                    variant="gradient"
                    bgColor="primary"
                    borderRadius="lg"
                    coloredShadow="dark"
                    p={3}
                    mt={-3}
                    mx={2}
                  >
                    <MKTypography variant="h3" color="white" textAlign="center">
                      Generate Free QR Code
                    </MKTypography>
                  </MKBox>
                  <FreeQRGenerator />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox sx={{ backgroundColor: "#1D3E49" }}>
        <Container>
          <CenteredFooter />
        </Container>
      </MKBox>
    </>
  );
}

export default QRGenerator;
