import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import CenteredFooter from "molecules/Footers/CenteredFooter";

// HelpCenter page components
import FaqCollapse from "pages/Support/Faq/components/FaqCollapse";

// Routes
//import { HeaderRoutes } from "footer.routes";
import { useHeaderRoutes } from "hooks/useHeaderRoutes";

function Faq() {
  const headerRoutes = useHeaderRoutes();

  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <DefaultNavbar routes={headerRoutes} sticky />
      <MKBox component="section" pt={20} pb={6}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                    FAQ
                  </MKTypography>
                </MKBox>
                <MKBox p={6}>
                  <FaqCollapse
                    title="What is Taprr?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    Taprr is a versatile platform that supports the sale of digital products,
                    physical products, memberships, and almost anything else creators can think of.
                    It&apos;s a popular choice for a diverse range of industries, including digital
                    artists, writers, musicians, entrepreneurs, and tech-savvy individuals, such as
                    SaaS creators. In fact, it&apos;s difficult to find something that cannot be
                    sold on Taprr, which begs the question: What can&apos;t you sell on Taprr?
                  </FaqCollapse>
                  <FaqCollapse
                    title="How do I contact the Taprr team?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    We'd love to chat! Feel free to contact us at support@taprr.com, and one of our
                    team members get back to you as soon as possible.
                  </FaqCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox sx={{ backgroundColor: "#1D3E49" }}>
        <Container>
          <CenteredFooter />
        </Container>
      </MKBox>
    </>
  );
}

export default Faq;
