import { useCallback, useEffect, useState, useMemo, useRef } from "react";

// react-router components
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { QRCodeSVG } from "qrcode.react";
//
import UAParser from "ua-parser-js";
import PublicActivation from "./publicactivation";

import MKMenuAccordion from "components/MKMenuAccordion";
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKMusic from "components/MKMusic";
import MKTypography from "components/MKTypography";
import MKYoutubeLinkAccordion from "components/MKYoutubeLinkAccordion";
import MKSpinner from "components/MKSpinner";
import rgba from "assets/theme/functions/rgba";
import FooterLogoTxt from "components/utils/FooterLogoTxt";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";

// Assets colors
import dark from "assets/theme/custom-colors/dark";
import colors from "assets/theme/base/colors";
import sky from "assets/theme/custom-colors/sky";
import sunset from "assets/theme/custom-colors/sunset";
import nature from "assets/theme/custom-colors/nature";
import snow from "assets/theme/custom-colors/snow";
import cheese from "assets/theme/custom-colors/cheese";
import mineral from "assets/theme/custom-colors/mineral";
import flush from "assets/theme/custom-colors/flush";

// api call
import { getUserProfile, linkClickIncrement, pageViewIncrement, getCookie } from "api";

import taprrImage from "assets/images/taprr/logo_400_400.png";
import appearance from "assets/theme/appearance";
import {
  EXTERNALURL,
  SHARINGLINKS,
  renderLinkAnimation,
  TikTokIcon,
  defaultProfilePic,
  IP_LOOKUP_URL,
} from "utils";
import * as regex from "regex";

// @mui material components
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TwitterIcon from "@mui/icons-material/Twitter";
import ShareIcon from "@mui/icons-material/Share";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import MKButton from "components/MKButton";
import MKVcardModal from "components/MKVcardModal";

function PublicProfile() {
  const params = useParams();
  const jtoken = getCookie("taprr-token");
  const username = params?.username;
  const [isLoading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [openVcardModal, setopenVcardModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [publicProfile, setPublicProfile] = useState();
  const [style, setStyle] = useState({});
  const [unknownUser, setUnknownUser] = useState(false);
  const [claimUser, setClaimUser] = useState(false);
  const fetched = useRef(false); // Ref to track if data has been fetched
  const ref = useRef(null);
  const pageViewIncrementRef = useRef(false);

  const getUserDetails = useCallback(async () => {
    setLoading(true);
    const res = await getUserProfile({ username, jtoken });
    if (res.success) {
      setLoading(false);
      if (res.message !== "" && res.message === "unclaimed") {
        setClaimUser(true);
        return;
      }
      const response = res.response[0];
      setPublicProfile({ ...response });
    } else {
      setUnknownUser(true);
      setLoading(false);
    }
  }, [username, jtoken]);

  useEffect(() => {
    if (!fetched.current) {
      fetched.current = true;
      getUserDetails();
    }
    return () => null;
  }, [getUserDetails]);

  useEffect(() => {
    const handleLoad = async () => {
      if (pageViewIncrementRef.current) return; // Exit if already run

      const mySession = window.sessionStorage;
      const pageSession = mySession.getItem(`taprrPV-${username}`);
      if (pageSession === null || pageSession !== username) {
        try {
          const ipresponse = await fetch(IP_LOOKUP_URL);
          let ipValue = "";
          if (!ipresponse.ok) {
            ipValue = null;
          } else {
            const ipValueFetch = await ipresponse.json();
            ipValue = ipValueFetch.ip;
          }
          mySession.setItem(`taprrPV-${username}`, username);
          const parser = new UAParser();
          const result = parser.getResult();
          const ipdevice = result.device?.vendor || "Unknown Device";
          const operatingSys = `${ipdevice} ${result.os?.name}`;
          pageViewIncrement(jtoken, username, operatingSys, ipValue);
          pageViewIncrementRef.current = true; // Mark as run
        } catch (e) {
          console.error("Error incrementing page view:", e);
        }
      }
    };

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, [username, jtoken]);

  useEffect(() => {
    if (publicProfile === undefined) return;

    const mode = appearance.filter((i) => publicProfile?.theme === i.mode)[0];
    setStyle({ ...mode });
  }, [publicProfile]);

  const CustomButton = styled(Button)(() => ({
    color: style?.btnTextColor,
    backgroundColor: style?.btnBackground,
    borderRadius: 45,
    borderWidth: 2,
    padding: 15,
    width: "100%",
    borderColor: style?.borderColor,
    position: "relative",
    "&:hover": {
      backgroundColor: style?.btnHovered,
      color: style?.textHovered,
      borderColor: style?.borderColor,
      borderWidth: "2px",
    },
  }));

  const getTheme = (theme) => {
    switch (theme) {
      case "DARK":
        return {
          backgroundColor: dark.dark?.background,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "SKY":
        return {
          backgroundColor: rgba(sky.sky?.background, 0.9),
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "OVERLAY":
        return {
          backgroundImage: `url(${publicProfile?.picture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          boxShadow: "inset 0 0 0 50vw rgba(0, 0, 0, 0.8)",
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "SUNSET":
        return {
          backgroundColor: sunset?.sunset?.background,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "NATURE":
        return {
          backgroundColor: nature?.nature?.background,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "SNOW":
        return {
          backgroundColor: snow?.snow?.background,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "CHEESE":
        return {
          backgroundColor: cheese?.cheese?.background,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "MINERAL":
        return {
          backgroundColor: mineral?.mineral?.background,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "FLUSH":
        return {
          backgroundColor: flush?.flush?.background,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      case "BLURED":
        return {
          background: `url(${publicProfile?.picture})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
      default:
        return {
          backgroundColor: colors.background?.default,
          height: "100%",
          minHeight: "100vh",
          paddingBottom: "50px",
        };
    }
  };

  // increment link count by item id and username
  const handleClickCount = (itemid) => {
    linkClickIncrement(itemid, username, jtoken);
  };

  const formatYoutubeLink = (link) => {
    if (!link.includes("embed") && regex.youtubeTester.test(link)) {
      const subLink = link.split("/watch?v=");
      const validLink = `${subLink[0]}/embed/${subLink[1]}`;
      return validLink;
    }
    return link;
  };

  const formatSpotifyLink = (link) => {
    if (!link.includes("embed") && regex.spotifyTester.test(link)) {
      const subLink = link.split("open.spotify.com");
      return `https://open.spotify.com/embed${subLink[1]}`;
    }
    return link;
  };

  const formatAppleMusicLink = (link) => {
    if (!link.includes("embed") && regex.appleMusicTester.test(link)) {
      const subLink = link.split("music.apple.com");
      return `https://embed.music.apple.com${subLink[1]}`;
    }
    return link;
  };

  const linkDisplayAppearance = (i) => {
    switch (i?.linkDisplayType) {
      case "closed":
        return (
          <MKYoutubeLinkAccordion
            key={i?.id}
            title={i.title}
            url={formatYoutubeLink(i?.link_ref)}
            userTheme={publicProfile?.theme}
          />
        );
      default:
        return (
          <MKBox mt={2} mb={2} sx={{ display: "flex", flexDirection: "column" }} key={i?.id}>
            <MKBox sx={{ alignSelf: "center", width: "100%" }}>
              <MKBox className="placeholder shimmer">
                <MKBox className="faux-image-wrapper">
                  <MKBox className="faux-image" />
                </MKBox>
                <MKBox className="faux-text" />
                <MKBox className="faux-text short" />
              </MKBox>
            </MKBox>
            <iframe
              frameBorder="0"
              height={0}
              width="100%"
              loading="lazy"
              title={i?.title}
              src={formatYoutubeLink(i?.link_ref)}
              onLoad={(e) => {
                e.target.previousSibling.classList.add("hidden");
                e.target.setAttribute("height", "350px");
              }}
            />
          </MKBox>
        );
    }
  };

  const linkDisplayMenu = (i) => {
    switch (i?.linkDisplayType) {
      case "closed":
        return (
          <MKMenuAccordion
            key={i?.id}
            title={i.title}
            menuItems={JSON.parse(i?.link_ref)}
            userTheme={publicProfile?.theme}
            onClick={() => handleClickCount(i.id)}
          />
        );
      default:
        return (
          <MKMenuAccordion
            key={i?.id}
            title={i.title}
            menuItems={JSON.parse(i?.link_ref)}
            userTheme={publicProfile?.theme}
            onClick={() => handleClickCount(i.id)}
          />
        );
    }
  };

  const linkDisplayMusic = (i) => {
    switch (i?.linkDisplayType) {
      case "closed":
        return (
          <MKMusic
            key={i?.id}
            title={i.title}
            url={
              i?.type === "SPOTIFY"
                ? formatSpotifyLink(i?.link_ref)
                : i?.type === "APPLEMUSIC"
                ? formatAppleMusicLink(i?.link_ref)
                : null
            }
            userTheme={publicProfile?.theme}
          />
        );
      default:
        return (
          <MKBox mt={2} mb={2} sx={{ display: "flex", flexDirection: "column" }} key={i?.id}>
            <MKBox sx={{ alignSelf: "center", width: "100%" }}>
              <MKBox className="placeholder shimmer">
                <MKBox className="faux-image-wrapper">
                  <MKBox className="faux-image" />
                </MKBox>
                <MKBox className="faux-text" />
                <MKBox className="faux-text short" />
              </MKBox>
            </MKBox>
            <iframe
              src={
                i?.type === "SPOTIFY"
                  ? formatSpotifyLink(i?.link_ref)
                  : i?.type === "APPLEMUSIC"
                  ? formatAppleMusicLink(i?.link_ref)
                  : null
              }
              width="100%"
              height={0}
              title={i?.title}
              allow="autoplay *; encrypted-media *;"
              frameBorder="0"
              loading="lazy"
              onLoad={(e) => {
                e.target.previousSibling.classList.add("hidden");
                e.target.setAttribute("height", "350px");
              }}
            />
          </MKBox>
        );
    }
  };

  const profileLinks = (items) => {
    const links = items
      ?.filter((i) => i.visible)
      .map((i) =>
        i?.type === "YOUTUBE" ? (
          linkDisplayAppearance(i)
        ) : i?.type === "SPOTIFY" ? (
          linkDisplayMusic(i)
        ) : i?.type === "APPLEMUSIC" ? (
          linkDisplayMusic(i)
        ) : i?.type === "MENU_LISTING" ? (
          linkDisplayMenu(i)
        ) : (
          <CustomButton
            key={i?.id}
            mb={2}
            component="a"
            href={i.link_ref}
            target="_blank"
            rel="noreferrer"
            variant="outlined"
            fullWidth
            size="large"
            className={`link_btn ${renderLinkAnimation(i.linkAnimation)}`}
            onClick={() => handleClickCount(i.id)}
            ref={ref}
          >
            {i?.icon.length ? (
              <MKAvatar
                variant="circular"
                sx={{
                  borderRadius: "50%",
                  position: "absolute",
                  left: "5px",
                }}
                size="sm"
                src={i?.icon}
              />
            ) : null}
            {i.title}
          </CustomButton>
        )
      );
    return links;
  };

  const vCardIcon = () => (
    <MKButton
      variant="outlined"
      sx={{
        borderColor: style?.borderColor,
        color: style?.textColor,
        padding: "0.85rem 1.5rem",
        boxShadow: `0px 1px 2px 0px ${style?.borderColor}`,
      }}
      size="small"
      onClick={() => {
        window.open(`${publicProfile.vcard}`, "_blank").focus();
        setopenVcardModal(true);
      }}
    >
      Save Contact
    </MKButton>
  );

  const SocialIcon = () => {
    const { twitter_id, fb_id, ig_id, tik_id, whatsapp_id, linkedin_id, email } = publicProfile;
    const obj = { twitter_id, fb_id, ig_id, tik_id, whatsapp_id, linkedin_id, email };
    const arr = [];
    const links = useMemo(() => {
      for (const [key, value] of Object.entries(obj)) {
        arr.push({ id: key, url: value, isUrl: value?.length ? true : false });
      }
      return [...arr];
    }, []);
    return links
      .filter((link) => link?.isUrl)
      .map((item) => (
        <li key={item?.id}>
          {item?.id === "twitter_id" ? (
            <IconButton
              onClick={() => window.open(`${EXTERNALURL.TWITTER}${item.url}`, "_blank").focus()}
            >
              <TwitterIcon fontSize="large" sx={{ color: style?.iconColor }} />
            </IconButton>
          ) : item?.id === "fb_id" ? (
            <IconButton
              onClick={() => window.open(`${EXTERNALURL.FACEBOOK}${item.url}`, "_blank").focus()}
            >
              <FacebookOutlinedIcon fontSize="large" sx={{ color: style?.iconColor }} />
            </IconButton>
          ) : item?.id === "ig_id" ? (
            <IconButton
              onClick={() => window.open(`${EXTERNALURL.INSTAGRAM}${item.url}`, "_blank").focus()}
            >
              <InstagramIcon fontSize="large" sx={{ color: style?.iconColor }} />
            </IconButton>
          ) : item?.id === "tik_id" ? (
            <IconButton
              onClick={() => window.open(`${EXTERNALURL.TIKTOK}${item.url}`, "_blank").focus()}
            >
              <TikTokIcon color={style?.iconColor} />
            </IconButton>
          ) : item?.id === "whatsapp_id" ? (
            <IconButton
              onClick={() => window.open(`${EXTERNALURL.WHATSAPP}${item.url}`, "_blank").focus()}
            >
              <WhatsAppIcon fontSize="large" sx={{ color: style?.iconColor }} />
            </IconButton>
          ) : item?.id === "linkedin_id" ? (
            <IconButton
              onClick={() => window.open(`${EXTERNALURL.LINKEDIN}${item.url}`, "_blank").focus()}
            >
              <LinkedInIcon fontSize="large" sx={{ color: style?.iconColor }} />
            </IconButton>
          ) : item?.id === "email" ? (
            <IconButton
              onClick={() => window.open(`${EXTERNALURL.EMAIL}${item.url}`, "_blank").focus()}
            >
              <EmailIcon fontSize="large" sx={{ color: style?.iconColor }} />
            </IconButton>
          ) : null}
        </li>
      ));
  };

  // const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  // const copyURLToClipBoard = () => navigator.clipboard.writeText(`https://taprr.com/${username}`);

  const renderShareBox = () => (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle sx={{ textAlign: "center" }}>
        {activeStep === 1 && (
          <KeyboardArrowLeft
            sx={{
              position: "absolute",
              left: 15,
              top: 22,
              cursor: "pointer",
            }}
            fontSize="medium"
            onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
          />
        )}{" "}
        Connect
        <CloseIcon
          sx={{
            position: "absolute",
            right: 15,
            top: 22,
            cursor: "pointer",
          }}
          color="#000000"
          fontSize="medium"
          onClick={() => {
            setOpen(false);
            setActiveStep(0);
          }}
        />
      </DialogTitle>
      <Divider variant="fullWidth" sx={{ margin: 0, backgroundColor: "#000000" }} />
      <DialogContent>
        {activeStep === 0 && (
          <MKBox>
            <MKButton
              variant="outlined"
              sx={{ borderColor: "#000000", color: "#000000" }}
              size="large"
              fullWidth
              onClick={() => setActiveStep(1)}
            >
              <QrCode2Icon fontSize="medium" sx={{ marginRight: 1 }} />
              QR Code{" "}
            </MKButton>
            {SHARINGLINKS.map(({ id, name, link, img: Img }) => (
              <MKBox
                key={id}
                component="a"
                display="flex"
                direction="row"
                target="_blank"
                href={`${link}https://taprr.com/${username}`}
                rel="noreferrer"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                mt={3}
                mb={3}
                sx={{
                  cursor: "pointer",
                }}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Img fontSize="large" />
                  <MKTypography fontWeight="bold">{name}</MKTypography>
                </Stack>
                <ArrowForwardIosOutlinedIcon fontSize="medium" />
              </MKBox>
            ))}
          </MKBox>
        )}
        {activeStep === 1 && (
          <MKBox>
            <MKBox mt={4} textAlign="center" width="100%" mb={4}>
              <QRCodeSVG size={250} value={`https://taprr.com/${username}`} />
            </MKBox>
          </MKBox>
        )}
      </DialogContent>
    </Dialog>
  );

  const FooterContent = () => (
    <>
      <FooterLogoTxt dark={false} />
      <MKTypography
        fontWeight="bold"
        m={1}
        variant="caption"
        component={Link}
        to="/signup"
        sx={{ color: style?.textColor }}
      >
        New here? create your free page
        <ArrowForwardOutlined
          sx={{ top: "3px", left: "5px", color: "#1d3e49", position: "relative" }}
        />
      </MKTypography>
    </>
  );

  const ProfileContent = () => (
    <>
      <MKBox sx={getTheme(publicProfile?.theme)}>
        <MKBox
          pt={4}
          width="100%"
          className={style?.isBlur && "unblur"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <MKVcardModal
            // eslint-disable-next-line no-underscore-dangle
            ownerid={publicProfile?.rand_id}
            username={publicProfile?.name}
            picture={publicProfile?.picture}
            isOpen={openVcardModal}
            cancelAction={setopenVcardModal}
          />
          {renderShareBox()}
          <MKBox
            width="100%"
            position="absolute"
            sx={{
              backgroundImage: `url(${publicProfile?.cover_img})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              maxWidth: "100% !important",
              height: "120px",
              top: "0",
            }}
          />
          <MKBox mb={2}>
            <MKAvatar
              variant="circular"
              sx={{
                border: "1px solid",
                borderColor: `${style?.textColor}`,
                borderRadius: "50%",
                backgroundColor: "#fff",
              }}
              size="xxl"
              src={`${publicProfile?.picture}`}
            />
          </MKBox>
          <MKBox textAlign="center" mx={2}>
            <Stack direction="row" sx={{ paddingLeft: "20px", justifyContent: "center" }}>
              <MKTypography mb={1} variant="h5" fontWeight="bold" sx={{ color: style?.textColor }}>
                {publicProfile.name ? publicProfile.name : `@${publicProfile?.username}`}
              </MKTypography>
              <IconButton onClick={() => setOpen(!open)} sx={{ top: "-2px" }}>
                <ShareIcon fontSize="small" sx={{ color: style?.iconColor }} />
              </IconButton>
            </Stack>
            <MKTypography mb={2} variant="body2" sx={{ color: style?.textColor }}>
              {publicProfile?.bio ?? ""}
            </MKTypography>
          </MKBox>
          <MKBox
            className="profile_links"
            sx={{
              display: "flex",
              flexDirection:
                publicProfile?.social_position === "BOTTOM" ? "column-reverse" : "column",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center" my={2}>
              <ul className="social__container">{SocialIcon()}</ul>
            </Stack>
            <MKBox sx={{ width: "100%" }}>
              <MKBox my={1} sx={{ width: "100%" }}>
                {publicProfile?.custom_links.length
                  ? profileLinks(publicProfile?.custom_links)
                  : null}
              </MKBox>
            </MKBox>
          </MKBox>
          {/* Subscription option soon <FooterContent /> */}
          <Stack
            spacing={2}
            mb={3}
            mt={3}
            direction="row"
            sx={{
              borderColor: style?.borderColor,
              borderWidth: "2px",
              position: "fixed",
              bottom: "0",
              left: "0",
              right: "0",
              zIndex: "1000",
              backgroundColor: style?.backgroundColor, // Adjust as needed
              padding: "10px",
              transition: "all 0.3s ease-in-out",
              minHeight: "40px",
              justifyContent: "center", // Center horizontally
              alignItems: "center",
              margin: 0,
            }}
          >
            <MKButton
              sx={{
                borderColor: style?.borderColor,
                color: style?.textColor,
                padding: "0.85rem 1.5rem",
                boxShadow: `0px 1px 2px 0px ${style?.borderColor}`,
              }}
              size="small"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                setActiveStep(0);
                setopenVcardModal(true);
              }}
            >
              Exchange Contact{" "}
            </MKButton>
            <>{publicProfile?.vcard && publicProfile?.contactnumber > 0 && vCardIcon()}</>
          </Stack>
        </MKBox>
      </MKBox>
    </>
  );

  const UnknownContent = () => (
    <MKBox
      pt={4}
      width="100%"
      className={style?.isBlur && "unblur"}
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <MKBox textAlign="center" mx={2}>
        <MKTypography variant="p">
          Your search <b>{username}</b> is currently not a username on Taprr.
        </MKTypography>
        <MKTypography variant="h6" my={3}>
          Want this username?{" "}
          <Link
            state={{
              queryUsername: username,
            }}
            to="/signup"
          >
            Sign up on Taprr.
          </Link>
        </MKTypography>
      </MKBox>
      <FooterContent />
    </MKBox>
  );

  const pageContent = () => {
    if (unknownUser) return <UnknownContent />;

    if (claimUser) return <PublicActivation />;

    return <ProfileContent />;
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{publicProfile?.name ? publicProfile?.name : username} - Taprr</title>
        <meta
          name="description"
          content={`${publicProfile?.name ? publicProfile?.name : username}`}
        />
        <meta
          property="og:title"
          content={`${publicProfile?.name ? publicProfile?.name : username} - Taprr`}
        />
        <meta
          property="og:description"
          content={`${publicProfile?.name ? publicProfile?.name : username} - Taprr`}
        />
        <meta
          property="og:image"
          content={
            publicProfile?.picture === defaultProfilePic ? taprrImage : publicProfile?.picture
          }
        />
        <meta property="og:url" content={`https://www.taprr.com/${username}`} />
      </Helmet>
      <>{isLoading ? <MKSpinner color="white" size={50} /> : pageContent()}</>
    </div>
  );
}

export default PublicProfile;
