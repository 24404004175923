import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";

// Context
import { useSnackbar } from "context/SnackbarContext";

// API
import { validateSubGroupUser } from "api";

const SubUserVerification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { showSnackbar } = useSnackbar();
  const [verificationStatus, setVerificationStatus] = useState("pending");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateSubUser = async () => {
      const email = searchParams.get("validateemail");
      const token = searchParams.get("token");

      if (!email || !token) {
        setVerificationStatus("error");
        showSnackbar("Invalid verification link", "error");
        setLoading(false);
        return;
      }

      try {
        const result = await validateSubGroupUser({
          email,
          token,
        });

        if (result?.success) {
          setVerificationStatus("success");
          showSnackbar("Email verified successfully!", "success");
        } else {
          throw new Error(result?.message || "Verification failed");
        }
      } catch (error) {
        console.error("Verification error:", error);
        setVerificationStatus("error");
        showSnackbar(error.message || "Email verification failed", "error");
      } finally {
        setLoading(false);
      }
    };

    validateSubUser();
  }, []);

  const handleAction = () => {
    if (verificationStatus === "success") {
      navigate("/signin", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  return (
    <MKBox
      component="section"
      display="grid"
      minHeight="100vh"
      alignItems="center"
      bgcolor="grey.100"
    >
      <Container maxWidth="sm">
        <Card sx={{ p: 4, textAlign: "center" }}>
          <MKBox mb={3}>
            {loading ? (
              <CircularProgress size={64} color="primary" />
            ) : verificationStatus === "success" ? (
              <CheckCircleIcon sx={{ fontSize: 64, color: "success.main" }} />
            ) : verificationStatus === "error" ? (
              <ErrorIcon sx={{ fontSize: 64, color: "error.main" }} />
            ) : (
              <MailOutlineIcon sx={{ fontSize: 64, color: "primary.main" }} />
            )}
          </MKBox>

          <MKTypography variant="h3" mb={2}>
            {loading
              ? "Verifying your email..."
              : verificationStatus === "success"
              ? "Email Verified!"
              : "Verification Failed"}
          </MKTypography>

          <MKTypography variant="body1" color="secondary" mb={4}>
            {loading
              ? "Please wait while we verify your email address..."
              : verificationStatus === "success"
              ? "Your email has been successfully verified. You can now sign in to access your sub-group account."
              : "We couldn't verify your email address. The link might be expired or invalid."}
          </MKTypography>

          <MKButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAction}
            disabled={loading}
          >
            {verificationStatus === "success" ? "Sign In" : "Go to Homepage"}
          </MKButton>
        </Card>
      </Container>
    </MKBox>
  );
};

export default SubUserVerification;
