import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

import appearance from "assets/theme/appearance";

function MKMenuAccordion({ title, menuItems, userTheme, onClick }) {
  const [expanded, setExpanded] = useState(false);
  const [style, setStyle] = useState({});

  const handleChange = () => {
    if (!expanded) {
      onClick(); // Only track click when opening
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (userTheme === undefined) return;
    const mode = appearance.filter((i) => userTheme === i.mode)[0];
    setStyle({ ...mode });
  }, [userTheme]);

  return (
    <MKBox mt={2} mb={2}>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        sx={{
          backgroundColor: `${style?.btnBackground} !important`,
          borderWidth: 2,
          borderColor: style?.borderColor,
          borderRadius: 60,
        }}
        className="mk_accordion link_btn"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: style?.btnTextColor }} />}
          className="mk_accordion_header"
        >
          <MKTypography variant="button" fontWeight="bold" sx={{ color: style?.btnTextColor }}>
            {title}
          </MKTypography>
        </AccordionSummary>
        <AccordionDetails className="mk_accordion_content mk_accordion_content__menuItem">
          {menuItems.map((menuItem, idx) => (
            <MKBox key={idx} mb={2}>
              <MKTypography variant="subtitle1" display="flex" justifyContent="space-between">
                <span>{menuItem.name}</span>
                <span>
                  {menuItem.currency?.symbol || "$"}
                  {menuItem.price}
                </span>
              </MKTypography>
              {menuItem.description && (
                <MKTypography variant="body2" color="text">
                  {menuItem.description}
                </MKTypography>
              )}
              {menuItem?.footnote && (
                <MKTypography variant="caption" color="text">
                  {menuItem.footnote}
                </MKTypography>
              )}
            </MKBox>
          ))}
          <MKBox display="flex" justifyContent="center">
            <MKButton
              variant="outlined"
              color="dark"
              size="medium"
              iconOnly
              sx={{ borderRadius: 5 }}
              onClick={handleChange}
            >
              <CloseOutlined color="dark" />
            </MKButton>
          </MKBox>
        </AccordionDetails>
      </Accordion>
    </MKBox>
  );
}

MKMenuAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  userTheme: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default MKMenuAccordion;
