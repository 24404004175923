import React, { useState } from "react";

// @mui material components
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKPricing from "components/MKPricing";
import MKTable from "components/MKTable";

// Material Kit 2 PRO React examples
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import CenteredFooter from "molecules/Footers/CenteredFooter";

import { PRICING_OFFERS } from "utils";

// Routes
//import { HeaderRoutes } from "footer.routes";
import { useHeaderRoutes } from "hooks/useHeaderRoutes";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function Pricing() {
  const [expanded, setExpanded] = useState(false);
  const headerRoutes = useHeaderRoutes();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <DefaultNavbar routes={headerRoutes} sticky />
      <MKBox component="section" pt={20} pb={6}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card sx={{ borderWidth: 0 }}>
                <CardContent>
                  <MKBox
                    variant="gradient"
                    bgColor="primary"
                    borderRadius="lg"
                    coloredShadow="dark"
                    p={3}
                    mt={-3}
                    mx={2}
                  >
                    <MKTypography variant="h3" color="white" textAlign="center">
                      Taprr Plans & Pricing
                    </MKTypography>
                  </MKBox>
                  <MKBox pb={6} px={6}>
                    <MKTypography variant="h6" mt={6} fontWeight="light" textAlign="center" mb={3}>
                      Taprr offers flexible pricing plans tailored to meet your networking needs.
                      Whether you're a solo professional or a large enterprise, we have a solution
                      for you.
                    </MKTypography>
                  </MKBox>
                  <MKBox
                    className="flip-card"
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    {PRICING_OFFERS.map(
                      ({ id, title, advantages, priceView, path, desc, price }) => (
                        <MKPricing
                          key={id}
                          title={title}
                          priceView={priceView}
                          price={price}
                          path={path}
                          advantages={advantages}
                          desc={desc}
                        />
                      )
                    )}
                  </MKBox>
                </CardContent>
                <CardActions>
                  <MKTypography onClick={handleExpandClick} variant="h6" fontWeight="bold" ml={3}>
                    {expanded ? "Hide" : "More info"}
                  </MKTypography>
                  <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="More info"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <MKTable />
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox sx={{ backgroundColor: "#1D3E49" }}>
        <Container>
          <CenteredFooter />
        </Container>
      </MKBox>
    </>
  );
}

export default Pricing;
