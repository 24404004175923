// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// Consent Cookie
import CookieConsent from "react-cookie-consent";

// Material Kit 2 PRO React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

// Material Kit 2 PRO React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

const { primary } = colors;
const { fontWeightBold } = typography;

const buttonStyle = {
  backgroundColor: "transparent",
  color: primary.main,
  minHeight: pxToRem(47),
  padding: `${pxToRem(12)} ${pxToRem(28)}`,
  fontWeight: fontWeightBold,
  borderRadius: 10,
  fontSize: typography.size.sm,
  border: "1px solid #1d3e49",
};

const rootStyle = {
  background: "#ecf0f1",
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function CookiePreference() {
  const { pathname } = useLocation();

  const reFreshIFrame = () => {
    if (pathname === "/admin") {
      const iframeEle = document.getElementById("profile-preview");
      iframeEle.contentWindow.location.reload();
    }
  };

  return (
    <CookieConsent
      style={{ ...rootStyle }}
      location="bottom"
      buttonText="Accept All cookies"
      buttonStyle={{ ...buttonStyle }}
      declineButtonText="Refuse non-essential cookies"
      declineButtonStyle={{ ...buttonStyle }}
      enableDeclineButton
      cookieName="taprr-cookies"
      onAccept={reFreshIFrame}
      onDecline={reFreshIFrame}
      buttonWrapperClasses="btn-wrapper"
      flipButtons
    >
      <MKBox>
        <MKTypography variant="body2">
          Taprr and its partners use cookies to provide you with a better, safer, and faster service
          and to support our business. Some cookies are necessary to use our services, improve our
          services, and make sure they work properly. To learn more, visit the cookie page of our{" "}
          <MKTypography
            variant="body2"
            component={Link}
            href="#"
            to="/taprr-cookies"
            fontWeight="bold"
          >
            help center
          </MKTypography>
        </MKTypography>
      </MKBox>
    </CookieConsent>
  );
}

export default CookiePreference;
