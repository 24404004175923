import React from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// assets
import elasticImg from "assets/images/elastic.png";
import amazonImg from "assets/images/amazon.png";
import googleImg from "assets/images/google.png";
import descriptImg from "assets/images/descript.png";
import mazeImg from "assets/images/maze.png";
import upworkImg from "assets/images/upwork.png";

const Sponsor = () => {
  return (
    <Container>
      <Grid container mb={5}>
        <Grid item xs={12} mt={5} mb={5}>
          <MKTypography sx={{ fontSize: 18, color: "#64748B", textAlign: "center" }}>
            Sponsor by
          </MKTypography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            mt={2}
          >
            <MKBox component="img" src={elasticImg} alt="elastic logo" loading="lazy" />
            <MKBox component="img" src={amazonImg} alt="amazon logo" loading="lazy" />
            <MKBox component="img" src={googleImg} alt="google logo" loading="lazy" />
            <MKBox component="img" src={descriptImg} alt="descript logo" loading="lazy" />
            <MKBox component="img" src={mazeImg} alt="maze logo" loading="lazy" />
            <MKBox component="img" src={upworkImg} alt="upwork logo" loading="lazy" />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Sponsor;
