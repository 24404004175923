import React, { useContext } from "react";

// @mui material components
import { Container, Grid } from "@mui/material";
import MKButton from "components/MKButton";
// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import ADD_TAPRR_TO_APPLE_WALLET from "assets/images/taprr/add_taprr_to_apple_wallet.png";

// api call
import { generatePass } from "api";

// context
import AuthContext from "context/AuthContext";

function PreviewProfile() {
  const { state } = useContext(AuthContext);
  const handleGeneratePass = async () => {
    try {
      const username = state.userProfile.username;
      const result = await generatePass({
        name: state.userProfile.name,
        bio: state.userProfile.bio,
        qrCodeData: `https://taprr.com/${username}`,
      });
      console.log(result.message);
      // Handle success (e.g., show a success message to the user)
    } catch (error) {
      console.error(error.message);
      // Handle error (e.g., show an error message to the user)
    }
  };
  return (
    <>
      <MKBox component="section" pb={6}>
        <Container>
          <Grid container justifyContent="center">
            <MKTypography className="text-center" mt={2}>
              Profile Preview
            </MKTypography>
            <MKBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              textAlign="center"
              sx={({ breakpoints }) => ({
                [breakpoints.up("md")]: {
                  position: "fixed",
                },
              })}
            >
              <MKButton onClick={() => handleGeneratePass()} sx={{ minWidth: "380px" }}>
                <MKBox
                  component="img"
                  src={ADD_TAPRR_TO_APPLE_WALLET}
                  alt={`add_taprr_to_apple_wallet`}
                  loading="lazy"
                  ml={2}
                  sx={{
                    width: "180px",
                    height: "60px",
                    borderRadius: "15px",
                    padding: "5px",
                  }}
                />
              </MKButton>
              <MKBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <iframe
                  className="phone phone_translate phone__dashboard"
                  height="100%"
                  id="profile-preview"
                  width="100%"
                  loading="eager"
                  title={`${state.userProfile?.username}`}
                  src={`${process.env.PUBLIC_URL}/${state.userProfile?.username}`}
                />
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default PreviewProfile;
