// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import CenteredFooter from "molecules/Footers/CenteredFooter";

// Routes
//import { HeaderRoutes } from "footer.routes";
import { useHeaderRoutes } from "hooks/useHeaderRoutes";
function TaprrCookies() {
  const headerRoutes = useHeaderRoutes();
  return (
    <>
      <DefaultNavbar
        routes={headerRoutes}
        action={{ type: "internal", route: "/signup", label: "Sign Up Free", color: "primary" }}
        sticky
      />
      <MKBox component="section" pt={20} pb={6}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="primary"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h1" color="white">
                    How cookies are used on Taprr
                  </MKTypography>
                </MKBox>
                <MKBox pb={6} px={6}>
                  <MKTypography variant="body2" color="dark" mt={6}>
                    Cookies and similar technologies like pixels and local storage provide you with
                    a better, faster, and safer experience on Taprr. Cookies are also used to
                    operate our services, which include our websites, applications, APIs, and email
                    communications.
                  </MKTypography>
                  <MKTypography variant="h3" mt={6} mb={3} fontWeight="bold">
                    What are cookies, and local storage?
                  </MKTypography>
                  <MKTypography variant="body2" color="dark">
                    <strong>Cookies</strong> are small files placed on your computer as you browse
                    the web or use a web-enabled app. We use cookies to operate our services,
                    discover how people use our services, understand how to make them work better
                    and more. See above for more examples of how we use cookies. We use local
                    storage to save data on your computer or mobile device.
                  </MKTypography>
                  <MKTypography variant="body2" color="dark">
                    We use data from <strong>local storage</strong> to turn on web navigation,
                    maintain video player preferences, customize what we show you based on your past
                    interactions with our services, remember your preferences, and measure ad
                    effectiveness. We may also use local storage on other websites, where necessary
                    to provide you certain features, to obtain information about your visits to
                    those sites.
                  </MKTypography>
                  <MKTypography variant="h3" mt={6} mb={3} fontWeight="bold">
                    Where are these technologies used?
                  </MKTypography>
                  <MKTypography variant="body2" color="dark">
                    We (along with third parties) use these technologies on Taprr services, which
                    includes our websites, applications, and services and on other websites,
                    applications, and services that have integrated Taprr services, including
                    third-party properties that incorporate our advertising technology. This
                    includes our ad partners’ websites and sites that use our embeds, including
                    embedded timelines. Third parties may also use these technologies, for example,
                    when you click on links from Taprr services, view or interact with third-party
                    content in Taprr services, or visit third-party websites that incorporate our
                    advertising technology.
                  </MKTypography>
                  <MKTypography variant="h3" mt={6} mb={3} fontWeight="bold">
                    What are my privacy options?
                  </MKTypography>
                  <MKTypography variant="body2" color="dark">
                    We&#39;re committed to giving you meaningful privacy options to control or limit
                    how we, our partners, and other third-parties use cookies and cookie-related
                    data: Taprr stores information about other websites where you&#39;ve seen Taprr
                    content or accessed our services. For most Taprr features you can adjust this by
                    going to: Settings &gt; Allow use of where you see Taprr content across the web.
                    However, for certain paid subscription features, even if you make this
                    adjustment we will still store certain information where doing so is necessary
                    for that feature to work. We will tell you about this storage when you sign up
                    for these features.
                  </MKTypography>
                  <MKTypography variant="h3" mt={6} mb={3} fontWeight="bold">
                    More privacy options
                  </MKTypography>
                  <MKTypography variant="body2" color="dark">
                    <strong>Important</strong>: To view or change web settings for your Taprr
                    account, you&#39;ll need to log in to the web browser. Changing your Taprr
                    settings in the web browser when you’re logged out will only affect behavior on
                    that browser while you aren&#39;t logged in to Taprr.
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox sx={{ backgroundColor: "#1D3E49" }}>
        <Container>
          <CenteredFooter />
        </Container>
      </MKBox>
    </>
  );
}

export default TaprrCookies;
