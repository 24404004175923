import { useState, useContext, useRef } from "react";
import { Draggable } from "@hello-pangea/dnd";

// Material Kit 2 React Components
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKDeleteModal from "components/MKDeleteModal";
import MKModalThumbnail from "components/MKModalThumbnail";
import MKSpinner from "components/MKSpinner";
import MenuListingForm from "components/MenuListingForm";
// import MenuListingPreview from "components/MenuListingPreview";

// MUI Core Components
import {
  Grid,
  Stack,
  Icon,
  Tooltip,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

// MUI Icons
import {
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff,
  AutoFixHigh as AutoFixHighIcon,
  DragIndicator as DragIndicatorIcon,
  YouTube as YouTubeIcon,
  TrendingUpOutlined as TrendingUpOutlinedIcon,
  AllInclusive as AllInclusiveIcon,
  AttachFile as AttachFileIcon,
  Settings as SettingsOutlinedIcon,
  ImageOutlined as ImageOutlinedIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Close as CloseIcon,
  ModeEditOutline as ModeEditOutlineIcon,
  BorderHorizontal as BorderHorizontalIcon,
  BorderOuter as BorderOuterIcon,
} from "@mui/icons-material";

// import utils
import { LINKTYPE, ANIMATION, LINK_APPEARANCE_TYPE, renderLinkAnimation, LINK_CREATE } from "utils";

// context
import AuthContext from "context/AuthContext";

// Form validation
import { useFormik } from "formik";

// API call
import {
  deleteCustomLink,
  getCookie,
  createCustomLink,
  updateCustomLinksItem,
  updateLinkAnimation,
  updateLinkDisplayType,
  uploadLinkPicture,
  removeLinkPicture,
} from "api";

// Regex validation
import * as regex from "regex";

// assets
import calendly from "assets/images/calendly.svg";

const DraggableListItem = ({ item, linkForm, setLinkForm, index }) => {
  const [currLinkId, setCurrLinkId] = useState(-1);
  const { state, dispatch } = useContext(AuthContext);
  const [isTitle, setIsTitle] = useState(false);
  const [isURL, setIsURL] = useState(false);
  const jtoken = getCookie("taprr-token");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenThumb, setIsOpenThumb] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [loadingToggle] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();
  const [activeStep, setActiveStep] = useState(0);

  const reFreshIFrame = () => {
    const iframeEle = document.getElementById("profile-preview");
    iframeEle.contentWindow.location.reload();
  };

  const validation = useFormik({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      id: item?.id || linkForm?.id,
      title: item?.title || linkForm?.title,
      urlRef: item?.link_ref || linkForm?.link_ref,
      type: item?.type || linkForm?.type,
      icon: item?.icon || (linkForm?.icon ?? ""),
      visible: item?.visible || linkForm?.visible === 1 ? true : false,
      menuItems: item?.menuItems || linkForm?.menuItems || [],
    },
    validate: (values) => {
      const errors = {};
      if (
        !regex.url.test(values.urlRef) &&
        values.type !== LINKTYPE.FILE_UPLOAD &&
        values.type !== LINKTYPE.MENU_LISTING
      ) {
        errors.urlRef = "Invalid link, should be e.g taprr.com";
      }
      if (
        values.urlRef.match(/ftp|ssh|ftps/g) &&
        values.type !== LINKTYPE.FILE_UPLOAD &&
        values.type !== LINKTYPE.MENU_LISTING
      ) {
        errors.urlRef = "Invalid link, should be e.g taprr.com";
      }
      return errors;
    },
    onSubmit: async (values) => {
      if (typeof item?.id === "string") {
        const newLink = await createCustomLink({
          jtoken,
          linktitle: values.title,
          linkref:
            values.urlRef.includes("https://") || values.urlRef.includes("http://")
              ? values.urlRef
              : `https://${values.urlRef}`,
          linktype: values.type,
          linkvisible: values.visible ? 1 : 0,
        });
        if (newLink?.success) {
          dispatch.updateCustomLinks(newLink.response);
          setLinkForm({
            title: "",
            link_ref: "",
            visible: true,
            mode: LINK_CREATE,
          });
          reFreshIFrame();
        }
      }
      if (typeof item?.id === "number") {
        const oldLink = state?.userProfile?.custom_links.filter((u) => u?.id === item?.id)[0];
        let updatedLinK;
        if (values.type === LINKTYPE.MENU_LISTING) {
          const menuObj = {
            ...oldLink,
            id: item?.id,
            title: values.title,
            icon: item?.icon,
            visible: values.visible ? 1 : 0,
          };
          updatedLinK = await updateCustomLinksItem(jtoken, menuObj);
        } else {
          const newObj = {
            ...oldLink,
            id: item?.id,
            title: values.title,
            icon: item?.icon,
            link_ref:
              values.urlRef.includes("https://") || values.urlRef.includes("http://")
                ? values.urlRef
                : `https://${values.urlRef}`,
            visible: values.visible ? 1 : 0,
          };
          updatedLinK = await updateCustomLinksItem(jtoken, newObj);
        }
        if (updatedLinK?.success) {
          dispatch.updateCustomLinks(updatedLinK.response);
          reFreshIFrame();
        }
      }
    },
    validateOnChange: true,
  });

  const toggleVisible = async (ev) => {
    if (validation.isValid) {
      if (typeof item?.id === "number") {
        setIsVisible(!isVisible);
        const oldLink = state?.userProfile?.custom_links.filter((u) => u?.id === item?.id)[0];
        const newObj = {
          ...oldLink,
          id: item?.id,
          title: validation.values.title,
          link_ref: validation.values.urlRef,
          visible: ev ? 1 : 0,
        };
        const updatedLinK = await updateCustomLinksItem(jtoken, newObj);
        if (updatedLinK?.success) {
          dispatch.updateCustomLinks(updatedLinK.response);
          reFreshIFrame();
        }
        setIsVisible(!isVisible);
      }
    }
  };

  const addTitle = (id) => {
    if (item?.id === id) {
      setCurrLinkId(index);
      setIsTitle(true);
    }
  };

  const addURL = (id) => {
    if (item?.id === id) {
      setCurrLinkId(index);
      setIsURL(true);
    }
  };

  const removeLink = async (id) => {
    const req = await deleteCustomLink({ jtoken, id, linkurl: item?.icon });
    if (req?.success) {
      // dispatch
      setOpen(false);
      dispatch.updateCustomLinks(req.response);
      reFreshIFrame();
    }
    if (!req?.success && req?.message === "No Links...") {
      setOpen(false);
      dispatch.updateCustomLinks([]);
      reFreshIFrame();
    }
  };

  const doNotForgetSaving = () => {
    if (
      (validation.isValid && validation.values.urlRef !== (item?.link_ref || linkForm?.link_ref)) ||
      (validation.isValid && validation.values.title !== (item?.title || linkForm?.title))
    ) {
      return (
        <MKBox
          sx={{
            borderRadius: "0px 0px 1rem 1rem",
            marginTop: "-25px",
          }}
          bgColor="dark"
          textAlign="center"
        >
          <MKTypography color="white" variant="body2" alignItems="center">
            Do not forget to save your changes
          </MKTypography>
        </MKBox>
      );
    }
    return null;
  };

  const DisableByTypeLink = ({ type }) => {
    switch (type) {
      case LINKTYPE.PAYPAL:
      case LINKTYPE.CALENDLY:
      case LINKTYPE.YOUTUBE_SUB:
      case LINKTYPE.FILE_UPLOAD:
      case LINKTYPE.MENU_LISTING:
        return true;
      default:
        return null;
    }
  };

  const showIconByTypeLink = ({ type }) => {
    switch (type) {
      case LINKTYPE.YOUTUBE:
        return (
          <Tooltip title="Youtube Link" arrow>
            <YouTubeIcon fontSize="small" color="dark" />
          </Tooltip>
        );
      case LINKTYPE.NORMAL:
        return (
          <Tooltip title="Custom Link" arrow>
            <AllInclusiveIcon fontSize="small" color="dark" />
          </Tooltip>
        );
      case LINKTYPE.PAYPAL:
        return (
          <Tooltip title="Paypal Link" arrow>
            <Icon fontSize="small" color="#000000">
              paypal
            </Icon>
          </Tooltip>
        );
      case LINKTYPE.CALENDLY:
        return (
          <Tooltip title="Calendly Link" arrow>
            <img src={calendly} alt="calendly logo" width="20px" height="20px" />
          </Tooltip>
        );
      case LINKTYPE.YOUTUBE_SUB:
        return (
          <Tooltip title="Youtube Subscriber Link" arrow>
            <YouTubeIcon fontSize="small" color="dark" />
          </Tooltip>
        );
      case LINKTYPE.FILE_UPLOAD:
        return (
          <Tooltip title="File Upload Link" arrow>
            <AttachFileIcon fontSize="small" color="dark" />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const handleChangeAnimation = async (ev, customLink) => {
    const req = await updateLinkAnimation({
      linkid: customLink?.id,
      jtoken,
      linkAnimation: ev.target.value,
    });
    if (req?.status === 200) {
      dispatch.updateCustomLinkAnimation(customLink?.id, ev.target.value);
      reFreshIFrame();
    }
  };

  const handleChangeApperance = async (ev, customLink) => {
    const req = await updateLinkDisplayType({
      linkid: customLink?.id,
      jtoken,
      linkDisplayType: ev.target.value,
    });
    if (req?.status === 200) {
      dispatch.updateLinkAppearance(customLink?.id, ev.target.value);
      reFreshIFrame();
    }
  };

  const AnimationLabel = ({ label, type }) => (
    <>
      <AutoFixHighIcon
        fontSize="large"
        color="secondary"
        className={renderLinkAnimation(type)}
        sx={{ marginRight: "3px", position: "relative", top: "5px" }}
      />
      <span>{label}</span>
    </>
  );

  const AppearanceLinkLabel = ({ label, type }) => (
    <>
      <>
        {type === "default" ? (
          <BorderOuterIcon
            fontSize="small"
            color="dark"
            sx={{ top: "5px", position: "relative" }}
          />
        ) : (
          <BorderHorizontalIcon
            fontSize="small"
            color="dark"
            sx={{ top: "5px", position: "relative" }}
          />
        )}
      </>
      <span>{label}</span>
    </>
  );

  const CommonOption = ({ customLink }) => (
    <MKBox>
      <MKTypography variant="button" color="dark" fontWeight="bold">
        Choose a custom animation for link
      </MKTypography>
      <MKBox>
        <FormControl>
          <RadioGroup
            row
            name="linkAnimation"
            onChange={(e) => handleChangeAnimation(e, customLink)}
            value={customLink?.linkAnimation}
            defaultValue="default"
          >
            {ANIMATION.map((animation) => (
              <FormControlLabel
                key={animation?.id}
                value={animation?.linkAnimation}
                control={<Radio />}
                label={<AnimationLabel label={animation?.label} type={animation?.linkAnimation} />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </MKBox>
    </MKBox>
  );

  const AppearanceOption = ({ customLink }) => (
    <MKBox>
      <MKTypography variant="button" color="dark" fontWeight="bold">
        Appearance type
      </MKTypography>
      <MKBox>
        <FormControl>
          <RadioGroup
            row
            name="linkDisplayType"
            onChange={(e) => handleChangeApperance(e, customLink)}
            value={customLink?.linkDisplayType}
            defaultValue="default"
          >
            {LINK_APPEARANCE_TYPE.map((displayType) => (
              <FormControlLabel
                key={displayType.id}
                value={displayType.linkDisplayType}
                control={<Radio />}
                label={
                  <AppearanceLinkLabel
                    label={displayType.label}
                    type={displayType.linkDisplayType}
                  />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      </MKBox>
    </MKBox>
  );

  const onCloseImg = () => {
    setActiveStep(0);
    setOpenImg(false);
  };

  const optionSettingType = (customLink) => {
    switch (customLink?.type) {
      case LINKTYPE.YOUTUBE:
        return <AppearanceOption customLink={customLink} />;
      case LINKTYPE.MENU_LISTING:
        return <MenuListingOption customLink={customLink} />;
      default:
        return <CommonOption customLink={customLink} />;
    }
  };

  const advancedOption = (customLink) => {
    if (isOpen) {
      return (
        <MKBox
          sx={{
            borderRadius: "0px 0px 1rem 1rem",
            marginTop: "-25px",
          }}
          className="advanced_option"
        >
          <MKBox
            bgColor="#EBEEF1"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MKBox />
            <MKTypography color="dark" fontWeight="bold" variant="body2" alignItems="center">
              Advanced options
            </MKTypography>
            <IconButton onClick={() => setIsOpen(false)}>
              <CloseIcon fontSize="small" color="dark" />
            </IconButton>
          </MKBox>
          <MKBox bgColor="#FFFFFF" p={2}>
            <MKBox>{optionSettingType(customLink)}</MKBox>
          </MKBox>
        </MKBox>
      );
    }
    return null;
  };

  const handleClickUpload = () => {
    const { rand_: userId } = state.userProfile;
    const uploadfile = document.getElementById("input_file");
    uploadfile.click();
    const uploadEvt = async () => {
      const formadata = new FormData();
      formadata.append("file", ref.current.files[0]);
      formadata.set("linkid", item?.id);
      formadata.set("id", userId);
      onCloseImg();
      const res = await uploadLinkPicture(formadata);
      if (res.success) {
        const oldLink = state?.userProfile?.custom_links.filter((u) => u?.id === item?.id)[0];
        const newObj = {
          ...oldLink,
          icon: res?.response,
        };
        const updatedLinK = await updateCustomLinksItem(jtoken, newObj);
        if (updatedLinK?.success) {
          dispatch.updateCustomLinks(updatedLinK.response);
          setActiveStep(0);
          setOpenImg(false);
          reFreshIFrame();
        }
      }
    };
    uploadfile.addEventListener("change", uploadEvt);
  };

  const removeThumbnail = async () => {
    setIsLoading(!isLoading);
    const { rand_: userId, custom_links } = state.userProfile;
    const updatedLinK = await removeLinkPicture(item?.id, userId, item?.icon);
    if (Object.keys(updatedLinK).length === 0) {
      setIsLoading(false);
      const customLinksCopy = [...custom_links];
      const indexLink = customLinksCopy.findIndex((i) => i.id === item?.id);
      const updatedLink = { ...custom_links[indexLink], icon: "" };
      customLinksCopy.splice(indexLink, 1, updatedLink);
      dispatch.updateCustomLinks(customLinksCopy);
      setIsOpenThumb(false);
      setActiveStep(0);
      reFreshIFrame();
    }
  };

  const setThumbnailByURL = async (obj) => {
    const updatedLinK = await updateCustomLinksItem(jtoken, {
      ...obj,
      icon: validation.values.icon,
    });
    if (updatedLinK?.success) {
      dispatch.updateCustomLinks(updatedLinK.response);
      setActiveStep(0);
      setIsOpenThumb(false);
      reFreshIFrame();
    }
  };

  const thumbnailOption = (customLink) => {
    if (isOpenThumb) {
      return (
        <MKBox
          sx={{
            borderRadius: "0px 0px 1rem 1rem",
            marginTop: "-25px",
          }}
          className="advanced_option"
        >
          <MKBox
            bgColor="#EBEEF1"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MKBox />
            <MKTypography color="dark" fontWeight="bold" variant="body2" alignItems="center">
              Thumbnail options
            </MKTypography>
            <IconButton onClick={() => setIsOpenThumb(false)}>
              <CloseIcon fontSize="small" color="dark" />
            </IconButton>
          </MKBox>
          <MKBox bgColor="#FFFFFF" p={2}>
            <MKBox>
              {customLink?.icon?.length ? (
                <MKBox
                  mt={2}
                  mb={1}
                  display="flex"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <MKBox>
                    <img src={customLink?.icon} alt="link icon" width="50px" height="50px" />
                  </MKBox>
                  <MKBox>
                    <MKButton
                      variant="gradient"
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenImg(true);
                        return false;
                      }}
                      color="primary"
                      fullWidth
                    >
                      Change
                    </MKButton>
                    <MKButton
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        removeThumbnail();
                        return false;
                      }}
                      color="primary"
                      fullWidth
                      sx={{ marginTop: 3 }}
                    >
                      {isLoading ? <MKSpinner color="primary" size={20} /> : "Remove"}
                    </MKButton>
                  </MKBox>
                </MKBox>
              ) : null}

              {!customLink?.icon?.length ? (
                <MKButton
                  variant="gradient"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenImg(true);
                    return false;
                  }}
                  color="primary"
                  fullWidth
                >
                  Add Thumbnail
                </MKButton>
              ) : null}
            </MKBox>
          </MKBox>
        </MKBox>
      );
    }
    return null;
  };

  const renderLinkContent = (customLink) => {
    switch (customLink.type) {
      case LINKTYPE.YOUTUBE:
      case LINKTYPE.SPOTIFY:
      case LINKTYPE.APPLEMUSIC:
        // Handle existing media types
        return null;
      case LINKTYPE.MENU_LISTING:
        try {
          if (!customLink.link_ref) return null;

          let menuItems = [];
          let rawData = customLink.link_ref;

          // If it's a string, try to repair and parse it
          if (typeof rawData === "string") {
            try {
              // Try to repair truncated JSON
              if (rawData.endsWith("0") || !rawData.endsWith("]")) {
                // Find the last complete object
                const lastValidBrace = rawData.lastIndexOf("}");
                if (lastValidBrace !== -1) {
                  rawData = rawData.substring(0, lastValidBrace + 1) + "]";
                }
              }

              // Remove any invalid characters
              rawData = rawData
                .replace(/[\u0000-\u001F]+/g, "")
                .replace(/\\/g, "\\\\")
                .replace(/\r?\n|\r/g, "");

              menuItems = JSON.parse(rawData);
            } catch (parseError) {
              console.error("Initial parse failed, trying alternative parsing");

              // Try parsing as individual objects
              try {
                const itemStrings = rawData.replace(/^\[/, "").replace(/\]$/, "").split("},{");

                menuItems = itemStrings
                  .map((str) => {
                    try {
                      const cleanStr = str.replace(/^{/, "").replace(/}$/, "");
                      return JSON.parse(`{${cleanStr}}`);
                    } catch {
                      return null;
                    }
                  })
                  .filter(Boolean);
              } catch (e) {
                console.error("Alternative parsing failed:", e);
                return null;
              }
            }
          } else if (Array.isArray(rawData)) {
            menuItems = rawData;
          } else {
            return null;
          }

          // Validate menu items
          const validMenuItems = menuItems
            .filter(
              (item) =>
                item &&
                typeof item === "object" &&
                typeof item.name === "string" &&
                item.name.trim() !== "" &&
                (typeof item.price === "string" || typeof item.price === "number")
            )
            .map((item) => ({
              ...item,
              price:
                typeof item.price === "string"
                  ? parseFloat(item.price).toFixed(2)
                  : item.price.toFixed(2),
              currency: item.currency || { symbol: "$", code: "USD", name: "US Dollar" },
            }));

          if (validMenuItems.length === 0) return null;

          return (
            <MKBox p={2}>
              <MKTypography variant="h6" gutterBottom>
                {customLink.title || "Menu"}
              </MKTypography>
              {validMenuItems.map((menuItem, idx) => (
                <MKBox key={idx} mb={2}>
                  <MKTypography variant="subtitle1" display="flex" justifyContent="space-between">
                    <span>{menuItem.name}</span>
                    <span>
                      {menuItem.currency.symbol}
                      {menuItem.price}
                    </span>
                  </MKTypography>
                  {menuItem.description && (
                    <MKTypography variant="body2" color="text">
                      {menuItem.description}
                    </MKTypography>
                  )}
                </MKBox>
              ))}
            </MKBox>
          );
        } catch (error) {
          console.error("Error in renderLinkContent:", error);
          return null;
        }
      default:
        return null;
    }
  };

  return (
    <Draggable draggableId={String(item.id)} index={index}>
      {(provided, snapshot) => (
        <MKBox sx={{ marginBottom: 5 }}>
          <MKDeleteModal
            title="Delete Link"
            message="This action is irreversible. Do you want to perform this action ?"
            isOpen={open}
            confirmDelete={() => removeLink(item?.id)}
            cancelAction={setOpen}
          />
          <MKBox
            color="white"
            bgColor="white"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={snapshot.isDragging ? { background: "rgb(235,235,235)" } : ""}
            shadow="lg"
            opacity={1}
            p={2}
            mt={3}
            mb={3}
            key={linkForm.id}
            sx={{ borderRadius: "1rem 1rem 0 0", position: "relative" }}
          >
            <DragIndicatorIcon
              color="light"
              fontSize="large"
              sx={{ position: "absolute", top: "35%", left: "-3px" }}
            />
            <form>
              <Grid container sx={{ padding: "0 20px" }}>
                <Grid item xs={9} md={9} lg={9} sm={9}>
                  <MKBox sx={{ cursor: "pointer" }} onClick={() => addTitle(item.id)}>
                    {(isTitle && currLinkId === index) || item?.title.length ? (
                      <MKInput
                        onChange={validation.handleChange}
                        value={validation.values.title}
                        type="text"
                        placeholder="Title"
                        variant="standard"
                        name="title"
                        InputProps={{
                          disableUnderline: true,
                          autoFocus: true,
                        }}
                        fullWidth
                      />
                    ) : (
                      <MKTypography fontWeight="bold" variant="body2">
                        Title&nbsp;
                        <ModeEditOutlineIcon fontSize="inherit" />
                      </MKTypography>
                    )}
                  </MKBox>
                  <MKBox sx={{ cursor: "pointer" }} onClick={() => addURL(item.id)}>
                    {(isURL && currLinkId === index) || item?.link_ref.length ? (
                      <MKInput
                        onChange={validation.handleChange}
                        value={validation.values.urlRef}
                        type="text"
                        placeholder="URL"
                        variant="standard"
                        name="urlRef"
                        InputProps={{
                          disableUnderline: true,
                          autoFocus: !DisableByTypeLink(item),
                          disabled: DisableByTypeLink(item),
                        }}
                        fullWidth
                      />
                    ) : (
                      <MKTypography variant="h6">
                        Link&nbsp;
                        <ModeEditOutlineIcon fontSize="inherit" />
                      </MKTypography>
                    )}
                  </MKBox>
                </Grid>
                <Grid
                  item
                  xs={3}
                  md={3}
                  lg={3}
                  sm={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Stack direction="row" alignItems="flex-start" justifyContent="flex-end">
                    {validation.values.visible ? (
                      loadingToggle ? (
                        <MKSpinner color="white" size={10} />
                      ) : (
                        <IconButton
                          color="dark"
                          component="span"
                          disabled={isVisible}
                          onClick={() => toggleVisible(!validation.values.visible)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      )
                    ) : loadingToggle ? (
                      <MKSpinner color="white" size={10} />
                    ) : (
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => toggleVisible(!validation.values.visible)}
                      >
                        <VisibilityOff />
                      </IconButton>
                    )}
                    <IconButton
                      color="error"
                      component="span"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  </Stack>
                  <MKButton
                    color={!validation.isValid ? "default" : "dark"}
                    onClick={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    variant="text"
                  >
                    Save
                  </MKButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} sm={12}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-start">
                    {item.mode !== LINK_CREATE && (
                      <>
                        <IconButton>{showIconByTypeLink(item)}</IconButton>
                        <Tooltip title="Thumbnail">
                          <IconButton onClick={() => setIsOpenThumb(!isOpenThumb)}>
                            <ImageOutlinedIcon
                              fontSize="small"
                              color={item?.icon?.length ? "success" : "dark"}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Lifetime clicks">
                          <IconButton>
                            <TrendingUpOutlinedIcon fontSize="medium" color="dark" />{" "}
                            <MKTypography variant="button" color="dark" fontWeight="bold">
                              {item?.clicks > 0 ? `${item?.clicks} clicks` : "0 clicks"}
                            </MKTypography>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Advanced option">
                          <IconButton onClick={() => setIsOpen(!isOpen)}>
                            <SettingsOutlinedIcon fontSize="small" color="dark" />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </MKBox>
          {!validation.isValid && validation.values.urlRef.length ? (
            <MKBox
              sx={{
                borderRadius: "0px 0px 1rem 1rem",
                marginTop: "-25px",
              }}
              bgColor="dark"
              textAlign="center"
            >
              <MKTypography color="white" variant="body2" alignItems="center">
                {validation.errors.urlRef}
              </MKTypography>
            </MKBox>
          ) : null}
          {isOpen && advancedOption(item)}
          {isOpenThumb && thumbnailOption(item)}
          {doNotForgetSaving()}
          <MKModalThumbnail isOpen={openImg} cancel={onCloseImg}>
            {activeStep === 0 ? (
              <MKBox
                mt={4}
                display="flex"
                justifyContent="space-between"
                direction="row"
                alignItems="center"
              >
                <MKBox className="thumbnail-box">
                  <input type="file" accept="image/*" id="input_file" ref={ref} hidden />
                  <MKBox
                    onClick={() => {
                      handleClickUpload();
                    }}
                  >
                    <MKTypography variant="h5" fontWeight="bold" textAlign="start">
                      Upload your own thumbnail
                    </MKTypography>
                  </MKBox>
                  <MKTypography variant="button">From your computer</MKTypography>
                </MKBox>
                <ArrowForwardIosIcon fontSize="small" color="dark" />
              </MKBox>
            ) : null}

            {activeStep === 1 ? (
              <MKBox component="form" role="form" mt={5} width="100%">
                <MKInput
                  name="icon"
                  value={validation.values.icon}
                  onChange={validation.handleChange}
                  type="text"
                  label="Enter a URL"
                  fullWidth
                />
                <MKButton
                  variant="gradient"
                  onClick={(e) => {
                    e.preventDefault();
                    setThumbnailByURL(item);
                    return false;
                  }}
                  color="primary"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                >
                  Add
                </MKButton>
              </MKBox>
            ) : null}
          </MKModalThumbnail>
          {renderLinkContent(item)}
        </MKBox>
      )}
    </Draggable>
  );
};

// Create a new component for menu listing options
const MenuListingOption = ({ customLink }) => {
  const { state, dispatch } = useContext(AuthContext);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // Add loading state

  const [menuItems, setMenuItems] = useState(() => {
    try {
      return JSON.parse(customLink.link_ref);
    } catch {
      return [];
    }
  });

  // Create validation object for the form
  const validation = useFormik({
    initialValues: {
      menuItems: menuItems,
    },
    onSubmit: async (values) => {
      await handleUpdateMenu(values.menuItems);
    },
    validate: (values) => {
      const errors = {};
      if (!values.menuItems || values.menuItems.length === 0) {
        errors.menuItems = "Please add at least one menu item";
      }
      return errors;
    },
  });

  const handleUpdateMenu = async (updatedItems) => {
    setIsSaving(true);
    try {
      const jtoken = getCookie("taprr-token");
      const sanitizedItems = updatedItems.map((item) => ({
        name: item.name.trim(),
        price:
          typeof item.price === "string"
            ? parseFloat(item.price).toFixed(2)
            : item.price.toFixed(2),
        description: item.description?.trim() || "",
        currency: item.currency || { symbol: "$", code: "USD", name: "US Dollar" },
        footnote: item.footnote?.trim() || "", // Include footnote in sanitized items
      }));
      const jsonSanitizedItems = JSON.stringify(sanitizedItems);
      const updatedLink = {
        ...customLink,
        link_ref: jsonSanitizedItems,
      };

      const response = await updateCustomLinksItem(jtoken, updatedLink);
      if (response?.success) {
        dispatch.updateCustomLinks(response.response);
        setEditMode(false);
        // Update local state to reflect changes.
        setMenuItems(jsonSanitizedItems);
        const iframeEle = document.getElementById("profile-preview");
        iframeEle?.contentWindow?.location?.reload();
      }
    } catch (error) {
      console.error("Error updating menu:", error);
    } finally {
      setIsSaving(false); // End loading regardless of outcome
    }
  };

  return (
    <MKBox>
      {editMode ? (
        <>
          <MenuListingForm validation={validation} initialData={menuItems} />
          <MKBox display="flex" justifyContent="space-between" mt={2}>
            <MKButton variant="outlined" color="dark" onClick={() => setEditMode(false)}>
              Cancel
            </MKButton>
            <MKButton
              variant="contained"
              color="info"
              onClick={() => validation.handleSubmit()}
              disabled={isSaving}
            >
              {isSaving ? (
                <MKBox display="flex" alignItems="center">
                  <MKSpinner size={20} color="white" mr={1} />
                  <span>Saving...</span>
                </MKBox>
              ) : (
                "Save Changes"
              )}
            </MKButton>
          </MKBox>
        </>
      ) : (
        <>
          {/* <MenuListingPreview items={menuItems} /> */}
          <MKButton
            variant="outlined"
            color="primary"
            onClick={() => {
              validation.setValues({ menuItems });
              setEditMode(true);
            }}
            fullWidth
            sx={{ mt: 2 }}
          >
            Edit Menu Items
          </MKButton>
        </>
      )}
    </MKBox>
  );
};

export default DraggableListItem;
