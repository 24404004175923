import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Link from "@mui/material/Link";
import taprrQRCode from "assets/images/taprr/taprr_qr.png";
import printingCard from "assets/images/taprr/printing_card.png";
import taprrPort from "assets/images/taprr/tappr_port.png";

const LandingPrinting = () => {
  return (
    <MKBox pb={10} sx={{ backgroundColor: "#F9F9F9" }}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <MKBox
              component="img"
              src={taprrQRCode}
              alt="scan taprr QR Code"
              loading="lazy"
              sx={{
                width: "320px",
                height: "300px",
                marginTop: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} pt={10}>
            <MKTypography
              variant="h3"
              fontWeight="bold"
              sx={({ breakpoints }) => ({
                fontSize: 40,
                color: "#222832",
                fontFamily: "sans-serif",
                [breakpoints.down("sm")]: {
                  fontSize: 35,
                },
              })}
            >
              QR Codes for Bars & Restaurants
            </MKTypography>
            <MKTypography sx={{ color: "#808091", fontSize: "18px", marginTop: 3 }}>
              Streamline customer experiences with customized QR codes. Simplify menus, promotions,
              and reservations, transforming engagement while reducing printing costs.
            </MKTypography>
            <MKTypography
              component={Link}
              href="/signup"
              variant="body2"
              color="secondary"
              fontWeight="bold"
              sx={{
                color: "#222832",
                textDecoration: "underline",
                fontSize: "18px",
              }}
            >
              Learn more
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container pt={5}>
          <Grid item xs={12} md={6}>
            <MKTypography
              variant="h3"
              sx={({ breakpoints }) => ({
                fontSize: 40,
                color: "#222832",
                fontFamily: "sans-serif",
                fontWeight: "bold",
                [breakpoints.down("sm")]: {
                  fontSize: 35,
                },
              })}
            >
              Eco-Friendly and Cost-Effective
            </MKTypography>
            <MKTypography sx={{ color: "#808091", fontSize: "18px", marginTop: 3 }}>
              Go paperless, save money, and embrace sustainability with Taprr.
            </MKTypography>
            <MKTypography
              component={Link}
              href="/signup"
              variant="body2"
              color="secondary"
              fontWeight="bold"
              sx={{
                color: "#222832",
                fontSize: "18px",
              }}
            >
              Learn more
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
          >
            <MKBox
              component="img"
              src={printingCard}
              alt="taprrCard"
              loading="lazy"
              sx={{
                width: "345px",
                height: "365px",
                marginTop: 3,
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6}>
            <MKBox
              component="img"
              src={taprrPort}
              alt="taprrCard"
              loading="lazy"
              sx={{
                width: "400px",
                height: "478px",
                marginTop: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} pt={10}>
            <MKTypography
              variant="h3"
              fontWeight="bold"
              sx={({ breakpoints }) => ({
                fontSize: 40,
                color: "#222832",
                fontFamily: "sans-serif",
                [breakpoints.down("sm")]: {
                  fontSize: 35,
                },
              })}
            >
              Sales &amp; Employees Tracking for Companies
            </MKTypography>
            <MKTypography sx={{ color: "#808091", fontSize: "18px", marginTop: 3 }}>
              Taprr enables businesses to monitor the performance of their sales teams by
              integratingdigital business cards with tracking tools. Managers can gain insights into
              how employees engage with clients, track the frequency of interactions, and measure
              lead conversion rates. This ensures accountability and supports data-driven
              decision-making, boosting sales efficiency.
            </MKTypography>
            <MKTypography
              component={Link}
              href="/signup"
              variant="body2"
              color="secondary"
              fontWeight="bold"
              sx={{
                color: "#222832",
                textDecoration: "underline",
                fontSize: "18px",
              }}
            >
              Learn more
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default LandingPrinting;
