import React, { useContext, useState, useRef, memo } from "react";
import Snackbar from "@mui/material/Snackbar";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKSocialModal from "components/MKSocialModal";
import MKAvatar from "components/MKAvatar";
import MKSpinner from "components/MKSpinner";
import IconSocial from "components/IconSocial";
import Popover from "@mui/material/Popover";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import PhotoCameraFrontOutlinedIcon from "@mui/icons-material/PhotoCameraFrontOutlined";
import PhotoSizeSelectLargeOutlinedIcon from "@mui/icons-material/PhotoSizeSelectLargeOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Fade from "@mui/material/Fade";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import QrCodeOutlinedIcon from "@mui/icons-material/QrCodeOutlined";
import Divider from "@mui/material/Divider";

import SubscriptionModal from "components/SubscriptionModal";

// form validation with Formik
import { useFormik } from "formik";
import * as Yup from "yup";
import * as regex from "regex";

// App context
import AuthContext from "context/AuthContext";

// api call
import {
  removeProfilePicture,
  getCookie,
  uploadProfilePicture,
  updateUserProfile,
  updateUserTheme,
  uploadProfileCover,
  generatePass,
} from "api";
import { defaultProfilePic } from "utils";
import ADD_TAPRR_TO_APPLE_WALLET from "assets/images/taprr/add_taprr_to_apple_wallet.png";

// appearance color
import appearance from "assets/theme/appearance";

import qrcode from "qrcode-generator";

const Profile = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popno, setPopno] = useState(-1);
  const [responseMssg, setResponseMssg] = useState("");
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  const ref = useRef();
  const coverImgref = useRef();
  const jtoken = getCookie("taprr-token");
  const [isUploadingCover, setIsUploadingCover] = useState(false);
  const [isGeneratingQR, setIsGeneratingQR] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const reFreshIFrame = () => {
    const iframeEle = document.getElementById("profile-preview");
    iframeEle.contentWindow.location.reload();
  };

  const removePicture = async () => {
    const currentPic = state.userProfile?.picture;

    if (navigator.onLine) {
      if (currentPic === defaultProfilePic) return;
      setLoading2(!loading2);
      const res = await removeProfilePicture(jtoken);
      if (res.success) {
        setLoading2(false);
        dispatch.updatePicture(res.response);
        // reFreshIFrame();
        setResponseMssg("Profile picture removed");
        setOpenToolSnack({
          open: true,
          Fade,
        });
      }
    } else {
      setError("You're offline. Please check your network connection...");
    }
  };

  // const removeProfileCover = async () => {
  //   const currentProfileCover = state.userProfile?.cover_img;
  //   if (!currentProfileCover?.length) return;

  //   if (navigator.onLine) {
  //     const res = await deleteProfileVideo(jtoken);
  //     if (res.success) {
  //       reFreshIFrame();
  //       dispatch.resetProfileVideo();
  //       setResponseMssg("Profile video removed");
  //       setOpenToolSnack({
  //         open: true,
  //         Fade,
  //       });
  //     }
  //   } else {
  //     setError("You're offline. Please check your network connection...");
  //   }
  // };

  const handleClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  const uploadPicture = () => {
    let inputFile = document.getElementById("input_file");
    if (!inputFile) {
      inputFile = document.createElement("input");
      inputFile.setAttribute("type", "file");
      inputFile.setAttribute("id", "input_file");
      inputFile.setAttribute("accept", "image/jpeg,image/png,image/jpg");
      inputFile.style.display = "none";
      document.body.appendChild(inputFile);
    }

    const handleFileChange = async (event) => {
      try {
        const file = event.target.files[0];

        if (!file) {
          setError("No file selected. Please choose a file.");
          return;
        }
        setIsUploading(true);
        // Check file size (5 MB limit)
        const maxSize = 5 * 1024 * 1024;
        if (file.size > maxSize) {
          setError("File size exceeds the 5 MB limit. Please choose a smaller file.");
          return;
        }

        // Check file type
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!validTypes.includes(file.type)) {
          setError("Invalid file type. Please upload a JPEG or PNG image.");
          return;
        }

        const formData = new FormData();
        formData.append("picture", file);
        formData.append("jtoken", jtoken);

        if (navigator.onLine) {
          setIsUploading(true); // Start loading
          setError(""); // Clear any previous errors

          const res = await uploadProfilePicture(formData);

          if (res.success) {
            const pictureUrl = `${res.pictureUrl}`;
            // Create a promise to handle image loading
            const loadImage = () => {
              return new Promise((resolve, reject) => {
                const img = new Image();

                img.onload = () => {
                  resolve(pictureUrl);
                };

                img.onerror = () => {
                  reject(new Error("Failed to load image"));
                };

                img.src = pictureUrl;
              });
            };

            try {
              const loadedImageUrl = await loadImage();
              dispatch.updatePicture(loadedImageUrl);
              //reFreshIFrame();
              setResponseMssg("Profile picture successfully uploaded");
              setOpenToolSnack({
                open: true,
                Transition: Fade,
              });
            } catch (loadError) {
              setError("Failed to load the uploaded image");
            }
          } else {
            throw new Error(res.message || "Upload failed");
          }
        } else {
          setError("You're offline. Please check your network connection...");
        }
      } catch (error) {
        setError(error.message || "An error occurred while uploading the picture.");
      } finally {
        setIsUploading(false);
        inputFile.value = "";
      }
    };

    // Remove old event listeners and add new one
    inputFile.removeEventListener("change", handleFileChange);
    inputFile.addEventListener("change", handleFileChange);

    // Trigger file selection
    inputFile.click();
  };

  const uploadCoverImg = () => {
    let inputFile = document.getElementById("input_file_cover");
    if (!inputFile) {
      inputFile = document.createElement("input");
      inputFile.setAttribute("type", "file");
      inputFile.setAttribute("id", "input_file_cover");
      inputFile.setAttribute("accept", "image/jpeg,image/png,image/jpg");
      inputFile.style.display = "none";
      document.body.appendChild(inputFile);
    }

    const handleFileChange = async (event) => {
      try {
        const file = event.target.files[0];
        if (!file) {
          setError("No file selected. Please choose a file.");
          return;
        }
        setIsUploadingCover(true);
        // Validate file size
        const maxSize = 10 * 1024 * 1024; // 10MB
        if (file.size > maxSize) {
          setError("File size should be less than 10MB");
          return;
        }

        // Check file type
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        if (!validTypes.includes(file.type)) {
          setError("Invalid file type. Please upload a JPEG or PNG image.");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);
        formData.append("jtoken", jtoken);
        if (navigator.onLine) {
          const res = await uploadProfileCover(formData);
          setIsUploadingCover(true);
          setError("");

          if (res.success) {
            dispatch.updateCoverImg(res.response);
            //reFreshIFrame();
            setResponseMssg("Cover image successfully uploaded");
            setOpenToolSnack({
              open: true,
              Transition: Fade,
            });
          } else {
            throw new Error(res.message || "Upload failed");
          }
        } else {
          setError("You're offline. Please check your network connection...");
        }
      } catch (error) {
        setError(error.message || "An error occurred while uploading the image.");
      } finally {
        setIsUploadingCover(false);
        inputFile.value = "";
      }
    };

    // Clean up old listener and add new one
    inputFile.removeEventListener("change", handleFileChange);
    inputFile.addEventListener("change", handleFileChange);
    inputFile.click();
  };

  const handleGeneratePass = async () => {
    const hasActiveSubscription =
      state.userProfile.subscription?.status === "active" ||
      state.userProfile.subscription?.status === "trialing";

    if (!hasActiveSubscription) {
      setShowSubscriptionModal(true);
      return;
    }
    try {
      const username = state.userProfile.username;
      const result = await generatePass({
        name: state.userProfile.name,
        bio: state.userProfile.bio,
        qrCodeData: `https://taprr.com/${username}`,
      });
      // console.log(result.message);
      // Handle success (e.g., show a success message to the user)
    } catch (error) {
      console.error(error.message);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const generateAndDownloadQRCode = async () => {
    const hasActiveSubscription =
      state.userProfile.subscription?.status === "active" ||
      state.userProfile.subscription?.status === "trialing";

    if (!hasActiveSubscription) {
      setShowSubscriptionModal(true);
      return;
    }
    try {
      setIsGeneratingQR(true);
      const { username } = state.userProfile;
      const qr = qrcode(0, "L");
      qr.addData(`https://taprr.com/${username}`);
      qr.make();
      const qrCodeDataURL = qr.createDataURL(10);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = qrCodeDataURL;
      link.download = `${username}-qrcode.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setResponseMssg("QR Code downloaded successfully");
      setOpenToolSnack({
        open: true,
        Transition: Fade,
      });
    } catch (error) {
      console.error("Error generating QR code:", error);
      setError("Failed to generate QR code");
    } finally {
      setIsGeneratingQR(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: state.userProfile.name ?? "",
      useremail: state.userProfile.email,
      bio: state.userProfile.bio ?? "",
      contactnumber: state.userProfile.contactnumber ?? "",
    },
    validate: (values) => {
      const errors = {};
      if (!regex.email.test(values.useremail)) {
        errors.useremail = "Invalid email address";
      }
      return errors;
    },
    validationSchema: Yup.object({
      useremail: Yup.string().required("Enter a valid Email"),
    }),
    onSubmit: async (values) => {
      if (type === "UPDATE_PROFILE") {
        setSaveLoading(!saveLoading);
        const { name, useremail, bio, contactnumber } = values;
        const res = await updateUserProfile({
          name,
          useremail,
          username: state?.userProfile?.username,
          bio,
          contactnumber,
          theme: state?.userProfile?.theme,
          jtoken,
          fbid: state?.userProfile?.fb_id,
          twitterid: state?.userProfile?.twitter_id,
          igid: state?.userProfile?.ig_id,
          tikid: state?.userProfile?.tik_id,
          whatsappid: state?.userProfile?.whatsapp_id,
          linkedinid: state?.userProfile?.linkedin_id,
          socialposition: state?.userProfile?.social_position,
          picURl: state?.userProfile?.picture,
          cover_img: state?.userProfile?.cover_img,
        });
        if (res.success) {
          setType("");
          setSaveLoading(false);
          dispatch.getDetails(res.response[0]);

          //reFreshIFrame();
          setResponseMssg(res?.message);
          setOpenToolSnack({
            open: true,
            Fade,
          });
        } else {
          setType("");
          setResponseMssg(res?.message);
          setSaveLoading(false);
          setOpenToolSnack({
            open: true,
            Fade,
          });
        }
      }
      if (type === "UPDATE_SOCIAL_LINKS") {
        setLoading3(!loading3);
      }
    },
  });

  const handleClick = (event) => {
    if (!isUploading) {
      setAnchorEl(event.currentTarget);
      setPopno(1);
    }
  };

  const handlePopoverOpen = (event, _popno) => {
    setAnchorEl(event.currentTarget);
    setPopno(_popno);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopno(-1);
  };

  const changeTheme = async (theme) => {
    const res = await updateUserTheme({
      theme: theme?.mode,
      jtoken,
    });
    if (res.success) {
      dispatch.getDetails(res.response);
      dispatch.changeTheme(theme?.mode);
      localStorage.setItem("TAPRR_THEME", JSON.stringify({ ...theme }));
      //reFreshIFrame();
      setResponseMssg("Profile theme updated");
      setOpenToolSnack({
        open: true,
        Fade,
      });
    }
  };

  const ShowPhotoMenu = memo(({ onRequestClose }) => {
    const elem = (
      <MKBox mt={2} mb={1} className="popover_container">
        <Stack spacing={1}>
          <MKTypography alignSelf="center" variant="button" fontWeight="medium" color="light">
            {" "}
            Profile Picture
          </MKTypography>
          <MKButton
            color="dark"
            varient="outlined"
            onClick={() => {
              uploadPicture();
              onRequestClose();
            }}
            disabled={isUploading}
            startIcon={
              isUploading ? <MKSpinner size={20} color="dark" /> : <CloudUploadOutlinedIcon />
            }
          >
            {isUploading ? "Uploading..." : "Upload Picture"}
          </MKButton>
          <Divider light />
          <MKButton
            color="dark"
            size="small"
            varient="text"
            onClick={() => {
              removePicture();
              onRequestClose();
            }}
            disabled={isUploading}
            startIcon={<DeleteForeverOutlinedIcon />}
          >
            {isUploading ? "Removing..." : "Remove Picture"}
          </MKButton>
        </Stack>
      </MKBox>
    );
    return elem;
  });

  const ShowCoverMenu = memo(({ onRequestClose }) => (
    <MKBox p={2} className="popover_container">
      <Stack spacing={2}>
        <MKButton
          variant="text"
          color="light"
          onClick={() => {
            uploadCoverImg();
            onRequestClose();
          }}
          disabled={isUploadingCover}
          startIcon={
            isUploadingCover ? <MKSpinner size={20} color="dark" /> : <CloudUploadOutlinedIcon />
          }
        >
          {isUploadingCover ? "Uploading..." : "Upload Cover"}
        </MKButton>
      </Stack>
    </MKBox>
  ));

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Grid container>
        <Snackbar
          open={openToolSnack.open}
          onClose={handleClose}
          TransitionComponent={openToolSnack.Transition}
          message={responseMssg}
          className="snackBar_container"
        />
        <MKSocialModal title="Add social link" setIsOpen={setIsOpen} isOpen={isOpen} />
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <MKTypography className="text-center" mt={2} mb={2}>
            Profile
          </MKTypography>
          <MKBox color="white" bgColor="white" borderRadius="lg" shadow="lg" opacity={1} p={2}>
            <MKBox display="flex" flexDirection="row" justifyContent="center">
              <MKAvatar
                src={state.userProfile.picture}
                alt={state.userProfile.name}
                variant="circular"
                size="xxl"
                sx={{ border: "2px solid rgba(0, 0, 0, 0.05)" }}
              />
            </MKBox>
            <MKBox component="form" role="form">
              <MKBox mt={2} mb={2}>
                <MKInput
                  type="text"
                  variant="standard"
                  name="name"
                  label="Name"
                  value={validation.values.name}
                  onChange={validation.handleChange}
                  fullWidth
                />
              </MKBox>
              <MKBox mt={2} mb={2}>
                <MKInput
                  type="email"
                  variant="standard"
                  name="useremail"
                  label="Email"
                  value={validation.values.useremail}
                  onChange={validation.handleChange}
                  error={!!(validation.touched.useremail && validation.errors.useremail)}
                  fullWidth
                />
                {validation.touched.useremail && validation.errors.useremail ? (
                  <MKTypography variant="button" color="error">
                    {validation.errors.useremail}
                  </MKTypography>
                ) : null}
              </MKBox>
              <MKBox mt={2} mb={2}>
                <MKInput
                  type="tel"
                  variant="standard"
                  name="contactnumber"
                  label="Contact Number (Add your number to enable smooth exchange of contact)"
                  value={validation.values.contactnumber}
                  onChange={validation.handleChange}
                  error={!!(validation.touched.contactnumber && validation.errors.contactnumber)}
                  fullWidth
                />
                {validation.touched.contactnumber && validation.errors.contactnumber ? (
                  <MKTypography variant="button" color="error">
                    {validation.errors.contactnumber}
                  </MKTypography>
                ) : null}
              </MKBox>
              <MKBox mt={4} mb={2}>
                <MKInput
                  type="text"
                  multiline
                  rows={3}
                  maxLength={10}
                  name="bio"
                  label="Bio"
                  value={validation.values.bio}
                  onChange={validation.handleChange}
                  fullWidth
                  error={!!(validation.touched.bio && validation.errors.bio)}
                />
                {validation.touched.bio && validation.errors.bio ? (
                  <MKTypography variant="button" color="error">
                    {validation.errors.bio}
                  </MKTypography>
                ) : null}
              </MKBox>
              <MKBox mt={3}>
                <MKButton
                  variant="gradient"
                  disabled={!validation.values.useremail || !validation.values.name}
                  onClick={(e) => {
                    e.preventDefault();
                    setType("UPDATE_PROFILE");
                    validation.handleSubmit();
                    return false;
                  }}
                  color="primary"
                >
                  {saveLoading ? <MKSpinner color="white" size={20} /> : "Save details"}
                </MKButton>
              </MKBox>
              {error ? (
                <MKBox mt={2} mb={1}>
                  <MKTypography variant="button" color="error">
                    {error}
                  </MKTypography>
                </MKBox>
              ) : null}
            </MKBox>
          </MKBox>
          {/*Add Profile Pcitures below*/}
          <MKTypography textAlign="start" mt={2} mb={2}>
            Profile Picture & Cover Image
          </MKTypography>
          <MKBox color="white" bgColor="white" borderRadius="lg" shadow="lg" opacity={1} p={2}>
            <MKBox
              sx={({ breakpoints }) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flexWrap: "nowrap",
                justifyContent: "space-around",
                [breakpoints.up("md")]: {
                  flexDirection: "row",
                  flexWrap: "wrap",
                },
              })}
            >
              <input accept="image/*" id="input_file_cover" type="file" ref={coverImgref} hidden />
              <input type="file" accept="image/*" id="input_file" ref={ref} hidden />
              <MKButton
                variant="outlined"
                color="dark"
                className="taprr_custom__btn light-lemo-green"
                onClick={handleClick}
                disabled={isUploading}
                size="medium"
              >
                <MKBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <MKBox>
                    <MKTypography variant="button" fontWeight="bold">
                      {isUploading ? "Uploading..." : "Add / Replace Profile Picture"}
                    </MKTypography>
                    <br />
                    <MKTypography variant="caption" fontWeight="light">
                      {isUploading ? "Please wait..." : "Click to upload picture"}
                    </MKTypography>
                  </MKBox>
                  {isUploading ? (
                    <MKSpinner size={20} color="info" />
                  ) : (
                    <PhotoCameraFrontOutlinedIcon
                      sx={{
                        width: "45px",
                        height: "45px",
                        backgroundColor: "#1e3f49",
                        borderRadius: "15px",
                        padding: "5px",
                        color: "white",
                        ml: 2,
                      }}
                    />
                  )}
                </MKBox>
              </MKButton>
              <MKButton
                variant="outlined"
                color="dark"
                size="medium"
                className="taprr_custom__btn light-purple"
                onClick={(e) => handlePopoverOpen(e, 2)}
                aria-describedby={id}
                disabled={isUploadingCover}
              >
                <MKBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <MKBox>
                    <MKTypography variant="button" fontWeight="bold">
                      {isUploadingCover ? "Uploading..." : "Profile Cover Image"}
                    </MKTypography>
                    <br />
                    <MKTypography variant="caption" fontWeight="light">
                      {isUploadingCover ? "Please wait..." : "Click to add cover image"}
                    </MKTypography>
                  </MKBox>
                  {isUploadingCover ? (
                    <MKSpinner size={20} color="info" />
                  ) : (
                    <PhotoSizeSelectLargeOutlinedIcon
                      sx={{
                        width: "45px",
                        height: "45px",
                        backgroundColor: "#1e3f49",
                        borderRadius: "15px",
                        padding: "5px",
                        color: "white",
                        ml: 2,
                      }}
                    />
                  )}
                </MKBox>
              </MKButton>
              <MKButton
                variant="outlined"
                color="dark"
                size="medium"
                className="taprr_custom__btn light-blue"
                onClick={() => generateAndDownloadQRCode()}
                disabled={isGeneratingQR}
              >
                <MKBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <MKBox>
                    <MKTypography variant="button" fontWeight="bold">
                      {isGeneratingQR ? "Generating..." : "Download Your Profile QR Code"}
                    </MKTypography>
                    <br />
                    <MKTypography variant="caption" fontWeight="light">
                      {isGeneratingQR ? "Please wait..." : "Add your qr code to email signatures"}
                    </MKTypography>
                  </MKBox>
                  {isGeneratingQR ? (
                    <MKSpinner size={20} color="info" />
                  ) : (
                    <QrCodeOutlinedIcon
                      sx={{
                        width: "45px",
                        height: "45px",
                        backgroundColor: "#1e3f49",
                        borderRadius: "15px",
                        padding: "5px",
                        color: "white",
                        ml: 2,
                      }}
                    />
                  )}
                </MKBox>
              </MKButton>
              <MKButton onClick={() => handleGeneratePass()} sx={{ minWidth: "380px" }}>
                <MKBox
                  component="img"
                  src={ADD_TAPRR_TO_APPLE_WALLET}
                  alt={`add_taprr_to_apple_wallet`}
                  loading="lazy"
                  ml={2}
                  sx={{
                    width: "180px",
                    height: "60px",
                    borderRadius: "15px",
                    padding: "5px",
                  }}
                />
              </MKButton>
              <Popover
                id={id}
                sx={{
                  backgroundColor: "black",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                {popno === 1 && <ShowPhotoMenu onRequestClose={handlePopoverClose} />}
                {popno === 2 && <ShowCoverMenu onRequestClose={handlePopoverClose} />}
              </Popover>

              {error ? (
                <MKBox mt={2} mb={1}>
                  <MKTypography variant="button" fontWeight="bold">
                    {error}
                  </MKTypography>
                </MKBox>
              ) : null}
            </MKBox>
          </MKBox>
          <MKTypography textAlign="start" mt={2} mb={2}>
            Social media
          </MKTypography>
          <MKBox color="white" bgColor="white" borderRadius="lg" shadow="lg" opacity={1} p={2}>
            <MKBox>
              <MKTypography textAlign="start" mb={2} variant="body2">
                Display links to your social profiles on your Taprr page.
              </MKTypography>
              <MKBox mt={2}>
                <IconSocial />
              </MKBox>
              {error ? (
                <MKBox mt={2} mb={1}>
                  <MKTypography variant="button" color="error">
                    {error}
                  </MKTypography>
                </MKBox>
              ) : null}
            </MKBox>
          </MKBox>
          <MKTypography textAlign="start" mt={2} mb={1}>
            Themes
          </MKTypography>
          <MKTypography textAlign="start" mb={2} variant="body2">
            Customize your Taprr profile. Change your app background with colours.
          </MKTypography>
          <MKBox
            color="white"
            bgColor="white"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
          >
            {appearance.map((item) => (
              <MKBox
                key={item?.mode}
                mx={2}
                mb={2}
                mt={2}
                sx={{ cursor: "pointer" }}
                onClick={() => changeTheme(item)}
                position="relative"
              >
                <MKBox
                  sx={({ breakpoints }) => ({
                    backgroundColor: item.backgroundColor ?? "transparent",
                    backgroundImage: `url(${item?.demo})` ?? "",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    borderRadius: 2,
                    height: 170,
                    width: 90,
                    [breakpoints.up("md")]: {
                      width: 120,
                    },
                  })}
                >
                  {state?.userProfile?.theme === item?.mode ? (
                    <CheckCircleOutlinedIcon
                      fontSize="medium"
                      color="success"
                      sx={{ top: 5, right: 5, position: "absolute" }}
                    />
                  ) : null}
                </MKBox>
                <MKTypography fontWeight="bold" textAlign="center" variant="body2" mt={1}>
                  {item?.label}
                </MKTypography>
              </MKBox>
            ))}
          </MKBox>
        </Grid>
      </Grid>
      <SubscriptionModal
        open={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </>
  );
};

export default Profile;
