/**
 * Reuseable utility const / function for the application
 */
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import StyleIcon from "@mui/icons-material/Style";
import EqualizerIcon from "@mui/icons-material/Equalizer";

import {
  Person as PersonIcon,
  Settings as SettingsIcon,
  PermContactCalendar as PermContactCalendarIcon,
  CreditCard as CreditCardIcon,
  List as ListIcon,
  Preview as PreviewIcon,
  Groups as GroupsIcon,
} from "@mui/icons-material";
import maria from "assets/images/maria.png";
import samantha from "assets/images/samantha.png";

export const defaultProfilePic =
  "https://fanbiesapp.s3.eu-west-2.amazonaws.com/2019-03-13/1552479858884_209.png";

export const profileVideoLimit = 15000000;
export const requestVideoLimit = 30000000;
export const productImgLimit = 30000000;

export const LINK_CREATE = "create";

export const IP_LOOKUP_URL = "https://api.ipify.org?format=json";

export const renderDateStamp = (stamp) => {
  // Convert timestamp to milliseconds
  const monthsArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(stamp * 1000);

  // Year
  const year = date.getFullYear();

  // Month
  const month = monthsArray[date.getMonth()];

  // Day
  const day = date.getDate();

  // Display date time in MM-dd-yyyy h:m:s format
  const convdataTime = `${month}-${day}-${year}`;

  return convdataTime;
};

export const LINKTYPE = Object.freeze({
  YOUTUBE: "YOUTUBE",
  NORMAL: "NORMAL",
  PAYPAL: "PAYPAL",
  CALENDLY: "CALENDLY",
  YOUTUBE_SUB: "YOUTUBE_SUBSCRIBERS",
  FILE_UPLOAD: "FILE_UPLOAD",
  MENU_LISTING: "MENU_LISTING",
});

export const EXTERNALURL = Object.freeze({
  WHATSAPP: "https://api.whatsapp.com/send?phone=",
  TIKTOK: "https://tiktok.com/@",
  LINKEDIN: "https://linkedin.com/in/",
  INSTAGRAM: "https://instagram.com/",
  FACEBOOK: "https://facebook.com/",
  TWITTER: "https://twitter.com/",
  PAYPAL: "https://paypal.com/paypalme",
  YOUTUBE_CHANNEL: "?sub_confirmation=1",
  SPOTIFY: "https://open.spotify.com/artist/",
  APPLEMUSIC: "https://music.apple.com/",
  EMAIL: "mailto:",
});

export const ANIMATIONENUM = Object.freeze({
  SHAKE: "shake",
  SHAKE_HOVER: "shake_on_hover",
  SWING: "swing",
  SWING_HOVER: "swing_on_hover",
  RUBBERBAND: "rubber_band",
  RUBBERBAND_HOVER: "rubber_band_on_hover",
  PULSE: "pulse",
});

export const EXTRALINKS = [
  {
    id: 1,
    name: "Menu Listing",
    desc: "Create a menu for your restaurant or bar",
    type: LINKTYPE.MENU_LISTING,
    icon: LINKTYPE.FILE_UPLOAD,
  },
  {
    id: 2, // Make sure this ID is unique
    name: "Upload File Link",
    desc: "Share downloadable files",
    type: LINKTYPE.FILE_UPLOAD,
  },
  {
    id: 5,
    name: "Get / Receive TIPS via Paypal",
    desc: "Buy me a coffee with Paypal",
    type: LINKTYPE.PAYPAL,
  },
  {
    id: 3,
    name: "Share A Calendly Link",
    desc: "Share available calendly bookings",
    type: LINKTYPE.CALENDLY,
  },
  {
    id: 4,
    name: "Increase YouTube Subscriber",
    desc: "Custom link to help grow your subscribers",
    type: LINKTYPE.YOUTUBE_SUB,
  },
  {
    id: 6,
    name: "Add YouTube Video Link",
    desc: "Share YouTube videos",
    type: LINKTYPE.YOUTUBE,
  },
];

export const SHARINGLINKS = [
  {
    id: 1,
    name: "Share on Twitter",
    link: "https://twitter.com/intent/tweet?text=Check out this Taprr user ! - ",
    img: (props) => <TwitterIcon {...props} />,
  },
  {
    id: 2,
    name: "Share on Facebook",
    link: "https://www.facebook.com/sharer/sharer.php?u=",
    img: (props) => <FacebookOutlinedIcon {...props} />,
  },
  {
    id: 3,
    name: "Share on Whatsapp",
    link: "https://wa.me/?text=Check out this Taprr user ! - ",
    img: (props) => <WhatsAppIcon {...props} />,
  },
  {
    id: 4,
    name: "Share on LinkedIn",
    link: "https://www.linkedin.com/sharing/share-offsite/?url=",
    img: (props) => <LinkedInIcon {...props} />,
  },
];

export const ANIMATION = [
  {
    id: 1,
    label: "Default",
    linkAnimation: "default",
  },
  {
    id: 2,
    label: "Shake Infinite",
    linkAnimation: "shake",
  },
  {
    id: 3,
    label: "Shake Hover",
    linkAnimation: "shake_on_hover",
  },
  {
    id: 4,
    label: "Swing Infinite",
    linkAnimation: "swing",
  },
  {
    id: 5,
    label: "Swing Hover",
    linkAnimation: "swing_on_hover",
  },
  {
    id: 6,
    label: "Rubber Band Infinite",
    linkAnimation: "rubber_band",
  },
  {
    id: 7,
    label: "Rubber Band Hover",
    linkAnimation: "rubber_band_on_hover",
  },
  {
    id: 8,
    label: "Pulse Hover",
    linkAnimation: "pulse",
  },
];

export const renderLinkAnimation = (link) => {
  switch (link) {
    case ANIMATIONENUM.SHAKE:
      return "shake-animation";
    case ANIMATIONENUM.SHAKE_HOVER:
      return "shake-animation-hover";
    case ANIMATIONENUM.SWING:
      return "swing-animation";
    case ANIMATIONENUM.SWING_HOVER:
      return "swing-animation-hover";
    case ANIMATIONENUM.RUBBERBAND:
      return "rubberBand-animation";
    case ANIMATIONENUM.RUBBERBAND_HOVER:
      return "rubberBand-animation-hover";
    case ANIMATIONENUM.PULSE:
      return "pulse-animation";
    default:
      return "";
  }
};

export const menuItems = [
  { text: "Page Links", icon: <ListIcon />, section: "addlink", type: "normal" },
  { text: "Profile", icon: <PersonIcon />, section: "profile", type: "normal" },
  { text: "Contacts", icon: <PermContactCalendarIcon />, section: "contacts", type: "normal" },
  { text: "Analytics", icon: <EqualizerIcon />, section: "analytics", type: "normal" },
  { text: "Custom Card", icon: <StyleIcon />, section: "customcard", type: "normal" },
  { text: "Profile Preview", icon: <PreviewIcon />, section: "previewpage", type: "normal" },
  { text: "Billing", icon: <CreditCardIcon />, section: "billing", type: "normal" },
  { text: "Group Users", icon: <GroupsIcon />, section: "groupuser", type: "admin" },
  { text: "Settings", icon: <SettingsIcon />, section: "settings", type: "normal" },
];
// { text: "QR Code", icon: <QrCodeIcon />, section: "qrcode", type: "normal" },

export const DEMO_ACCESS_CODE = "FanbiesTaprr!";

export const LINK_APPEARANCE_TYPE = [
  {
    id: 1,
    label: "Transparent",
    linkDisplayType: "default",
  },
  {
    id: 2,
    label: "Closed",
    linkDisplayType: "closed",
  },
];

export const PRICING_OFFERS = [
  {
    id: 1,
    title: "Free",
    price: process.env.REACT_APP_PRICE_PRO,
    desc: "For individual ready to drive more traffic to their personal Taprr page 100% free.",
    advantages: [],
    priceView: "$0",
    path: "/signup",
  },
  {
    id: 2,
    title: "Premium Plan",
    price: "",
    desc: "For companies with over 5+ employees ready to drive more traffic and leads with insights and more Data.",
    advantages: [
      "All Free +",
      "Free 1 Branded Digital Business Card",
      "CRM integration & Automation",
      "Manage many profiles from one account",
    ],
    priceView: "$6.00",
    path: "/signup",
  },
  {
    id: 3,
    title: "White Label",
    price: "",
    desc: "Contact Sales team for customised needs and requirements",
    path: "/contactus",
    advantages: ["Dashboard and Domain Branding", "All Features +"],
    priceView: "",
  },
];

export const testimonials = [
  {
    id: 1,
    image: maria,
  },
  {
    id: 1,
    image: samantha,
  },
];

export const TikTokIcon = ({ color = "#000000", width = "36px", height = "36px" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={width}
      height={height}
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};
