import { useState, useEffect, useCallback } from "react";

// react-router-dom components
import { Link, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKSpinner from "components/MKSpinner";

import FooterLogoTxt from "components/utils/FooterLogoTxt";

import BasicLayout from "pages/Authentication/components/BasicLayout";
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import bgImage from "assets/images/fanbies/signin.jpg";

// form validation with Formik
import { useFormik } from "formik";
import * as Yup from "yup";
import * as regex from "regex";

// api call
import { loginAuthv3 } from "api";

function SignIn() {
  const [error, setError] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors = {};
      if (!regex.email.test(values.email)) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Enter a valid Email"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const res = await loginAuthv3(values);
        if (!res.success) {
          setError(res.message);
          validation.resetForm();
          return;
        }
        setError("");
        navigate("/authcode", { replace: true, state: { paramEmail: values.email } });
      } catch (error) {
        setError("An error occurred. Please try again.");
        console.error("Login error:", error);
      } finally {
        setIsLoading(false);
      }
    },
    validateOnChange: true,
  });

  const handleEvent = (e) => {
    if (pathname === "/signin" && e.key === "Enter") {
      e.preventDefault();
      if (validation.isValid) {
        validation.submitForm();
      }
    }
  };

  const memoizedHandleEvent = useCallback(handleEvent, [pathname, validation.isValid]);

  useEffect(() => {
    document.addEventListener("keydown", memoizedHandleEvent);
    return () => document.removeEventListener("keydown", memoizedHandleEvent);
  }, [memoizedHandleEvent]);

  return (
    <>
      <DefaultNavbar routes={[]} sticky transparent light />
      <BasicLayout image={bgImage}>
        <MKTypography
          variant="h3"
          mt={20}
          fontWeight="bold"
          color="white"
          mb={3}
          textAlign="center"
        >
          Sign in to your Taprr
        </MKTypography>
        <Card>
          <MKBox pt={4} pb={3} px={3}>
            <MKBox component="form" role="form">
              <MKBox>
                <MKInput
                  name="email"
                  value={validation.values.email || ""}
                  onChange={validation.handleChange}
                  type="email"
                  placeholder="Email"
                  label="Your Taprr Email"
                  fullWidth
                  error={validation.touched.email && !!validation.errors.email}
                  helperText={validation.touched.email && validation.errors.email}
                />
                {validation.touched.email && validation.errors.email ? (
                  <MKTypography variant="caption" color="error">
                    {validation.errors.email}
                  </MKTypography>
                ) : null}
                <MKBox mt={2}>
                  <MKButton
                    variant="gradient"
                    onClick={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    color="primary"
                    fullWidth
                    disabled={!validation.values.email}
                  >
                    {isLoading ? <MKSpinner color="white" size={20} /> : "Sign in"}
                  </MKButton>
                </MKBox>
              </MKBox>
              {error ? (
                <MKBox textAlign="center">
                  <MKTypography variant="button" color="error">
                    {error}
                  </MKTypography>
                </MKBox>
              ) : null}
              <MKBox textAlign="center">
                <MKTypography variant="button">
                  By clicking on sign up, you agree to{" "}
                  <MKTypography
                    component={Link}
                    to="/terms"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    terms &amp; conditions
                  </MKTypography>
                </MKTypography>
              </MKBox>
              <MKBox textAlign="center">
                <MKTypography variant="caption">
                  Please check spam email for registration details from the Taprr Team
                </MKTypography>
              </MKBox>
              <MKBox mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Don&apos;t have an account?{" "}
                  <MKTypography
                    component={Link}
                    to="/signup"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign up
                  </MKTypography>
                </MKTypography>
              </MKBox>
              {/* <MKBox mb={1} textAlign="center">
                <MKTypography
                  component={Link}
                  to="/forgotten"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Forget Password ?
                </MKTypography>
              </MKBox> */}
            </MKBox>
          </MKBox>
        </Card>
        <MKBox mt={5}>
          <FooterLogoTxt dark />
        </MKBox>
      </BasicLayout>
    </>
  );
}

export default SignIn;
