import { useState, useContext } from "react";

// @mui material components
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { MobileStepper, Fade, AppBar, InputAdornment, TextField, Snackbar } from "@mui/material";
import { TikTokIcon } from "utils";

// import custom components
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import JoinIcon from "assets/images/taprr/join.svg";
import ProfileBio from "assets/images/taprr/data_profiles.svg";
import SocialProfile from "assets/images/taprr/contact_profile.svg";
// context
import AuthContext from "context/AuthContext";

// api
import { getCookie, updateUserProfileOnBoard } from "api";
const steps = ["Get Started", "Enter Short Bio and Contact Details", "Enter Social Media Handles"];

function OnboardNavigation() {
  const [responseMssg, setResponseMssg] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  const [activeStep, setActiveStep] = useState(0);
  const [userData, setUserData] = useState({
    name: "",
    socialMedia: {
      instagram: "",
      facebook: "",
      tiktok: "",
      twitter: "",
      whatsapp_id: "",
      linkedin: "",
    },
    bio: "",
    contactnumber: "",
  });
  const { dispatch, state } = useContext(AuthContext);
  const jtoken = getCookie("taprr-token"); //token

  // const reFreshIFrame = () => {
  //   const iframeEle = document.getElementById("profile-preview");
  //   iframeEle.contentWindow.location.reload();
  // };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // Call the API to save data
      saveFormData();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   saveFormData();
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      socialMedia: {
        ...userData.socialMedia,
        [name]: value,
      },
    });
  };

  const handleClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  const saveFormData = async () => {
    setSaveLoading(!saveLoading);
    const { name, socialMedia, bio, contactnumber } = userData;
    const res = await updateUserProfileOnBoard({
      //set onboard to 1 on the server
      name,
      bio,
      contactnumber,
      jtoken,
      fbid: socialMedia.facebook,
      twitterid: socialMedia.twitter,
      igid: socialMedia.instagram,
      tikid: socialMedia.tiktok,
      whatsappid: socialMedia.whatsapp_id,
      linkedinid: socialMedia.linkedin,
      username: state.userProfile.username,
      email: state.userProfile.email,
    });
    if (res.success) {
      setSaveLoading(false);
      dispatch.getDetails(res.response);
      // reFreshIFrame();
      setResponseMssg(res?.message);
      setOpenToolSnack({
        open: true,
        Fade,
      });
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <MKTypography variant="body2" sx={{ mb: 2 }}>
              Create your digital profile in 3 simple steps. First, let's start with your name.
            </MKTypography>
            <MKBox
              component="img"
              src={JoinIcon}
              sx={{ width: 300, height: 300 }}
              alt={`Onboarding to Taprr`}
              loading="lazy"
              ml={2}
            />
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              name="name"
              value={userData.name}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
          </>
        );
      case 1:
        return (
          <MKBox>
            <MKTypography variant="body2" sx={{ mb: 2 }}>
              Provide a short bio about yourself and a contact number. This information will be
              visible on your profile.
            </MKTypography>
            <MKBox
              component="img"
              src={ProfileBio}
              sx={{ width: 300, height: 300 }}
              alt={`Onboarding to Taprr`}
              loading="lazy"
              ml={2}
            />
            <TextField
              label="Biography about yourself"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              name="bio"
              value={userData.bio}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Contact Number"
              variant="outlined"
              type="number"
              fullWidth
              name="contactnumber"
              value={userData.contactnumber}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
          </MKBox>
        );
      case 2:
        return (
          <>
            <MKTypography variant="body2" sx={{ mb: 2 }}>
              Enter your social media handles to enhance the visibility of your online profile.
            </MKTypography>
            <MKBox
              component="img"
              src={SocialProfile}
              sx={{ width: 300, height: 300 }}
              alt={`Onboarding to Taprr`}
              loading="lazy"
              ml={2}
            />
            <MKBox>
              <MKInput
                name="facebook"
                value={userData.socialMedia.facebook}
                onChange={handleSocialMediaChange}
                type="text"
                placeholder="name"
                fullWidth
                sx={{ margin: "10px 5px", maxWidth: "430px", minWidth: "300px" }}
                InputProps={{
                  className: "fanbies_input_placeholder",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ padding: "0" }}>
                      <FacebookOutlinedIcon fontSize="medium" sx={{ color: "#000000" }} />{" "}
                      facebook.com/
                    </InputAdornment>
                  ),
                }}
              />
              <MKInput
                name="instagram"
                value={userData.socialMedia.instagram}
                onChange={handleSocialMediaChange}
                type="text"
                placeholder="name"
                fullWidth
                sx={{ margin: "10px 5px", maxWidth: "430px", minWidth: "300px" }}
                InputProps={{
                  className: "fanbies_input_placeholder",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ padding: "0" }}>
                      <InstagramIcon fontSize="medium" sx={{ color: "#000000" }} /> instagram.com/
                    </InputAdornment>
                  ),
                }}
              />
              <MKInput
                name="whatsapp_id"
                value={userData.socialMedia.whatsapp_id}
                onChange={handleSocialMediaChange}
                type="text"
                fullWidth
                placeholder="+1 xxx xxx xxx"
                sx={{ margin: "10px 5px", maxWidth: "430px", minWidth: "300px" }}
                InputProps={{
                  className: "fanbies_input_placeholder",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ padding: "0" }}>
                      <WhatsAppIcon fontSize="medium" sx={{ color: "#000000" }} /> whatsapp.com/+
                    </InputAdornment>
                  ),
                }}
              />
              <MKInput
                name="linkedin"
                value={userData.socialMedia.linkedin}
                onChange={handleSocialMediaChange}
                type="text"
                fullWidth
                placeholder="name"
                sx={{ margin: "10px 5px", maxWidth: "430px", minWidth: "300px" }}
                InputProps={{
                  className: "fanbies_input_placeholder",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ padding: "0" }}>
                      <LinkedInIcon fontSize="medium" sx={{ color: "#000000" }} />
                      linkedin.com/in/
                    </InputAdornment>
                  ),
                }}
              />
              <MKInput
                name="tiktok"
                value={userData.socialMedia.tiktok}
                onChange={handleSocialMediaChange}
                type="text"
                fullWidth
                placeholder="name"
                sx={{ margin: "10px 5px", maxWidth: "430px", minWidth: "300px" }}
                InputProps={{
                  className: "fanbies_input_placeholder",
                  startAdornment: (
                    <InputAdornment position="start" sx={{ padding: "0" }}>
                      <TikTokIcon width="20px" height="20px" /> tiktok.com/@
                    </InputAdornment>
                  ),
                }}
              />
              <MKInput
                name="twitter"
                value={userData.socialMedia.twitter}
                onChange={handleSocialMediaChange}
                type="text"
                fullWidth
                placeholder="name"
                sx={{ margin: "10px 5px", maxWidth: "430px", minWidth: "300px" }}
                InputProps={{
                  className: "fanbies_input_placeholder",
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon fontSize="medium" sx={{ color: "#000000" }} /> twitter.com/
                    </InputAdornment>
                  ),
                }}
              />
            </MKBox>
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <AppBar position="static" style={{ padding: 10 }}>
      <Snackbar
        open={openToolSnack.open}
        onClose={handleClose}
        TransitionComponent={openToolSnack.Transition}
        message={responseMssg}
        className="snackBar_container"
      />
      <Grid container spacing={0}>
        <Grid item md={6} lg={6} sm={6} xs={12}>
          <MKBox sx={{ width: "100%", mt: 3 }}>
            <MKTypography variant="h4">{steps[activeStep]}</MKTypography>
            <MKBox sx={{ mt: 2 }}>{getStepContent(activeStep)}</MKBox>
            <MobileStepper
              variant="text"
              steps={steps.length}
              position="top"
              activeStep={activeStep}
              sx={{ maxWidth: 800, position: "relative" }}
              nextButton={
                <MKButton size="small" onClick={handleNext} disabled={activeStep === steps.length}>
                  {activeStep === steps.length - 1 ? "Save" : "Next"}
                  <KeyboardArrowRight />
                </MKButton>
              }
              backButton={
                <MKButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft />
                  Back
                </MKButton>
              }
            />
            {/* {activeStep < steps.length && (
            <MKButton variant="text" color="secondary" onClick={handleSkip} sx={{ mt: 2 }}>
              {saveLoading ? <MKSpinner color="dark" size={20} /> : "Skip"}
            </MKButton>
          )} */}
          </MKBox>
        </Grid>
      </Grid>
    </AppBar>
  );
}

export default OnboardNavigation;
