import React, { useState, useRef, useContext, useEffect } from "react";

// @mui material components
import {
  TextField,
  Typography,
  Paper,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
// react-router-dom components
import { useNavigate } from "react-router-dom";

import qrcode from "qrcode-generator";
// Material Kit 2 PRO React components
import AuthContext from "context/AuthContext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import arrowRight from "assets/images/taprr/arrow_right.png";

import { getCookie } from "api";

const MIN_WIDTH = 1005;
const MIN_HEIGHT = 1047;

function FreeQRGenerator() {
  const [inputUrl, setInputUrl] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [logoDataUrl, setLogoDataUrl] = useState(null);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const qrRef = useRef(null);
  const fileInputRef = useRef(null);

  const { state } = useContext(AuthContext);
  const [logo, setLogo] = useState(null);

  const isLoggedIn = !state?.isSignout && getCookie("taprr-token");

  const [colors, setColors] = useState({
    dotsColor: "#000000",
    backgroundColor: "#ffffff",
    markerBorderColor: "#000000",
    markerCenterColor: "#000000",
  });

  const isValidUrl = (string) => {
    // This regex allows URLs with or without protocol, and with or without www
    const urlPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/\w \.-]*)*\/?$/;
    return urlPattern.test(string);
  };

  useEffect(() => {
    if (logo) {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        setLogoDataUrl(canvas.toDataURL("image/png"));
      };
      img.src = logo;
    }
  }, [logo]);

  const formatUrl = (url) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return "https://" + url;
    }
    return url;
  };

  const handleGenerate = () => {
    if (isValidUrl(inputUrl)) {
      setQrCodeUrl(formatUrl(inputUrl));
      setError(false);
    } else {
      setError(true);
      setQrCodeUrl("");
    }
  };

  const handleDownload = () => {
    if (qrRef.current) {
      const svgElement = qrRef.current.querySelector("svg");
      const svgData = new XMLSerializer().serializeToString(svgElement);

      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const scale = Math.max(MIN_WIDTH / img.width, MIN_HEIGHT / img.height);
        canvas.width = Math.max(MIN_WIDTH, img.width * scale);
        canvas.height = Math.max(MIN_HEIGHT, img.height * scale);
        const ctx = canvas.getContext("2d");

        // Fill the background
        ctx.fillStyle = colors.backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Draw the QR code centered
        const qrWidth = img.width * scale;
        const qrHeight = img.height * scale;
        const x = (canvas.width - qrWidth) / 2;
        const y = (canvas.height - qrHeight) / 2;
        ctx.drawImage(img, x, y, qrWidth, qrHeight);

        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = pngFile;
        downloadLink.click();
      };
      img.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
    }
  };

  const handleColorChange = (colorType) => (event) => {
    setColors((prevColors) => ({
      ...prevColors,
      [colorType]: event.target.value,
    }));
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.substr(0, 5) === "image") {
      setLogo(URL.createObjectURL(file));
    } else {
      setLogo(null);
      setLogoDataUrl(null);
    }
  };

  const renderQRCode = () => {
    if (!qrCodeUrl) return null;

    const qr = qrcode(0, "H");
    qr.addData(qrCodeUrl);
    qr.make();

    const cells = qr.getModuleCount();
    const cellSize = 4;
    const size = cells * cellSize;
    const qrSvg = [];

    // Background
    qrSvg.push(`<rect width="${size}" height="${size}" fill="${colors.backgroundColor}"/>`);

    // QR code dots
    for (let y = 0; y < cells; y++) {
      for (let x = 0; x < cells; x++) {
        if (qr.isDark(y, x)) {
          qrSvg.push(
            `<rect x="${x * cellSize}" y="${
              y * cellSize
            }" width="${cellSize}" height="${cellSize}" fill="${colors.dotsColor}"/>`
          );
        }
      }
    }

    // Position markers
    const markerPositions = [
      [0, 0],
      [0, cells - 7],
      [cells - 7, 0],
    ];
    markerPositions.forEach(([x, y]) => {
      // Outer square
      qrSvg.push(
        `<rect x="${x * cellSize}" y="${y * cellSize}" width="${7 * cellSize}" height="${
          7 * cellSize
        }" fill="${colors.markerBorderColor}"/>`
      );
      // Inner square
      qrSvg.push(
        `<rect x="${(x + 1) * cellSize}" y="${(y + 1) * cellSize}" width="${
          5 * cellSize
        }" height="${5 * cellSize}" fill="${colors.backgroundColor}"/>`
      );
      // Center square
      qrSvg.push(
        `<rect x="${(x + 2) * cellSize}" y="${(y + 2) * cellSize}" width="${
          3 * cellSize
        }" height="${3 * cellSize}" fill="${colors.markerCenterColor}"/>`
      );
    });

    // Logo
    if (logoDataUrl) {
      const logoSize = size * 0.2;
      const logoX = (size - logoSize) / 2;
      const logoY = (size - logoSize) / 2;
      qrSvg.push(
        `<image href="${logoDataUrl}" x="${logoX}" y="${logoY}" height="${logoSize}" width="${logoSize}"/>`
      );
    }

    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        dangerouslySetInnerHTML={{ __html: qrSvg.join("") }}
      />
    );
  };

  return (
    <>
      <MKBox pb={6} px={6}>
        <MKTypography variant="h6" mt={6} fontWeight="light" textAlign="center" mb={3}>
          Generate a unique qr code with the url (website) you want below.
        </MKTypography>
      </MKBox>
      <TextField
        fullWidth
        label="Enter URL"
        variant="outlined"
        value={inputUrl}
        onChange={(e) => setInputUrl(e.target.value)}
        error={error}
        helperText={error ? "Please enter a valid URL" : ""}
        sx={{ mb: 2 }}
        placeholder="www.google.com"
      />
      <MKButton variant="gradient" onClick={handleGenerate} color="primary" disabled={!inputUrl}>
        Generate QR Code
      </MKButton>
      {qrCodeUrl && (
        <Paper elevation={3} sx={{ p: 2, mt: 2 }} ref={qrRef}>
          <Typography variant="h6" gutterBottom>
            Generated QR Code:
          </Typography>
          <MKBox sx={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
            {renderQRCode()}
          </MKBox>
          {isLoggedIn && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Customize QR Code</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>Dots Color</Typography>
                    <input
                      type="color"
                      value={colors.dotsColor}
                      onChange={handleColorChange("dotsColor")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Background Color</Typography>
                    <input
                      type="color"
                      value={colors.backgroundColor}
                      onChange={handleColorChange("backgroundColor")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Marker Border Color</Typography>
                    <input
                      type="color"
                      value={colors.markerBorderColor}
                      onChange={handleColorChange("markerBorderColor")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Marker Center Color</Typography>
                    <input
                      type="color"
                      value={colors.markerCenterColor}
                      onChange={handleColorChange("markerCenterColor")}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography>Upload Logo</Typography>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleLogoUpload}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <MKButton
                      variant="gradient"
                      color="secondary"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Choose Logo
                    </MKButton>
                    {logo && <Typography variant="body2">Logo uploaded</Typography>}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
          <Stack spacing={2} justifyContent="space-between">
            <MKButton variant="gradient" onClick={handleDownload} color="primary" size="medium">
              Download QR Code
            </MKButton>
            {!isLoggedIn && (
              <MKButton
                circular
                sx={{
                  borderColor: "transparent",
                  backgroundColor: "#5FBC7F !important",
                  padding: 1.1,
                }}
                onClick={() => navigate(`/signup`, { replace: true })}
              >
                <MKTypography variant="button" fontWeight="bold">
                  Add More Customisation to Your QR Code?
                </MKTypography>
                <MKBox
                  component="img"
                  loading="lazy"
                  src={arrowRight}
                  alt="tappr card"
                  ml={1}
                  sx={{
                    width: 25,
                    height: 25,
                  }}
                />
              </MKButton>
            )}
          </Stack>
        </Paper>
      )}
    </>
  );
}

export default FreeQRGenerator;
