/* eslint-disable no-useless-escape */
/*
 * username validation
 * accepted: letters & numbers, without space
 */
export const username = /^([a-zA-Z0-9\-_])*[^\s]\1*$/;
/*
 * email validation
 */
export const email = /^[^\s@]+@[^\s@]+\.([^\s@]{2,})+$/;

export const number = /[0-9]|\./;

/*
 * password validation, should contain:
 * (?=.*\d): at least one digit
 * (?=.*[a-z]): at least one lower case
 * (?=.*[A-Z]): at least one uppercase case
 * [0-9a-zA-Z]{8,}: at least 8 from the mentioned characters
 * (?=.*[!@#$%^&*]): at least one special characters
 */
export const password = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const url =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/;

export const youtubeTester = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/;
export const spotifyTester =
  /(https?:\/\/open.spotify.com\/(track|user|artist|playlist|album)\/[a-zA-Z0-9]+(\/playlist\/[a-zA-Z0-9]+|)|spotify:(track|user|artist|album):[a-zA-Z0-9]+(:playlist:[a-zA-Z0-9]+|))/;
export const appleMusicTester = /^(?:(https?):\/\/)?(?:(?:www)\.)?(music.apple\.com)\/(.*)$/;

export const amountCheck = /^(?:[1-9]|\d{2,3}|[1-4]\d{3}|1000)$/;

export const phoneNumber = /^\+(?:[0-9] ?){6,14}[0-9]$/;
