import snowImage from "assets/images/fanbies/demo/snow.png";
import mineraleImage from "assets/images/fanbies/demo/mineral.png";
import natureImage from "assets/images/fanbies/demo/nature.png";
import sunsetImage from "assets/images/fanbies/demo/sunset.png";
import cheeseImage from "assets/images/fanbies/demo/cheese.png";
import skyImage from "assets/images/fanbies/demo/sky.png";
import darkImage from "assets/images/fanbies/demo/dark.png";
import lightImage from "assets/images/fanbies/demo/light.png";
import overlayImage from "assets/images/fanbies/demo/overlay.png";
import bluredImage from "assets/images/fanbies/demo/blured.png";
import flushImage from "assets/images/fanbies/demo/flush.png";

import { defaultProfilePic } from "utils";

const appearance = [
  {
    mode: "DEFAULT",
    backgroundColor: "#f0f2f5",
    btnBackground: "transparent",
    btnTextColor: "#000000",
    label: "Default",
    textColor: "#000000",
    textHovered: "#000000",
    borderColor: "#000000",
    btnHovered: "#f0f2f5",
    iconColor: "#000000",
    demo: lightImage,
  },
  {
    mode: "DARK",
    backgroundColor: "#000000",
    btnBackground: "#222222",
    btnTextColor: "#ffffff",
    label: "Dark",
    textColor: "#ffffff",
    textHovered: "#ffffff",
    borderColor: "#222222",
    btnHovered: "#222222",
    iconColor: "#ffffff",
    demo: darkImage,
  },
  {
    mode: "SKY",
    backgroundColor: "#74b9ff",
    btnBackground: "transparent",
    btnTextColor: "#ffffff",
    label: "Sky",
    textColor: "#ffffff",
    textHovered: "#74b9ff",
    borderColor: "#ffffff",
    btnHovered: "#ffffff",
    iconColor: "#ffffff",
    demo: skyImage,
  },
  {
    mode: "OVERLAY",
    label: "Overlay",
    btnBackground: "#000000",
    btnTextColor: "#ffffff",
    borderColor: "#000000",
    textColor: "#ffffff",
    backgroundColor: "#000000",
    textHovered: "#ffffff",
    btnHovered: "#000000",
    iconColor: "#ffffff",
    backgroundImage: defaultProfilePic,
    demo: overlayImage,
  },
  {
    mode: "SUNSET",
    backgroundColor: "#ffeee2",
    btnBackground: "transparent",
    btnTextColor: "#000000",
    label: "Sunset",
    textColor: "#000000",
    textHovered: "#000000",
    borderColor: "#ccbeb5",
    btnHovered: "#ffeee2",
    iconColor: "#000000",
    demo: sunsetImage,
  },
  {
    mode: "NATURE",
    backgroundColor: "#e0faee",
    btnBackground: "transparent",
    btnTextColor: "#000000",
    label: "Nature",
    textColor: "#000000",
    textHovered: "#000000",
    borderColor: "#b3c8be",
    btnHovered: "#e0faee",
    iconColor: "#000000",
    demo: natureImage,
  },
  {
    mode: "SNOW",
    backgroundColor: "#ffffff",
    btnBackground: "#000000",
    btnTextColor: "#ffffff",
    label: "Snow",
    textColor: "#000000",
    textHovered: "#ffffff",
    borderColor: "#000000",
    btnHovered: "#000000",
    iconColor: "#000000",
    demo: snowImage,
  },
  {
    mode: "CHEESE",
    backgroundColor: "#ebeef1",
    btnBackground: "#ffffff",
    btnTextColor: "#000000",
    label: "Cheese",
    textColor: "#000000",
    textHovered: "#000000",
    borderColor: "#ffffff",
    btnHovered: "#ffffff",
    iconColor: "#000000",
    demo: cheeseImage,
  },
  {
    mode: "MINERAL",
    backgroundColor: "#fff8e0",
    btnBackground: "transparent",
    btnTextColor: "#000000",
    label: "Mineral",
    textColor: "#000000",
    textHovered: "#000000",
    borderColor: "#ccc6b3",
    btnHovered: "transparent",
    iconColor: "#000000",
    demo: mineraleImage,
  },
  {
    mode: "BLURED",
    backgroundColor: "#e9eaeb",
    btnBackground: "#ffffff",
    btnTextColor: "#000000",
    label: "Blured",
    textColor: "#000000",
    textHovered: "#000000",
    borderColor: "#ffffff",
    btnHovered: "#ffffff",
    demo: bluredImage,
    iconColor: "#000000",
    isBlur: true,
  },
  {
    mode: "FLUSH",
    backgroundColor: "#f78fb3",
    btnBackground: "#f78fb3",
    btnTextColor: "#ffffff",
    label: "Flush",
    textColor: "#ffffff",
    textHovered: "#000000",
    borderColor: "#ffffff",
    btnHovered: "#ffffff",
    iconColor: "#ffffff",
    demo: flushImage,
  },
];
export default appearance;
