import { useState, useContext, useEffect } from "react";

// react-router-dom component
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKSpinner from "components/MKSpinner";
import FooterLogoTxt from "components/utils/FooterLogoTxt";
import BasicLayout from "pages/Authentication/components/BasicLayout";
import InputAdornment from "@mui/material/InputAdornment";

import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
// user context
import AuthContext from "context/AuthContext";
import { DEMO_ACCESS_CODE } from "utils";
// Images
import bgImage from "assets/images/vr-bg.jpg";

// Form validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Regex validation
import * as regex from "regex";

// api call
import { registerUser } from "api";

function SignUp() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: params?.username !== "" ? params?.username : "",
      password: DEMO_ACCESS_CODE,
      confirm_password: DEMO_ACCESS_CODE,
    },
    validate: (values) => {
      const errors = {};
      if (!regex.email.test(values.email)) {
        errors.email = "Invalid email address";
      }
      if (!regex.username.test(values.username)) {
        errors.username = "alphanumeric characters without space accepted";
      }
      return errors;
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Enter a valid Email"),
      username: Yup.string()
        .matches(
          /^[a-zA-Z0-9\-_]{3,30}$/,
          "Usernames cannot be longer than 30 characters and minimum 3 characters"
        )
        .required("Enter a username"),
    }),
    onSubmit: async (values) => {
      setIsLoading(!isLoading);
      const userObject = {
        username: values.username,
        useremail: values.email,
        uname: "",
        password: DEMO_ACCESS_CODE,
        phone: "",
      };
      const res = await registerUser(userObject);
      if (!res?.success) {
        setIsLoading(false);
        // Reset form after Signup failed
        validation.resetForm();
        setError(res?.message);
        return;
      }
      if (res.success) {
        setIsLoading(false);
        const userobj = res.response;
        // Reset form after successfully Signup
        validation.resetForm();
        // Dispatch Sign Up / Register
        dispatch.signUp(userobj);
        navigate("/admin", { replace: true });
      }
    },
    validateOnChange: true,
  });

  const handleEvent = (e) => {
    if (pathname === "/signup") {
      if (e.key === "Enter") {
        if (validation.isValid) {
          validation.submitForm();
          return false;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    document.addEventListener("keydown", (event) => handleEvent(event));
    return () => document.removeEventListener("keydown", (event) => handleEvent(event));
  }, []);

  return (
    <>
      <DefaultNavbar routes={[]} sticky light transparent />
      <BasicLayout image={bgImage}>
        <MKBox
          mt={15}
          sx={({ breakpoints }) => ({
            [breakpoints.down("md")]: {
              textAlign: "center",
              marginTop: 20,
            },
          })}
        >
          <MKTypography variant="h3" mt={10} fontWeight="bold" color="white">
            Create your Taprr account
          </MKTypography>
          <MKTypography variant="body2" color="white" mb={2}>
            Join the millions of business owners on Taprr today.
          </MKTypography>
        </MKBox>
        <Card mb={10}>
          <MKBox p={2}>
            <MKBox component="form" role="form" p={2}>
              <MKBox mb={2}>
                <MKInput
                  name="username"
                  placeholder="username"
                  value={validation.values.username || ""}
                  onChange={validation.handleChange}
                  type="text"
                  fullWidth
                  error={!!(validation.touched.username && validation.errors.username)}
                  InputProps={{
                    className: "fanbies_input_placeholder",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ padding: "0" }}>
                        Taprr.com/
                      </InputAdornment>
                    ),
                  }}
                />
                {validation.touched.username && validation.errors.username ? (
                  <MKTypography variant="button" color="error">
                    {validation.errors.username}
                  </MKTypography>
                ) : null}
              </MKBox>
              <MKBox mb={2}>
                <MKInput
                  name="email"
                  value={validation.values.email || ""}
                  onChange={validation.handleChange}
                  type="email"
                  label="Email"
                  fullWidth
                  error={!!(validation.touched.email && validation.errors.email)}
                />
                {validation.touched.email && validation.errors.email ? (
                  <MKTypography variant="button" color="error">
                    {validation.errors.email}
                  </MKTypography>
                ) : null}
              </MKBox>
              <MKBox textAlign="center">
                <MKTypography variant="button">
                  By clicking on sign up, you agree to{" "}
                  <MKTypography
                    component={Link}
                    to="/terms"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    terms &amp; conditions
                  </MKTypography>
                </MKTypography>
              </MKBox>

              <MKBox textAlign="center">
                <MKTypography variant="caption">
                  Please check spam email for registration details from the Taprr Team
                </MKTypography>
              </MKBox>

              <MKBox mt={3} mb={1}>
                <MKButton
                  variant="gradient"
                  color="primary"
                  fullWidth
                  onClick={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  disabled={
                    !validation.values.username ||
                    !validation.values.email ||
                    !validation.values.password
                  }
                >
                  {isLoading ? <MKSpinner color="white" size={20} /> : "Sign up"}
                </MKButton>
              </MKBox>
              {error ? (
                <MKBox mt={2} mb={1}>
                  <MKTypography variant="button" color="error">
                    {error}
                  </MKTypography>
                </MKBox>
              ) : null}
              <MKBox mt={3} mb={1} textAlign="center">
                <MKTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MKTypography
                    component={Link}
                    to="/signin"
                    variant="button"
                    color="primary"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MKTypography>
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>
        </Card>
        <MKBox mt={5}>
          <FooterLogoTxt dark />
        </MKBox>
      </BasicLayout>
    </>
  );
}

export default SignUp;
