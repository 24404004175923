import { useState, useContext } from "react";

// react-router-dom component
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { MobileStepper, Fade, InputAdornment, Snackbar } from "@mui/material";

// import custom components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKSpinner from "components/MKSpinner";
import JoinIcon from "assets/images/taprr/join.svg";
import printingCard from "assets/images/taprr/printing_card.png";
// context
import AuthContext from "context/AuthContext";

// api
import { activateHashAccount } from "api";
const steps = ["", ""];

// Form validation
import * as Yup from "yup";
import { useFormik } from "formik";
import * as regex from "regex";

function OnboardActivation() {
  const [responseMssg, setResponseMssg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const { dispatch } = useContext(AuthContext);
  const params = useParams();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: "",
      password: "",
      confirm_password: "",
    },
    validate: (values) => {
      const errors = {};
      if (!regex.email.test(values.email)) {
        errors.email = "Invalid email address";
      }
      if (!regex.username.test(values.username)) {
        errors.username = "alphanumeric characters without space accepted";
      }
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "Password doesn't match. Try again";
      }
      return errors;
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Enter a valid Email"),
      username: Yup.string()
        .matches(
          /^[a-zA-Z0-9\-_]{3,30}$/,
          "Usernames cannot be longer than 30 characters and minimum 3 characters"
        )
        .required("Enter a username"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Enter your password"),
    }),
    onSubmit: async (values) => {
      const hashvalue = params?.username;
      if (values.confirm_password === values.password || hashvalue !== "") {
        setIsLoading(!isLoading);
        const userObject = {
          username: values.username,
          useremail: values.email,
          password: values.password,
          hashvalue,
        };
        const res = await activateHashAccount(userObject); // different enpoint to normal register
        if (!res?.success) {
          setIsLoading(false);
          // Reset form after Signup failed
          validation.resetForm();
          setError(res?.message);
          return;
        }
        if (res.success) {
          setIsLoading(false);
          const userobj = res.response;
          // Reset form after successfully Signup
          validation.resetForm();
          // Dispatch Sign Up / Register
          dispatch.signUp(userobj);
          navigate("/admin", { replace: true });
        }
      }
    },
    validateOnChange: true,
  });

  const handleNext = (e) => {
    if (activeStep === steps.length - 1) {
      e.preventDefault();
      validation.handleSubmit();
      return false;
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  const GetStarted = () => (
    <Container>
      <Grid container>
        <Grid item xs={12} md={6}>
          <MKTypography
            variant="h3"
            sx={({ breakpoints }) => ({
              fontSize: 40,
              color: "#222832",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              [breakpoints.down("sm")]: {
                fontSize: 35,
              },
            })}
          >
            Ready to activate your Taprr account?
          </MKTypography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
        >
          <MKBox
            component="img"
            src={printingCard}
            alt="taprrCard"
            loading="lazy"
            sx={{
              width: "345px",
              height: "365px",
              marginTop: 3,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <GetStarted />;
      case 1:
        return (
          <>
            <MKBox
              p={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <MKTypography
                variant="h3"
                sx={({ breakpoints }) => ({
                  fontSize: 40,
                  color: "#222832",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                  [breakpoints.down("sm")]: {
                    fontSize: 35,
                  },
                })}
              >
                Activate Profile
              </MKTypography>
              <MKBox
                component="img"
                src={JoinIcon}
                sx={{ width: 150, height: 150 }}
                alt={`Onboarding to Taprr`}
                loading="lazy"
              />
              <MKBox component="form" role="form" p={2}>
                <MKBox mb={2}>
                  <MKInput
                    name="username"
                    placeholder="username"
                    value={validation.values.username || ""}
                    onChange={validation.handleChange}
                    type="text"
                    fullWidth
                    error={!!(validation.touched.username && validation.errors.username)}
                    InputProps={{
                      className: "fanbies_input_placeholder",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ padding: "0" }}>
                          Taprr.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validation.touched.username && validation.errors.username ? (
                    <MKTypography variant="button" color="error">
                      {validation.errors.username}
                    </MKTypography>
                  ) : null}
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    name="email"
                    value={validation.values.email || ""}
                    onChange={validation.handleChange}
                    type="email"
                    label="Email"
                    fullWidth
                    error={!!(validation.touched.email && validation.errors.email)}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <MKTypography variant="button" color="error">
                      {validation.errors.email}
                    </MKTypography>
                  ) : null}
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    name="password"
                    value={validation.values.password || ""}
                    onChange={validation.handleChange}
                    type="password"
                    label="Password"
                    fullWidth
                    error={!!(validation.touched.password && validation.errors.password)}
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <MKTypography variant="button" color="error">
                      {validation.errors.password}
                    </MKTypography>
                  ) : null}
                </MKBox>
                <MKBox mb={2}>
                  <MKInput
                    name="confirm_password"
                    value={validation.values.confirm_password || ""}
                    onChange={validation.handleChange}
                    type="password"
                    label="Confirm Password"
                    fullWidth
                    error={
                      !!(validation.touched.confirm_password && validation.errors.confirm_password)
                    }
                  />
                  {validation.touched.confirm_password && validation.errors.confirm_password ? (
                    <MKTypography variant="button" color="error">
                      {validation.errors.confirm_password}
                    </MKTypography>
                  ) : null}
                </MKBox>
                {error ? (
                  <MKBox mt={2} mb={1}>
                    <MKTypography variant="button" color="error">
                      {error}
                    </MKTypography>
                  </MKBox>
                ) : null}
              </MKBox>
            </MKBox>
          </>
        );
      default:
        return "Unknown step";
    }
  };

  const getNextContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <MKTypography variant="button" color="dark">
              Next
            </MKTypography>
            <KeyboardArrowRight />
          </>
        );
      case 1:
        return (
          <>
            <MKTypography variant="button" color="dark">
              Activate
            </MKTypography>
            <KeyboardArrowRight />
          </>
        );
      default:
        return <MKSpinner color="dark" size={20} />;
    }
  };

  return (
    <>
      <Snackbar
        open={openToolSnack.open}
        onClose={handleClose}
        TransitionComponent={openToolSnack.Transition}
        message={responseMssg}
        className="snackBar_container"
      />
      <MKBox
        sx={{ backgroundColor: "#fff" }}
        pt={4}
        width="100%"
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <MKTypography variant="h6">{steps[activeStep]}</MKTypography>
        <MKBox sx={{ mt: 2 }}>{getStepContent(activeStep)}</MKBox>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="top"
          activeStep={activeStep}
          sx={{ position: "relative" }}
          nextButton={
            <MKButton
              size="small"
              onClick={handleNext}
              disabled={
                (activeStep === steps.length - 1 && !validation.values.username) ||
                (activeStep === steps.length - 1 && !validation.values.email) ||
                (activeStep === steps.length - 1 && !validation.values.password)
              }
            >
              {isLoading ? <MKSpinner color="white" size={20} /> : getNextContent(activeStep)}
            </MKButton>
          }
          backButton={
            <MKButton size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
            </MKButton>
          }
        />
      </MKBox>
    </>
  );
}

export default OnboardActivation;
