import PropTypes from "prop-types";
// import material components
import Modal from "@mui/material/Modal";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Container from "@mui/material/Container";

// Material Kit 2 React Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKSpinner from "components/MKSpinner";

// form validation with Formik
import { useFormik } from "formik";

// Regex validation
import * as regex from "regex";

import check from "assets/images/check.png";
import cross from "assets/images/cross.png";

import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

// Material Kit 2 colors
import colors from "assets/theme/base/colors";
import { useState } from "react";

// API
import { saveConversion } from "api";

const { light } = colors;

function MKVcardModal({ isOpen, ownerid, username, picture, cancelAction }) {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      fullname: "",
      contactnumber: "",
      company: "",
      note: "",
    },
    validate: (values) => {
      const errors = {};
      if (!regex.email.test(values.email)) {
        errors.email = "Invalid email address";
      }
      if (!regex.number.test(values.contactnumber)) {
        errors.contactnumber = "Please input a valid phone number";
      }
      if (!values.fullname) {
        errors.fullname = "Please enter your full name(First name & Last name)";
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(!loading);
      const req = await saveConversion(ownerid, values);
      if (req.success) {
        setLoading(false);
        setMsg(req?.message);
        resetForm();
      } else {
        setLoading(false);
        setMsg("Something went wrong. Try again");
        resetForm();
      }
    },
  });

  return (
    <Container>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox
          sx={({ breakpoints }) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: light.main,
            borderRadius: 3,
            border: `1px solid ${light.main}`,
            boxShadow: 24,
            p: 2,
            width: "340px",
            [breakpoints.up("md")]: {
              minWidth: "480px",
              maxWidth: "480px",
            },
          })}
        >
          {msg.length ? (
            <Icon
              sx={{
                position: "absolute",
                right: 15,
                top: 35,
                cursor: "pointer",
              }}
              color="#000000"
              fontSize="medium"
              onClick={() => cancelAction(false)}
            >
              close
            </Icon>
          ) : (
            <MKBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <MKAvatar
                alt="user image"
                variant="circular"
                size="xl"
                src={picture}
                sx={{
                  border: "2px solid rgba(0, 0, 0, 0.05)",
                  cursor: "pointer",
                }}
              />
              <MKTypography
                id="modal-modal-title"
                variant="h5"
                fontWeight="bold"
                sx={{ textAlign: "center" }}
              >
                Share contact with {username ? username : "Taprr user"}
                <IconButton
                  sx={{
                    position: "absolute",
                    right: 15,
                    top: 25,
                    cursor: "pointer",
                  }}
                  onClick={() => cancelAction(false)}
                >
                  <CloseIcon color="#000000" fontSize="large" />
                </IconButton>
              </MKTypography>
            </MKBox>
          )}

          <Grid container mx="auto">
            <Grid item xs={12} md={12} lg={12} sm={12}>
              {!msg.length ? (
                <MKBox component="form" role="form">
                  <MKBox mt={2} mb={2}>
                    <MKInput
                      type="email"
                      variant="standard"
                      name="email"
                      label="Email"
                      value={validation.values.email}
                      onChange={validation.handleChange}
                      fullWidth
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.email}
                      </MKTypography>
                    ) : null}
                  </MKBox>
                  <MKBox mt={2} mb={2}>
                    <MKInput
                      type="text"
                      variant="standard"
                      name="fullname"
                      label="Full Name"
                      value={validation.values.fullname}
                      onChange={validation.handleChange}
                      fullWidth
                    />
                    {validation.touched.fullname && validation.errors.fullname ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.fullname}
                      </MKTypography>
                    ) : null}
                  </MKBox>
                  <MKBox mt={2} mb={2}>
                    <MKInput
                      type="tel"
                      variant="standard"
                      name="contactnumber"
                      label="Contact number"
                      placeholder="+1 xxx xxx xxx"
                      value={validation.values.contactnumber}
                      onChange={validation.handleChange}
                      fullWidth
                    />
                    {validation.touched.contactnumber && validation.errors.contactnumber ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.contactnumber}
                      </MKTypography>
                    ) : null}
                  </MKBox>
                  <MKBox mt={2} mb={2}>
                    <MKInput
                      type="text"
                      variant="standard"
                      name="company"
                      label="Company"
                      value={validation.values.company}
                      onChange={validation.handleChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mt={2} mb={2}>
                    <MKInput
                      type="text"
                      multiline
                      rows={2}
                      maxLength={10}
                      name="note"
                      label="Note (where you met / follow ups)"
                      value={validation.values.note}
                      onChange={validation.handleChange}
                      fullWidth
                    />
                  </MKBox>
                  <MKBox mt={3}>
                    <MKButton
                      variant="gradient"
                      fullWidth
                      onClick={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      color="primary"
                    >
                      {loading ? <MKSpinner color="white" size={20} /> : "Share"}
                    </MKButton>
                  </MKBox>
                  <MKBox display="flex" justifyContent="center" mt={2}>
                    <MKTypography fontWeight="bold" variant="button">
                      Taprr, does not sell or share your data.
                    </MKTypography>
                  </MKBox>
                </MKBox>
              ) : (
                <MKBox mt={3} mb={2}>
                  <MKBox display="flex" alignItems="center" flexDirection="column">
                    <img
                      src={msg === "Submitted." ? check : cross}
                      alt="icon"
                      width="55"
                      height="55"
                    />
                    <MKTypography variant="h4" fontWeight="bold" mt={2} mb={2}>
                      {msg}
                    </MKTypography>
                    <MKTypography variant="body2" textAlign="center" fontWeight="bold">
                      Create your Taprr profile to enjoy similar features, grow your network today.
                    </MKTypography>
                    <MKBox mt={2} display="flex" flexDirection="row" justifyContent="space-around">
                      <MKButton
                        sx={{ margin: 1 }}
                        onClick={(e) => {
                          e.preventDefault();
                          cancelAction(false);
                          setMsg("");
                          return false;
                        }}
                        variant="outlined"
                        color="error"
                      >
                        No
                      </MKButton>
                      <MKButton
                        sx={{ margin: 1 }}
                        component="a"
                        href={`${process.env.PUBLIC_URL}/signup`}
                        rel="noreferrer"
                        variant="gradient"
                        color="primary"
                      >
                        Yes
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </MKBox>
              )}
            </Grid>
          </Grid>
        </MKBox>
      </Modal>
    </Container>
  );
}

MKVcardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  ownerid: PropTypes.number.isRequired,
  picture: PropTypes.string.isRequired,
  cancelAction: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default MKVcardModal;
