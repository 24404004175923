import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Link from "@mui/material/Link";
import taprrNetwork from "assets/images/taprr/taprr_network.png";

const LandingNetwork = () => {
  return (
    <MKBox pb={8} sx={{ backgroundColor: "#013941" }}>
      <Container>
        <Grid container pt={5}>
          <Grid item xs={12} md={12}>
            <MKTypography
              variant="h3"
              fontWeight="bold"
              sx={({ breakpoints }) => ({
                fontSize: 40,
                color: "#FFFFFF",
                fontWeight: "bold",
                textAlign: "center",
                [breakpoints.down("sm")]: {
                  fontSize: 35,
                  textAlign: "left",
                },
              })}
            >
              Ready to join our network?
            </MKTypography>
            <MKBox textAlign="center">
              <MKBox
                component="img"
                src={taprrNetwork}
                alt="taprr network of users"
                loading="lazy"
                sx={{
                  width: "248px",
                  height: "50px",
                  marginTop: 3,
                }}
              />
            </MKBox>
            <MKTypography
              sx={({ breakpoints }) => ({
                color: "#FFFFFF",
                fontSize: "18px",
                marginTop: 3,
                textAlign: "center",
                [breakpoints.down("sm")]: {
                  textAlign: "left",
                },
              })}
            >
              Join Taprr, order your Vcard, and step into effortless contact management,
              customizable digital cards, and <br /> seamless networking. Enter a new era of
              connectivity today!
            </MKTypography>
            <MKBox textAlign="center">
              <MKButton
                sx={{
                  marginTop: 3,
                  borderRadius: 2,
                  backgroundColor: "#BEEACD !important",
                  padding: 1.1,
                }}
                component={Link}
                variant="outlined"
                href="/contactus"
                color="dark"
                size="medium"
              >
                Learn more about our mission
              </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default LandingNetwork;
