// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import taprrPhone from "assets/images/taprr/phone.png";
import taprrEnvelope from "assets/images/taprr/envelope.png";
import taprrImage from "assets/images/taprr/taprr_mini_logo.png";
import taprrImageDark from "assets/images/taprr/taprr_white.png";

function CenteredFooter({ company, dark }) {
  const { href } = company;

  const year = new Date().getFullYear();

  return (
    <MKBox component="footer" pt={5} pb={2} sx={{ backgroundColor: dark ? "#1D3E49" : "#FFFFFF" }}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={({ breakpoints }) => ({
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            [breakpoints.down("sm")]: {
              display: "flex",
              justifyContent: "space-evenly",
            },
          })}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            mb={5}
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            alignContent="center"
          >
            <MKBox
              component="img"
              src={dark ? taprrImageDark : taprrImage}
              alt="taprr logo footer"
              loading="lazy"
              sx={{
                height: 40,
              }}
            />
            <MKBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              mt={2}
              justifyContent="center"
            >
              <MKBox component="img" src={taprrEnvelope} alt="email" loading="lazy" />{" "}
              <MKTypography
                sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: "15px", marginLeft: 1 }}
              >
                {" "}
                support@taprr.com
              </MKTypography>
            </MKBox>
            <MKBox
              MKBox
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              justifyContent="center"
              mt={2}
            >
              <MKBox component="img" src={taprrPhone} alt="contact" loading="lazy" />
              <MKTypography
                sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: "15px", marginLeft: 1 }}
              >
                +234 81 081 20 599
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            alignContent="center"
            color={dark ? "#FFFFFF" : "#8289A0"}
          >
            <MKTypography
              sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: 20, fontWeight: "bold" }}
            >
              Support
            </MKTypography>
            <MKTypography
              component={Link}
              href="https://taprr-blog.medium.com/"
              sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: 15 }}
            >
              Blog
            </MKTypography>
            <MKTypography
              component={Link}
              href="/free-qr-code-generator"
              sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: 15 }}
            >
              QR Code Generator
            </MKTypography>
            <MKTypography
              component={Link}
              href="/pricing"
              sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: 15 }}
            >
              Pricing
            </MKTypography>
            <MKTypography
              sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: 15 }}
              component={Link}
              href="/terms"
            >
              Privacy & Terms of use
            </MKTypography>
            {/* <MKTypography
              sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: 15 }}
              component={Link}
              href="/cookie-policy"
            >
              Cookie policy
            </MKTypography> */}
          </Grid>
        </Grid>
        <MKBox
          sx={({ breakpoints }) => ({
            height: 1.2,
            backgroundColor: dark ? "#FFFFFF" : "#8289A0",
            width: "62%",
            [breakpoints.down("sm")]: {
              width: "100%",
            },
          })}
        />
      </Container>
      <MKTypography
        mt={5}
        sx={{ textAlign: "center", color: dark ? "#FFFFFF" : "#8289A0", fontSize: 15 }}
      >
        Copyright &copy; {year} made with{" "}
        <MKTypography
          component={Link}
          href={href}
          rel="noreferrer"
          sx={{ color: dark ? "#FFFFFF" : "#8289A0", fontSize: 15 }}
        >
          taprr.com
        </MKTypography>{" "}
        all rights reserved
      </MKTypography>
    </MKBox>
  );
}

// Setting default values for the props of CenteredFooter
CenteredFooter.defaultProps = {
  company: { href: "https://www.taprr.com/", name: "Taprr" },
  dark: true,
};

// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  dark: PropTypes.bool,
};

export default CenteredFooter;
