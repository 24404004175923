import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Link } from "react-router-dom";

// Images
import taprrImage from "assets/images/taprr/taprr_mini_logo.png";
// import taprrImageDark from "assets/images/taprr/taprr_white.jpg";
import taprrImageDark from "assets/images/taprr/taprr_white.png";

const FooterLogoTxt = ({ dark }) => (
  <MKTypography component={Link} to="/">
    <MKBox
      component="img"
      src={dark ? taprrImageDark : taprrImage}
      alt="taprr logo"
      // width="50%"
      position="relative"
      zIndex={1}
      display="flex"
      mx="auto"
    />
  </MKTypography>
);

FooterLogoTxt.defaultProps = {
  dark: true,
};

FooterLogoTxt.propTypes = {
  dark: PropTypes.bool,
};

export default FooterLogoTxt;
