import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function createData(name, premium, teams, whiteLabel) {
  return { name, premium, teams, whiteLabel };
}

const rows = [
  createData("Unlimited Links", "✔", "✔", "✔"),
  createData("Free Landing Page", "✔", "✔", "✔"),
  createData("Analytics Views", "✔", "✔", "✔"),
  createData("Choose from selectable themes", "✔", "✔", "✔"),
  createData("Share button with QR code + vCard", "✔", "✔", "✔"),
  createData("Share Custom links (Youtube, Spotify, and more ...)", "✔", "✔", "✔"),
  createData("Free 1 Branded Digital Business Card", "✘", "✔", "✔"),
  createData("Download CSV Of Lead Generation", "✘", "✔", "✔"),
  createData("CRM integration & Automation", "✘", "✔", "✔"),
  createData("Manage many profiles from one account", "✘", "✔", "✔"),
  createData("White label branded with company Logo and Domain", "✘", "✘", "✔"),
];

export default function MKTable() {
  return (
    <TableContainer sx={{ borderRadius: 0, boxShadow: "none" }}>
      <Table aria-label="simple table">
        <TableHead sx={{ display: "table-row-group" }}>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left">Freemium</TableCell>
            <TableCell align="center">Team</TableCell>
            <TableCell align="center">White Label</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.premium}</TableCell>
              <TableCell align="center">{row.teams}</TableCell>
              <TableCell align="center">{row.whiteLabel}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
