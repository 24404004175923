import React, { useState, useEffect } from "react";
import MKBox from "components/MKBox";
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import CenteredFooter from "molecules/Footers/CenteredFooter";
import Container from "@mui/material/Container";

// Routes
//import { HeaderRoutes } from "footer.routes";
import { useHeaderRoutes } from "hooks/useHeaderRoutes";
// Landing page components
import LandingHeader from "./LandingHeader";
import LandingPrinting from "./LandingPrinting";
import LandingTestimonial from "./LandingTestimonial";
import LandingNetwork from "./LandingNetwork";
import LandingAbout from "./LandingAbout";
import LandingFaq from "./LandingFaq";
import Sponsor from "./Sponsor";

function LandingPage() {
  const headerRoutes = useHeaderRoutes();
  const [, setBrowserWidth] = useState(0);
  const displayWindowSize = () => {
    const size = window.innerWidth;
    setBrowserWidth(size);
  };

  useEffect(() => {
    window.addEventListener("resize", displayWindowSize);
    return () => window.removeEventListener("resize", displayWindowSize);
  }, []);

  return (
    <MKBox>
      <DefaultNavbar routes={headerRoutes} transparent={true} light={true} />
      <LandingHeader />
      <Sponsor />
      <LandingAbout />
      <LandingPrinting />
      <LandingTestimonial />
      <LandingFaq />
      <LandingNetwork />
      <Container>
        <CenteredFooter dark={false} />
      </Container>
    </MKBox>
  );
}

export default LandingPage;
