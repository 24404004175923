import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Card,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

// context
import AuthContext from "context/AuthContext";

// Form validation
import * as Yup from "yup";
import { useFormik } from "formik";

// api call
import { getCookie, requestSubUserGroup, getSubGroup } from "api";

const AddSubGroupModal = ({ open, onClose, validation }) => (
  <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
    <MKBox p={3}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <MKTypography variant="h6">Add Sub-group User</MKTypography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </MKBox>

      <MKBox component="form" role="form" onSubmit={validation.handleSubmit}>
        <MKBox mb={2}>
          <MKInput
            name="email"
            label="Email"
            fullWidth
            value={validation.values.email}
            onChange={validation.handleChange}
            error={!!(validation.touched.email && validation.errors.email)}
          />
          {validation.touched.email && validation.errors.email && (
            <MKTypography variant="caption" color="error">
              {validation.errors.email}
            </MKTypography>
          )}
        </MKBox>
        <MKBox mt={3} mb={1}>
          <MKButton
            variant="gradient"
            color="primary"
            fullWidth
            type="submit"
            disabled={!validation.isValid}
          >
            Send Request
          </MKButton>
        </MKBox>
      </MKBox>
    </MKBox>
  </Dialog>
);

const SubGroupUser = () => {
  const { state } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [responseMssg, setResponseMssg] = useState("");
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  const [subGroups, setSubGroups] = useState({
    data: {
      total: 0,
      analytics: {
        totalPageViews: 0,
        totalVisits: 0,
        activeMembers: 0,
      },
      members: [],
    },
  });
  const token = getCookie("taprr-token");

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Enter a valid Email"),
    }),
    onSubmit: async (values) => {
      try {
        const obj = {
          adminname: state?.userProfile?.name,
          jtoken: token,
          email: values.email,
        };
        const res = await requestSubUserGroup(obj);
        // if (!res?.success) {
        //   setError(res?.message);
        //   return;
        // }
        setResponseMssg(res?.message);
        setOpenToolSnack({
          open: true,
          Transition: Fade,
        });
        validation.resetForm();
        setIsModalOpen(false);
        fetchSubGroups(); // Refresh the list
      } catch (err) {
        setError(err?.message);
      }
    },
  });

  const fetchSubGroups = async () => {
    try {
      const res = await getSubGroup({ jtoken: token });
      if (res?.success) {
        setSubGroups(res);
      } else {
        setError(res?.message || "Failed to fetch sub groups");
      }
    } catch (error) {
      console.error("Error fetching sub groups:", error);
      setError("Failed to fetch sub groups");
    }
  };

  useEffect(() => {
    fetchSubGroups();
  }, [token]); // Add token as dependency

  const handleClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  const renderAnalytics = () => (
    <Grid container spacing={2} mb={3}>
      <Grid item xs={12} md={4}>
        <Card>
          <MKBox p={2} textAlign="center">
            <MKTypography variant="h6" mb={1}>
              Total Page Views
            </MKTypography>
            <MKTypography variant="h4" color="primary">
              {subGroups.data.analytics?.totalPageViews?.toLocaleString() || 0}
            </MKTypography>
          </MKBox>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <MKBox p={2} textAlign="center">
            <MKTypography variant="h6" mb={1}>
              Total Visits
            </MKTypography>
            <MKTypography variant="h4" color="info">
              {subGroups.data.analytics?.totalVisits?.toLocaleString() || 0}
            </MKTypography>
          </MKBox>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <MKBox p={2} textAlign="center">
            <MKTypography variant="h6" mb={1}>
              Active Members
            </MKTypography>
            <MKTypography variant="h4" color="success">
              {subGroups.data.analytics?.activeMembers || 0}
            </MKTypography>
          </MKBox>
        </Card>
      </Grid>
    </Grid>
  );

  const renderMembersList = () => {
    const members = subGroups?.data?.members;
    if (!members) return [];

    return members.map((member) => ({
      ...member,
      statusType: member.status, // for maintaining compatibility with existing status styling
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Snackbar
          open={openToolSnack.open}
          onClose={handleClose}
          TransitionComponent={openToolSnack.Transition}
          message={responseMssg}
          className="snackBar_container"
        />
      </Grid>

      <Grid item xs={12}>
        <MKBox display="flex" alignItems="flex-end" justifyContent="flex-end" mb={3}>
          <MKButton
            variant="gradient"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setIsModalOpen(true)}
          >
            Add Sub-user
          </MKButton>
        </MKBox>

        {renderAnalytics()}

        <Grid item xs={12}>
          <Card>
            <MKBox p={3}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <MKBox>
                  <MKTypography variant="h4">Sub-Users Details</MKTypography>
                  <MKTypography variant="body2" color="secondary">
                    Total Members: {subGroups.data.total || 0}
                  </MKTypography>
                </MKBox>
              </MKBox>

              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650, tableLayout: "fixed" }}>
                  <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                      <TableCell sx={{ width: 80 }}>Avatar</TableCell>
                      <TableCell sx={{ width: 180 }}>Member</TableCell>
                      <TableCell sx={{ width: "auto" }}>Email</TableCell>
                      <TableCell sx={{ width: 120 }}>Status</TableCell>
                      <TableCell sx={{ width: 120 }}>Page Views</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderMembersList().map((member) => (
                      <TableRow key={member.id}>
                        <TableCell>
                          <MKBox
                            component="img"
                            src={member.user.picture}
                            alt={member.user.fullname}
                            width={40}
                            height={40}
                            borderRadius="50%"
                            sx={{
                              objectFit: "cover",
                              border: "1px solid #eee",
                            }}
                            onError={(e) => {
                              e.target.src = "https://via.placeholder.com/40";
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <MKTypography variant="body2" fontWeight="medium">
                            {member.user.fullname}
                          </MKTypography>
                          <MKTypography variant="caption" color="secondary">
                            {member.user.username}
                          </MKTypography>
                        </TableCell>
                        <TableCell>
                          <MKTypography variant="body2" sx={{ wordBreak: "break-word" }}>
                            {member.user.email}
                          </MKTypography>
                        </TableCell>
                        <TableCell>
                          <MKBox
                            px={1.5}
                            py={0.5}
                            display="inline-block"
                            borderRadius="borderRadius"
                            bgcolor={member.status === "active" ? "success.light" : "warning.light"}
                            color={member.status === "active" ? "success.dark" : "warning.dark"}
                          >
                            <MKTypography variant="caption" fontWeight="medium">
                              {member.status.charAt(0).toUpperCase() + member.status.slice(1)}
                            </MKTypography>
                          </MKBox>
                        </TableCell>
                        <TableCell>
                          <MKTypography variant="body2">
                            {member.user.pageViews?.toLocaleString() || 0}
                          </MKTypography>
                        </TableCell>
                      </TableRow>
                    ))}
                    {!subGroups?.data?.members?.length && (
                      <TableRow>
                        <TableCell colSpan={5} align="center" sx={{ py: 8 }}>
                          <MKTypography variant="body2" color="secondary">
                            {error || "No sub-group users found"}
                          </MKTypography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </MKBox>
          </Card>
        </Grid>

        <AddSubGroupModal
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setError("");
            validation.resetForm();
          }}
          validation={validation}
        />
      </Grid>
    </Grid>
  );
};

export default SubGroupUser;
