import React, { useState, useContext, useEffect } from "react";
import { Grid, IconButton, Select, MenuItem } from "@mui/material";
import AuthContext from "context/AuthContext";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSnackbar } from "context/SnackbarContext";
import SubscriptionModal from "components/SubscriptionModal";

import PropTypes from "prop-types";

const CURRENCIES = [
  { symbol: "$", code: "USD", name: "US Dollar" },
  { symbol: "€", code: "EUR", name: "Euro" },
  { symbol: "£", code: "GBP", name: "British Pound" },
  { symbol: "¥", code: "JPY", name: "Japanese Yen" },
  { name: "Naira", symbol: "N", code: "NGN" },
  { symbol: "₹", code: "INR", name: "Indian Rupee" },
  { symbol: "A$", code: "AUD", name: "Australian Dollar" },
  { symbol: "C$", code: "CAD", name: "Canadian Dollar" },
];

const DEFAULT_CURRENCY = CURRENCIES[0];

const MenuListingForm = ({ validation }) => {
  const { state } = useContext(AuthContext);
  const { showSnackbar } = useSnackbar();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [currentItem, setCurrentItem] = useState({
    name: "",
    price: "",
    description: "",
    currency: DEFAULT_CURRENCY,
    footnote: "",
  });
  const [itemErrors, setItemErrors] = useState({});

  // Safely get menuItems from validation
  const getMenuItems = () => {
    try {
      const items = validation?.values?.menuItems;
      if (!items) return [];
      if (Array.isArray(items)) return items;
      if (typeof items === "string") {
        const parsed = JSON.parse(items);
        return Array.isArray(parsed) ? parsed : [];
      }
      return [];
    } catch (error) {
      console.error("Error parsing menuItems:", error);
      return [];
    }
  };

  const menuItems = getMenuItems();
  const touchedMenuItems = validation?.touched?.menuItems;
  const menuItemsError = validation?.errors?.menuItems;

  const validateItem = (item) => {
    const errors = {};
    if (!item.name?.trim()) {
      errors.name = "Product name is required";
    }
    if (!item.price) {
      errors.price = "Price is required";
    } else if (isNaN(parseFloat(item.price)) || parseFloat(item.price) <= 0) {
      errors.price = "Please enter a valid price";
    }
    return errors;
  };

  // Check subscription status and menu item count
  const hasActiveSubscription =
    state.userProfile.subscription?.status === "active" ||
    state.userProfile.subscription?.status === "trialing";

  const handleAddItem = () => {
    const errors = validateItem(currentItem);
    setItemErrors(errors);

    if (!hasActiveSubscription && menuItems.length >= 5) {
      setShowSubscriptionModal(true);
      return;
    }

    if (Object.keys(errors).length === 0) {
      const newItem = {
        ...currentItem,
        price: parseFloat(currentItem.price).toFixed(2),
        name: currentItem.name.trim(),
        description: currentItem.description?.trim() || "",
        footnote: currentItem.footnote?.trim() || "",
      };

      validation?.setFieldValue("menuItems", [...menuItems, newItem]);

      // Reset form including footnote
      setCurrentItem({
        name: "",
        price: "",
        description: "",
        currency: DEFAULT_CURRENCY,
        footnote: "",
      });
      setItemErrors({});
      showSnackbar("Item added successfully!", "success");
    }
  };

  const handleRemoveItem = (index) => {
    if (!Array.isArray(menuItems)) return;
    const updatedItems = [...menuItems];
    updatedItems.splice(index, 1);
    validation?.setFieldValue("menuItems", updatedItems);
    showSnackbar("Item removed successfully!", "info");
  };

  const handleUpdateItem = (index) => {
    const updatedItems = [...menuItems];
    const item = updatedItems[index];

    setCurrentItem({
      name: item.name,
      price: item.price,
      description: item.description || "",
      currency: item.currency || DEFAULT_CURRENCY,
      footnote: item.footnote || "",
    });

    // Remove the item being edited
    handleRemoveItem(index);
  };

  return (
    <MKBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MKTypography variant="h6">Menu Items</MKTypography>
        </Grid>

        {/* Input Fields */}
        <Grid item xs={12} md={6}>
          <MKInput
            label="Item Name"
            value={currentItem.name}
            onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
            error={!!itemErrors.name}
            fullWidth
          />
          {itemErrors.name && (
            <MKTypography variant="caption" color="error">
              {itemErrors.name}
            </MKTypography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <MKBox display="flex" gap={1}>
            <Select
              value={currentItem.currency.code} // Use currency code as value
              onChange={(e) => {
                const selectedCurrency = CURRENCIES.find((c) => c.code === e.target.value);
                setCurrentItem({ ...currentItem, currency: selectedCurrency });
              }}
              fullWidth
              sx={{ maxWidth: "120px" }}
            >
              {CURRENCIES.map((currency) => (
                <MenuItem key={currency.code} value={currency.code}>
                  {" "}
                  {/* Use currency code as value */}
                  {currency.symbol} - {currency.code}
                </MenuItem>
              ))}
            </Select>
            <MKInput
              label="Price"
              type="number"
              value={currentItem.price}
              onChange={(e) => setCurrentItem({ ...currentItem, price: e.target.value })}
              error={!!itemErrors.price}
              fullWidth
            />
          </MKBox>
          {itemErrors.price && (
            <MKTypography variant="caption" color="error">
              {itemErrors.price}
            </MKTypography>
          )}
        </Grid>

        <Grid item xs={12}>
          <MKInput
            label="Description (optional)"
            value={currentItem.description}
            onChange={(e) => setCurrentItem({ ...currentItem, description: e.target.value })}
            multiline
            rows={2}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <MKInput
            label="Footnote Message (optional)"
            value={currentItem.footnote}
            onChange={(e) => setCurrentItem({ ...currentItem, footnote: e.target.value })}
            placeholder="E.g., 'Contains nuts', 'Spicy', 'Vegetarian'"
            helperText="Add a small note that will appear below this menu item"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <MKButton
            variant="contained"
            color="info"
            onClick={handleAddItem}
            startIcon={<AddIcon />}
            fullWidth
            disabled={!hasActiveSubscription && menuItems.length >= 5}
          >
            {!hasActiveSubscription && menuItems.length >= 5
              ? "Upgrade to Add More Items"
              : "Add Item"}
          </MKButton>
        </Grid>

        {/* Validation Error Message */}
        {touchedMenuItems && menuItemsError && (
          <Grid item xs={12}>
            <MKTypography variant="caption" color="error">
              {menuItemsError}
            </MKTypography>
          </Grid>
        )}
        {!hasActiveSubscription && menuItems.length >= 3 && (
          <Grid item xs={12}>
            <MKBox mt={2} mb={2}>
              <MKTypography variant="body2" color="warning">
                {menuItems.length === 5
                  ? "You've reached the maximum limit of 5 menu items. Upgrade to add more items."
                  : `You can add ${5 - menuItems.length} more items with the free plan.`}
              </MKTypography>
            </MKBox>
          </Grid>
        )}
        {/* Menu Items List with Edit Button */}
        {Array.isArray(menuItems) && menuItems.length > 0 && (
          <Grid item xs={12}>
            <MKBox mt={2}>
              {menuItems.map((item, index) => (
                <MKBox
                  key={index}
                  mb={2}
                  p={2}
                  border="1px solid"
                  borderColor="grey.300"
                  borderRadius="borderRadius"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <MKBox flex={1}>
                    <MKTypography variant="subtitle1">
                      {item.name} - {item.currency?.symbol || "$"}
                      {item.price}
                    </MKTypography>
                    {item.description && (
                      <MKTypography variant="body2" color="text">
                        {item.description}
                      </MKTypography>
                    )}
                    {item.footnote && (
                      <MKTypography
                        variant="caption"
                        color="secondary"
                        sx={{
                          display: "block",
                          fontStyle: "italic",
                          mt: 0.5,
                        }}
                      >
                        * {item.footnote}
                      </MKTypography>
                    )}
                  </MKBox>
                  <MKBox>
                    <IconButton
                      onClick={() => handleUpdateItem(index)}
                      color="info"
                      size="small"
                      sx={{ mr: 1 }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleRemoveItem(index)} color="error" size="small">
                      <DeleteIcon />
                    </IconButton>
                  </MKBox>
                </MKBox>
              ))}
            </MKBox>
          </Grid>
        )}
        <SubscriptionModal
          open={showSubscriptionModal}
          onClose={() => setShowSubscriptionModal(false)}
          featureName="Menu Items"
        />
      </Grid>
    </MKBox>
  );
};

MenuListingForm.propTypes = {
  validation: PropTypes.object.isRequired,
};

export default MenuListingForm;
