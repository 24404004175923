import React, { useState } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import faqImg from "assets/images/taprr/faq.png";

import FaqCollapse from "pages/Support/Faq/components/FaqCollapse";

const LandingFaq = () => {
  const [collapse, setCollapse] = useState(false);

  return (
    <Container>
      <Grid container pb={10}>
        <Grid item xs={12} md={6} sm={6} xl={6}>
          <MKTypography
            variant="h3"
            fontWeight="bold"
            sx={({ breakpoints }) => ({
              fontSize: 40,
              color: "#222832",
              [breakpoints.down("sm")]: {
                fontSize: 35,
                marginTop: 5,
              },
            })}
          >
            FAQs
          </MKTypography>
          <MKTypography sx={{ color: "#64748B", fontSize: "18px", marginTop: 3 }}>
            Taprr is a digital networking platform offering customizable <br /> digital business
            cards, contact management, <br /> and seamless networking solutions.
          </MKTypography>
          <MKBox
            sx={({ breakpoints }) => ({
              [breakpoints.down("sm")]: {
                textAlign: "center",
                marginBottom: 5,
              },
            })}
          >
            <MKBox component="img" src={faqImg} alt="faq" loading="lazy" mt={5} />
          </MKBox>
        </Grid>
        <Grid item xs={12} md={6} sm={6} xl={6}>
          <MKBox>
            <FaqCollapse
              title="How does Taprr work?"
              open={collapse === 1}
              onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
            >
              Taprr provides users with digital business cards that can be easily shared with others
              via a simple tap or QR code scan. These cards contain essential contact information
              and can be customized to reflect the user&#39;s unique style and preferences.
              Additionally, Taprr offers integrated contact management and CRM solutions to organize
              and manage connections effectively.
            </FaqCollapse>
            <FaqCollapse
              title="What makes Taprr different from traditional networking methods?"
              open={collapse === 2}
              onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
            >
              We’d love to chat! Feel free to contact us at contact@taprr.com, and one of our team
              members get back to you as soon as possible.
            </FaqCollapse>
            <FaqCollapse
              title="Is Taprr suitable for individuals and businesses?"
              open={collapse === 3}
              onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
            >
              A digital business card with NFC (Near Field Communication) technology can be suitable
              for both individuals and businesses, offering several advantages over traditional
              paper business cards. Here's an analysis of the suitability of digital business cards
              with NFC for individuals and businesses:
              <br /> For Individuals: <br />
              1. Convenience: Digital business cards eliminate the need to carry and exchange
              physical cards, making it more convenient for individuals to share their contact
              information. <br /> 2. Contactless Sharing: With NFC, individuals can simply tap their
              digital business card against another NFC-enabled device to share their contact
              details eliminating the need for manual input or scanning. <br />
              3. Eco-friendly: Digital business cards are environmentally friendly as they reduce
              the need for printing physical cards, contributing to sustainable practices. <br />
              4. Customization: Digital business cards can be easily customized with various
              designs, logos, and multimedia elements, allowing individuals to create a unique and
              professional representation of themselves.
              <br /> <br />
              For Businesses: <br />
              1. Brand Promotion: Digital business cards with NFC can be branded with the company's
              logo, color scheme, and other branding elements, enhancing brand recognition and
              consistency. <br /> 2. Lead Generation: By incorporating NFC technology, businesses
              can track and capture lead information more efficiently when at events or meetings.{" "}
              <br /> 3. Analytics and Tracking: Some digital business card platforms offer analytics
              and tracking capabilities, allowing businesses to monitor card views, shares, and
              engagement levels. <br />
              4. Cost-effective: In the long run, digital business cards can be more cost-effective
              than repeatedly printing physical cards, especially for businesses with frequent
              personnel or contact information changes <br />
              5. Integration: Digital business cards can be integrated with Customer Relationship
              Management (CRM) systems, making it easier to manage and update contact information
              across various platforms.
            </FaqCollapse>
            <FaqCollapse
              title="How secure is Taprr?"
              open={collapse === 4}
              onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
            >
              Taprr employs data encryption techniques to protect the information stored on the
              card.This ensures that even if the card is accessed by an unauthorized party,the data
              remains encrypted and unreadable. Taprr NFC digital business cards typically store
              only essential contact information, minimizing the risk of exposing sensitive or
              confidential data if the card is compromised.
            </FaqCollapse>
          </MKBox>
        </Grid>
        {/* <MKTypography
              variant="h3"
              sx={({ breakpoints }) => ({
                fontSize: 40,
                color: "#0F172A",
                marginTop: 10,
                fontWeight: "bold",
                [breakpoints.down("sm")]: {
                  fontSize: 35,
                },
              })}
            >
              Still have a question?
            </MKTypography>
            <MKTypography sx={{ color: "#64748B", fontSize: "18px", marginTop: 2 }}>
              Reach us now and have a quick discussion.
            </MKTypography>
            <MKButton
              sx={{ marginTop: 3, borderRadius: 2 }}
              component={Link}
              href="/contactus"
              variant="gradient"
              color="primary"
              size="medium"
            >
              Contact
            </MKButton> */}
      </Grid>
    </Container>
  );
};

export default LandingFaq;
