import { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKDeleteModal from "components/MKDeleteModal";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import Modal from "@mui/material/Modal";
// Material Kit 2 PRO React examples
import Table from "molecules/Tables/TableAccordion";

// import csv components
import { CSVLink } from "react-csv";
import { deleteContactById, getCookie } from "api";
import AuthContext from "context/AuthContext";

const headers = [
  { label: "Full Name", key: "fullname" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "contactnumber" },
  { label: "Company", key: "company" },
  { label: "Date Connected", key: "date" },
  { label: "Note", key: "note" },
];

const epochToNormalDate = (epoch) => {
  const normalDate = new Date(epoch * 1000); // Convert seconds to milliseconds
  return normalDate.toLocaleDateString(); // Format the date
};

// Components
function Author({ name }) {
  return (
    <MKBox display="flex" alignItems="center">
      <MKBox display="flex" flexDirection="column">
        <MKTypography variant="button" fontWeight="medium" color="secondary">
          {name}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the Author
Author.propTypes = {
  name: PropTypes.string.isRequired,
};

function TableOne() {
  const { state, dispatch } = useContext(AuthContext);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [rows, setRows] = useState(state?.userProfile?.contact_list || []);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [businessSrc, setBusinessSrc] = useState(null);

  useEffect(() => {
    setRows(state?.userProfile?.contact_list || []);
  }, [state.userProfile.contact_list, rows]);

  const deleteItemByID = async () => {
    const jtoken = getCookie("taprr-token");
    const res = await deleteContactById(jtoken, deleteItem);
    if (res.success) {
      dispatch.deleteContactById({ id: deleteItem });
      setRows((prevRows) => prevRows.filter((row) => row.id !== deleteItem));
      setOpenDeleteModal(false);
    }
  };

  const handleDelete = (id) => {
    setDeleteItem(id);
    setOpenDeleteModal(true);
  };

  const handelBusinessCardModel = (id) => {
    setBusinessSrc(id);
    setOpenResetModal(!openResetModal);
  };

  const { columns } = {
    columns: [
      { name: "", align: "left" },
      { name: "name", align: "left" },
      { name: "company", align: "left" },
      { name: "email", align: "center" },
      { name: "contact_number", align: "center" },
    ],
  };

  return (
    <MKBox>
      <MKDeleteModal
        title="Delete this contact?"
        message="This action is irreversible. Do you want to perform this action ?"
        isOpen={openDeleteModal}
        confirmDelete={deleteItemByID}
        cancelAction={() => {
          setOpenDeleteModal(false);
          setDeleteItem(null);
        }}
      />
      <Modal
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        open={openResetModal}
        onClose={(e, reason) => {
          if (reason && reason === "backdropClick" && "escapeKeyDown") {
            setOpenResetModal(false);
          }
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #fff",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <MKTypography variant="h6" color="secondary" fontWeight="medium">
            Scanned Business Card
          </MKTypography>
          <MKBox
            component="img"
            src={businessSrc}
            sx={{ width: 300, height: 300 }}
            alt={`business for collected`}
            loading="lazy"
          />
        </MKBox>
      </Modal>
      <MKBox component="section" className="taprrContactTable">
        {rows?.length > 0 ? (
          <MKBox>
            <Table
              columns={columns}
              rows={rows.map((item) => ({
                expander: "",
                name: <Author name={item.fullname} />,
                details: item.note,
                date: `${epochToNormalDate(item.date)}`,
                email: (
                  <MKTypography variant="button" color="secondary" fontWeight="medium">
                    {item.email}
                  </MKTypography>
                ),
                contact_number: (
                  <MKTypography variant="button" color="secondary" fontWeight="medium">
                    {item.contactnumber}
                  </MKTypography>
                ),
                company: (
                  <MKTypography variant="button" color="secondary" fontWeight="medium">
                    {item.company}
                  </MKTypography>
                ),
                photo: item.photo,
                ownername: item.fullname,
                owneremail: item.email,
                ownerphone: item.contactnumber,
                ownercompany: item.company,
                id: item.id,
              }))}
              handleDelete={handleDelete}
              handelBusinessCardModel={handelBusinessCardModel}
            />
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <CSVLink headers={headers} data={rows} filename="connected_contact.csv">
                <MKBox mt={1}>
                  <MKButton variant="outlined" color="primary">
                    EXPORT CONTACTS
                  </MKButton>
                </MKBox>
              </CSVLink>
            </MKBox>
          </MKBox>
        ) : (
          <MKTypography my={3} sx={{ textAlign: "center" }} variant="h6" fontWeight="bold">
            You have no contacts connected.
          </MKTypography>
        )}
      </MKBox>
    </MKBox>
  );
}

export default TableOne;
