/* eslint-disable no-underscore-dangle */
/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

// import material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// react-router components
import { Link, useNavigate } from "react-router-dom";

// Material Kit 2 React Components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKSpinner from "components/MKSpinner";

// App context
import AuthContext from "context/AuthContext";

// Api
import { getCookie, createCheckoutSession } from "api";

const MKPricing = ({ title, priceView, path, desc }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useContext(AuthContext);
  const navigate = useNavigate();
  const jtoken = getCookie("taprr-token");
  const stripeCheckout = async () => {
    try {
      if (state?.isSignout && !jtoken) {
        navigate("/signup", { replace: true });
      } else {
        setIsLoading(true);
        const session = await createCheckoutSession(jtoken);
        if (session) {
          setIsLoading(false);
          window.open(session.url, "_self");
        }
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
    <Card
      sx={{ marginBottom: 5, borderColor: "rgb(187, 199, 182)", borderWidth: 1 }}
      className="pricing-card"
    >
      <CardContent>
        <MKTypography variant="h4" fontWeight="bold">
          {title}
        </MKTypography>
        {priceView.length ? (
          <MKBox display="flex" flexDirection="row" alignItems="baseline" mb={2}>
            <MKTypography variant="h1" fontWeight="bold">
              {priceView}
            </MKTypography>
            <MKTypography variant="body1" color="text" fontWeight="regular">
              /month/seat
            </MKTypography>
          </MKBox>
        ) : null}
        <MKTypography variant="body2" fontWeight="regular" mb={3}>
          {desc}
        </MKTypography>
        {title === "Teams" || title === "Free" || title === "White Label" ? (
          <MKButton
            sx={{ marginTop: 3, borderRadius: 20, width: "100%" }}
            component={Link}
            to={path}
            variant="gradient"
            color="primary"
            size="medium"
          >
            {title === "Teams" || title === "White Label" ? "Contact Support" : "Sign Up"}
          </MKButton>
        ) : (
          <MKButton
            sx={{ marginTop: 3, borderRadius: 20, width: "100%" }}
            variant="gradient"
            color="primary"
            size="medium"
            onClick={stripeCheckout}
            disabled={isLoading}
          >
            {isLoading ? (
              <MKSpinner color="white" size={20} />
            ) : !state?.isSignout ? (
              "Become Pro"
            ) : (
              "Sign Up"
            )}
          </MKButton>
        )}
      </CardContent>
    </Card>
  );
};

MKPricing.propTypes = {
  title: PropTypes.string.isRequired,
  priceView: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  path: PropTypes.string,
};

export default MKPricing;
