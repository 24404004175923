import React, { useState, useContext, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// context
import AuthContext from "context/AuthContext";

// Stats page components
import DashboardLayout from "components/DashboardLayout";
import DashboardNavigation from "components/DashboardNavigation";
import OnboardNavigation from "components/OnboardNavigation";
import CollapsibleSideNav from "components/CollapsibleSideNav";
//import SubscriptionModal from "components/SubscriptionModal";

// import views
import Settings from "views/user-settings";
import Profile from "views/user-profile";
import ContactList from "views/user-contacts";
import UserLink from "views/user-link";
import CustomCard from "views/custom-card";
// import QRCodeCard from "views/qrgenerator/freeqrcodegen";
import PreviewProfile from "views/previewProfile";
import Analytics from "views/user-analytics";
import SubGroupUser from "views/subgroupusers";
import Billing from "views/billing";

import { getUserSubscription, getCookie } from "api";

function Admin() {
  const { state, dispatch } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const jtoken = getCookie("taprr-token");

  useEffect(() => {
    if (state.isSignout || state === null) {
      navigate("/", { replace: true });
    }
  }, [state, navigate]);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await getUserSubscription({ jtoken });
        if (response.success) {
          // console.log(response.subscription);
          dispatch.updateUserSubscription(response.subscription);
        }
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, [jtoken]);

  if (!state.userProfile.isonboard) {
    return <OnboardNavigation />;
  }

  const handleSectionChange = useCallback(
    (section) => {
      dispatch.updateCurrentSection(section);
    },
    [dispatch]
  );

  let content;
  switch (state.currentSection) {
    case "addlink":
      content = <UserLink />;
      break;
    case "profile":
      content = <Profile />;
      break;
    case "settings":
      content = <Settings />;
      break;
    case "analytics":
      content = <Analytics />;
      break;
    case "contacts":
      content = <ContactList />;
      break;
    case "customcard":
      content = <CustomCard />;
      break;
    case "previewpage":
      content = <PreviewProfile />;
      break;
    case "groupuser":
      // Check if user has an active subscription
      if (
        state.userProfile.subscription?.status === "active" ||
        state.userProfile.subscription?.status === "trialing"
      ) {
        content = <SubGroupUser />;
        break;
      }
      content = <Billing />;
      break;
    case "billing":
      content = <Billing />;
      break;
    default:
      content = <UserLink />;
  }

  const hidePreview =
    state.userProfile.isonboard &&
    state.currentSection !== "profile" &&
    state.currentSection !== "groupuser" &&
    state.currentSection !== "contacts" &&
    state.currentSection !== "customcard" &&
    state.currentSection !== "analytics" &&
    state.currentSection !== "billing";

  return (
    <DashboardLayout>
      <MKBox height="100vh">
        <Grid container spacing={0}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => setMobileOpen(!mobileOpen)}
              sx={{ mr: 2, position: "absolute", top: 10, left: 10, zIndex: 1100 }}
            >
              <MenuOpenIcon />
            </IconButton>
          )}
          <Grid item md={1} lg={1} sm={1}>
            <CollapsibleSideNav
              onSectionChange={handleSectionChange}
              mobileOpen={mobileOpen}
              setMobileOpen={setMobileOpen}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={!hidePreview ? 10 : 6}
            lg={!hidePreview ? 10 : 6}
            sm={!hidePreview ? 10 : 6}
            sx={({ breakpoints }) => ({
              padding: "0 10px",
              [breakpoints.down("sm")]: {
                marginLeft: 0,
                padding: "0 20px",
              },
              [breakpoints.up("sm")]: {
                marginLeft: "65px",
                padding: "0 10px 0 0",
              },
            })}
          >
            <DashboardNavigation />
            <MKBox minHeight="100vh">{content}</MKBox>
          </Grid>
          {hidePreview ? (
            <Grid
              item
              md={4}
              lg={4}
              sm={4}
              sx={{
                backgroundColor: "#fff",
                borderLeft: "1px solid #d7ddd1",
                flex: 1,
              }}
              className="dashboard_preview_panel"
            >
              <MKBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                textAlign="center"
                sx={{
                  height: "100vh",
                  position: "sticky",
                  top: 0,
                }}
              >
                <MKBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    height: "calc(100vh - 60px)", // Adjust this value as needed
                    overflow: "hidden",
                  }}
                  mt={3}
                  pt={3}
                >
                  <iframe
                    className="phone phone_translate phone__dashboard"
                    style={{
                      height: "100%",
                      width: "100%",
                      border: "none",
                      transform: "scale(0.9)", // Adjust scale as needed
                      transformOrigin: "top center",
                      border: "1px solid #d7ddd1",
                    }}
                    id="profile-preview"
                    width="100%"
                    loading="eager"
                    title={`${state.userProfile?.username}`}
                    src={`${process.env.PUBLIC_URL}/${state.userProfile?.username}`}
                  />
                </MKBox>
              </MKBox>
            </Grid>
          ) : (
            " "
          )}
        </Grid>
        {/* <SubscriptionModal
          open={showSubscriptionModal}
          onClose={() => setShowSubscriptionModal(false)}
        /> */}
      </MKBox>
    </DashboardLayout>
  );
}

export default Admin;
