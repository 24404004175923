import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const LandingAbout = () => {
  return (
    <MKBox sx={{ backgroundColor: "rgba(45, 167, 111, 0.1)" }} pb={10}>
      <MKBox>
        <Container>
          <Grid container>
            <Grid item md={12} xs={12}>
              <MKTypography
                variant="h3"
                sx={({ breakpoints }) => ({
                  marginTop: "80px",
                  fontSize: 40,
                  color: "#222832",
                  fontWeight: "bold",
                  textAlign: "center",
                  [breakpoints.down("sm")]: {
                    marginTop: 5,
                    fontSize: 35,
                    textAlign: "left",
                  },
                })}
              >
                What is Taprr?
              </MKTypography>
              <MKTypography
                sx={({ breakpoints }) => ({
                  textAlign: "center",
                  color: "#8289A0",
                  fontSize: "20px",
                  [breakpoints.down("sm")]: {
                    textAlign: "left",
                  },
                })}
                mt={3}
              >
                Taprr is transforming professional networking with its digital business card and qr
                code solution, <br />
                allowing easy sharing of contact details with a simple tap—ensuring you always make{" "}
                <br />
                lasting first impressions and never miss a lead.
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={12}>
              <MKBox
                mt={5}
                component="iframe"
                frameBorder="0"
                sx={({ breakpoints }) => ({
                  height: "400px",
                  width: "100%",
                  [breakpoints.down("sm")]: {
                    height: "200px",
                  },
                })}
                loading="lazy"
                title="What is Taprr?"
                src="https://player.vimeo.com/video/915611800?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
};

export default LandingAbout;
