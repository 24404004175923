import PropTypes from "prop-types";

// import material components
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

function ExtraLinkDialog({ isOpen, children, cancel }) {
  return (
    <>
      <Dialog fullWidth maxWidth="md" open={isOpen}>
        <DialogTitle sx={{ textAlign: "center" }}>
          Customised Link Options
          <IconButton
            onClick={() => cancel(false)}
            sx={{ position: "absolute", top: 13, right: 15 }}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
        </DialogTitle>
        <Divider variant="fullWidth" sx={{ margin: 0 }} />
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
}

ExtraLinkDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ExtraLinkDialog;
