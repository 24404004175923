import { memo } from "react";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import DraggableListItem from "components/Draggable/DraggableListItem";

const DraggableList = memo(
  ({ items, onDragEnd, inputLengthTitle, inputLengthURL, linkForm, setLinkForm }) => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem
                linkForm={linkForm}
                setLinkForm={setLinkForm}
                item={item}
                index={index}
                key={item?.id}
                inputLengthTitle={inputLengthTitle}
                inputLengthURL={inputLengthURL}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
);

export default DraggableList;
