/* eslint-disable camelcase */
const API = "https://api.taprr.com/okiki/api";
/*
Log In user
*/
export const loginUser = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/signin`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Register user
*/
export const registerUser = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/registeruser`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Get user profile
*/

export const getUserProfile = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/getuserprofile`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Forgotten password email
*/
export const forgottenPassword = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/forgottenpass`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Update user password 
*/
export const updatePassword = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/resetuserpass`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Send email of custom card print
*/
export const customCardPrint = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: data,
    };
    fetch(`${API}/v3/customcardemail`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
One time validate reset hash for password reset
*/
export const validateResetHash = (data, abortcontroller) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
      signal: abortcontroller.signal,
    };
    fetch(`${API}/validateresthash`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Remove user profile picture
*/
export const removeProfilePicture = (token) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken: token }),
    };

    fetch(`${API}/removeprofilepic`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Upload user profile picture
*/
export const uploadProfilePicture = (formData) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: formData,
      // Let the browser set the Content-Type with boundary
    };
    fetch(`${API}/profilepicupdateweb`, params)
      .then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.message || `HTTP error! status: ${res.status}`);
        }
        return data;
      })
      .then((data) => resolve(data))
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });

/*
Upload user profile cover picture
*/
export const uploadProfileCover = (formData) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: formData,
    };
    fetch(`${API}/profilecoverupdate`, params)
      .then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.message || `HTTP error! status: ${res.status}`);
        }
        return data;
      })
      .then((data) => resolve(data))
      .catch((err) => {
        console.error("API Error:", err);
        reject(err);
      });
  });

/*
Delete user account
*/
export const deleteAccount = (jwtoken, uid) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jwtoken, uid }),
    };
    fetch(`${API}/deleteuser`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Get Cookie by name
*/
export const getCookie = (cookieName) => {
  const cookieArr = document.cookie.split(";");
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split("=");
    if (cookieName === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

/*
Update user profile
*/
export const updateUserProfile = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/updatedetails`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Update user theme profile
*/
export const updateUserTheme = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v2/updatetheme`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Get App Config Video Message Rates
*/
export const getInAppConfig = (jtoken, name) =>
  new Promise((resolve, reject) => {
    const value = "value";
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, name, value }),
    };

    fetch(`${API}/getconfig`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Update Request Form 
*/
export const updateRequestForm = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/updaterequestform`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
 Ask to join Fanbies Request tool
*/
export const askVerifyRequestTool = (jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken }),
    };
    fetch(`${API}/verifyrequest`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * Update Password By User ID updateuserpasswordbyid
 */
export const updateUserPasswordById = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/updateuserpasswordbyid`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
 Create a custom link
*/
export const createCustomLink = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/createuserlink`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
 Get all custom links
*/
export const getCustomLinks = (username) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ username }),
    };
    fetch(`${API}/owneredlinks`, params)
      .then((res) => res.json())
      .then((e) => resolve(e?.response))
      .catch((err) => reject(err.message));
  });

/*
 Delete custom link
*/
export const deleteCustomLink = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/deletelink`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
 Update custom link
*/
export const updateCustomLinksItem = (jtoken, item) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, item: { ...item } }),
    };
    fetch(`${API}/updatelink`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
 Shoutout Booking
*/
export const bookingShoutoutRequest = (jtoken, item) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, ...item }),
    };
    fetch(`${API}/v2/paymentRegister`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
 Upload user profile video
*/
export const uploadProfileVideo = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: data,
    };
    fetch(`${API}/v2/uploadprofilevideo`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
 Delete user profile video
*/
export const deleteProfileVideo = (jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken }),
    };
    fetch(`${API}/v2/deleteprofilevideo`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Link click count increments
  */
export const linkClickIncrement = (linkid, username, jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ linkid, username, jtoken }),
    };
    fetch(`${API}/v2/linkclickbyid`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Increment unique page view
  */
export const pageViewIncrement = (jtoken, username, operatingSys, ipValue) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, username, operatingSys, ipValue }),
    };
    fetch(`${API}/v2/updatepageview`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Get Pending request list
  */
export const getMyPendingRequest = (jtoken, username) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, username }),
    };
    fetch(`${API}/getallpendings`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Get completed request list
  */
export const getMyCompletedRequest = (jtoken, username) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, username }),
    };
    fetch(`${API}/getallusercompletedrequest`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Get completed request list
  */
export const completeVideoRequestApi = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: data,
    };
    fetch(`${API}/v2/completevideorequest`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Reject request from another user
  */
export const rejectRequestShoutout = (jtoken, requestId, status, reason) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, requestId, status, reason }),
    };
    fetch(`${API}/updaterequeststatusbyid`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/** 
 Create a market place item 
 // createnewmarketplaceitem -- new
 // createmarketplaceitem -- old
 */
export const createMarketplaceitem = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: data,
    };
    fetch(`${API}/v3/createnewmarketplaceitem`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 Update a market place item 
 */
export const updateMarketplaceitem = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v2/updatemarketplaceitem`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 Update a market place item 
 */
export const deleteMarketplaceitem = (id, jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ id, jtoken }),
    };
    fetch(`${API}/v2/deletemarketplaceitem`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 Update a market place item 
 */
export const updateMarketplaceitemProfileIMG = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v2/updatemarketplaceimagebyid`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 Get all my earnings
 */
export const getMyEarnings = (jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken }),
    };
    fetch(`${API}/getmyearnings`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const getBankDetails = (uid) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ uid }),
    };
    fetch(`${API}/getbankdetails`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const requestEarning = (jtoken, username, useremail) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, username, useremail }),
    };
    fetch(`${API}/requestforearning`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const sendFeedback = (username, message, rating) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify(username, message, rating),
    };
    fetch(`${API}/v2/sendfeedback`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 Custom link animation
*/
export const updateLinkAnimation = ({ jtoken, linkAnimation, linkid }) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, linkAnimation, linkid }),
    };
    fetch(`${API}/v2/updatelinkanimation`, params)
      .then((res) => resolve(res))
      .catch((err) => reject(err.message));
  });

/**
 Update Link Display type
 */

export const updateLinkDisplayType = ({ jtoken, linkDisplayType, linkid }) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, linkDisplayType, linkid }),
    };
    fetch(`${API}/v2/updatelinkdisplaytype`, params)
      .then((res) => resolve(res))
      .catch((err) => reject(err.message));
  });

/**
 * Get landing page featured users
 */
export const getFeaturedUserProfile = () =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
    };
    fetch(`${API}/v2/getfeaturedusers`, params)
      .then((res) => resolve(res.json()))
      .catch((err) => reject(err.message));
  });

/*
  MarketPlace Link click count increments
  */
export const marketplaceClickIncrement = (itemid, username, jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ itemid, username, jtoken }),
    };
    fetch(`${API}/v2/marketplaceitemclicked`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * Conversion Rate and Form API
 */
export const saveConversion = (ownerid, data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ownerid, ...data }),
    };
    fetch(`${API}/v2/saveconversion`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * Get count conversion rate
 */
export const getCountConversion = (jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken }),
    };
    fetch(`${API}/v2/conversionrate`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * Get list of shared contact
 */
export const getContactList = (jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken }),
    };
    fetch(`${API}/v2/getusercontactlist`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * Delete contact by id
 */
export const deleteContactById = (jtoken, contactid) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, contactid }),
    };
    fetch(`${API}/v2/deletecontactbyid`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * Upload custom link picture
 */
export const uploadLinkPicture = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: data,
    };
    fetch(`${API}/addlinkicon`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * remove custom link picture
 */
export const removeLinkPicture = (linkid, id, linkurl) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ linkid, id, linkurl }),
    };
    fetch(`${API}/removelinkicon`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const createCheckoutSession = (jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken }),
    };
    fetch(`${API}/stripesubcheckoutsession`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const getUserSubscription = async (data) => {
  try {
    const response = await fetch(`${API}/v3/getusersubscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error in getUserSubscription:", error);
    throw error;
  }
};

export const goToBillingPortal = (jtoken, userid) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, userid }),
    };
    fetch(`${API}/stripeportalsession`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const savePayoutDetails = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/updateuserbankdetail`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const connectAccount = (jtoken) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken }),
    };
    fetch(`${API}/v3/connectaccountcreate`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const getAccountLink = (jtoken, accountid) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, accountid }),
    };
    fetch(`${API}/v3/getstripeaccountlink`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const getPublishKey = () =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify(),
    };
    fetch(`${API}/v3/stripepublishkey`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const getPaymentIntent = (username, amount, buyeremail, itemtitle) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ username, amount, buyeremail, itemtitle }),
    };
    fetch(`${API}/v3/getstripeintent`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const cancelPaymentIntent = (intent) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ intent }),
    };
    fetch(`${API}/v3/cancelpaymentintent`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Send email confirmation
*/
export const sendEmailConfirm = (jtoken, email, userid) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ jtoken, email, id: userid }),
    };
    fetch(`${API}/v3/requestmailconfirm`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/**
 * Email verification
 */
export const verifyEmail = (validateemail, token) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ validateemail, token }),
    };
    fetch(`${API}/v3/verifyemail`, params)
      .then(async (res) => {
        const data = await res.json();
        if (!res.ok) {
          throw new Error(data.message || `Verification failed with status: ${res.status}`);
        }
        return data;
      })
      .then((data) => resolve(data))
      .catch((error) => {
        console.error("Verification API error:", error);
        reject(error);
      });
  });

/**
 * Download asset by id
 */
export const downloadAssetByID = (itemid, buyeremail) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ itemid, buyeremail }),
    };
    fetch(`${API}/v3/downloadasset`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Update user profile during on boarding 
*/
export const updateUserProfileOnBoard = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v3/updateonboardingdetails`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Activate pre-hash profile
*/
export const activateHashAccount = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v3/claimhashaccount`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Get user analytics by id
  */
export const getUserAnalytics = (uid) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ uid }),
    };
    fetch(`${API}/v2/getanalytics`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Upload user profile picture
*/
export const uploadCustomLinkFile = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      body: data,
    };
    fetch(`${API}/v3/customlinkfileupload`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
  Login by email for passcode
*/
export const loginAuthv3 = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v3/authloginviaemail`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });
/*
  Login by email for passcode
*/
export const loginPassCodev3 = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v3/authloginbypasscode`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

export const generatePass = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v3/walletgenpass`, params)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to generate pass");
        }
        return res.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "taprr_pass.pkpass";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        resolve({ success: true, message: "Pass generated successfully" });
      })
      .catch((err) => reject({ success: false, message: err.message }));
  });

/*
Request Sub-User Group user
*/
export const requestSubUserGroup = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v4/requestsubgroupuser`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Request Sub-User Group user
*/
export const getSubGroup = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v4/getsubgroupadmin`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });

/*
Validate Sub-User Group user to active
*/
export const validateSubGroupUser = (data) =>
  new Promise((resolve, reject) => {
    const params = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
      body: JSON.stringify({ ...data }),
    };
    fetch(`${API}/v4/validatesubgroup`, params)
      .then((res) => res.json())
      .then((e) => resolve(e))
      .catch((err) => reject(err.message));
  });
