import { useState, useContext, useEffect } from "react";

// import material components
import Accordion from "@mui/material/Accordion";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import AccordionSummary from "@mui/material/AccordionSummary";
import MKSpinner from "components/MKSpinner";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";

// import custom components
import MKTypography from "components/MKTypography";
import MKPaymentRequestModal from "components/MKPaymentRequestModal";
import AuthContext from "context/AuthContext";

// API call getContactList
import { getCookie, getContactList } from "api";

import analytics from "assets/images/analytics-outline.gif";

const MKAnalyticsView = () => {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const token = getCookie("taprr-token");

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const list = await getContactList(token);
        if (list.success) {
          dispatch.setContactList(list.response);
        } else {
          dispatch.setContactList([]);
        }
      } catch (error) {
        console.error("Error fetching contact list:", error);
        dispatch.setContactList([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]); // Call useEffect only when the token changes

  return (
    <AppBar
      position="relative"
      sx={({ breakpoints }) => ({
        justifyContent: "center",
        width: "92%",
        [breakpoints.down("sm")]: {
          flexDirection: "row",
        },
      })}
    >
      <Accordion expanded={open} onChange={handleToggle} className="wallet_mk_accordion">
        <AccordionSummary aria-controls="panel1a-content" expandIcon={<ExpandMoreIcon />}>
          <MKTypography variant="caption" mx={2} fontWeight="bold">
            Page Analytics
          </MKTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container mb={1} justifyContent="space-between">
            <Grid item xs={12} md={3} lg={3} xl={3} textAlign="center" mb={2}>
              <MKTypography variant="button" fontWeight="bold">
                Page views <br />
              </MKTypography>
              <img src={analytics} alt="Page analytics icon" width="30" height="30" />
              <MKTypography variant="h4" fontWeight="bold">
                {state.userProfile?.pageview ? state.userProfile?.pageview : 0}
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4} textAlign="center" mb={2}>
              <MKTypography variant="button" fontWeight="bold">
                Shared contact <br />
              </MKTypography>
              <ContactsOutlinedIcon sx={{ width: "30px", height: "30px" }} />
              {isLoading ? (
                <MKSpinner color="dark" size={20} />
              ) : (
                <MKTypography variant="h4" fontWeight="bold">
                  {state?.userProfile?.contact_list.length}
                </MKTypography>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <MKPaymentRequestModal isOpen={isOpen} close={setIsOpen} />
    </AppBar>
  );
};

export default MKAnalyticsView;
