import React, { useState, useContext } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ListItemButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { menuItems } from "utils";

import TaprrLogo from "assets/images/taprr/taprr_mini_logo.png";
import TaprrIcon from "assets/images/taprr/taprr_icon.png";

// App context
import AuthContext from "context/AuthContext";

const drawerWidth = 170;
const closedDrawerWidth = 60;

const CollapsibleSideNav = ({ onSectionChange, mobileOpen, setMobileOpen }) => {
  const [open, setOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("profile");
  const { state } = useContext(AuthContext);
  const getUserType = state.userProfile.usertype;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMouseEnter = () => {
    if (!isMobile) setOpen(true);
  };

  const handleMouseLeave = () => {
    if (!isMobile) setOpen(false);
  };

  const handleSectionClick = (section) => {
    setSelectedSection(section);
    onSectionChange(section);
    if (isMobile) setMobileOpen(false);
  };

  const drawerContent = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: (theme) => theme.spacing(2),
          ...(theme) => theme.mixins.toolbar,
        }}
        className="admin__sidebar"
      >
        {open || isMobile ? (
          <Box mt={2} component="img" src={TaprrLogo} alt="Taprr Logo" className="side-bar__logo" />
        ) : (
          <Box
            mt={2}
            component="img"
            src={TaprrIcon}
            alt="Taprr Logo Icon"
            className="side-bar__logo"
          />
        )}
      </Box>
      <List>
        {menuItems.map((item) => (
          <ListItem
            key={item.text}
            style={{ display: item.type === "admin" && getUserType < 1 ? "none" : "block" }}
            className={`${item.section} menuitems`}
            sx={{ padding: "8px 0" }}
            disablePadding
          >
            <ListItemButton
              onClick={() => handleSectionClick(item.section)}
              selected={selectedSection === item.section}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "action.selected",
                },
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "25px", padding: "0 5px" }} className="primary_color">
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                className="side-bar__menu primary_color"
                sx={{ opacity: open || isMobile ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );

  return (
    <>
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawerContent}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          anchor="left"
          open={true}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          sx={{
            width: open ? drawerWidth : closedDrawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: open ? drawerWidth : closedDrawerWidth,
              overflowX: "hidden",
              transition: (theme) =>
                theme.transitions.create("width", {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.standard,
                }),
              whiteSpace: "nowrap",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </>
  );
};

export default CollapsibleSideNav;
