import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Carousel from "react-material-ui-carousel";
import maria from "assets/images/maria.png";
import samantha from "assets/images/samantha.png";
import bgImg from "assets/images/bg_img.png";

const LandingTestimonial = () => {
  return (
    <MKBox
      className="page_landing_bg"
      pb={10}
      sx={{
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <Container>
        <Grid container pt={10}>
          <Grid item xs={12} sm={5} md={5} xl={5}>
            <MKTypography
              variant="h3"
              fontWeight="bold"
              sx={({ breakpoints }) => ({
                fontSize: 40,
                color: "#222832",
                [breakpoints.down("sm")]: {
                  fontSize: 35,
                  padding: 0,
                },
              })}
            >
              Hear what our <br /> users have to say!
            </MKTypography>
            <MKTypography
              sx={({ breakpoints }) => ({
                color: "#808091",
                fontSize: "18px",
                marginTop: 3,
                [breakpoints.down("sm")]: {
                  marginBottom: 5,
                },
              })}
            >
              Discover Taprr&#39;s success stories: effortless connections, <br /> satisfied users,
              and what sets us apart in digital networking.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={2} md={2} xl={2} />
          <Grid item xs={12} sm={5} md={5} xl={5} textAlign="center">
            <MKBox
              component="iframe"
              frameBorder="0"
              sx={({ breakpoints }) => ({
                height: "250px",
                width: "100%",
                marginTop: "40px",
                [breakpoints.down("sm")]: {
                  margin: 0,
                },
              })}
              loading="lazy"
              title="What Taprr About?"
              src="https://share.synthesia.io/embeds/videos/549b20c5-4fc9-4c0a-a103-b1b2b4a70b2d"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            />
            {/* <Carousel fullHeightHover autoPlay>
              {Array.from([
                { id: 1, image: samantha },
                { id: 2, image: maria },
              ]).map((item) => (
                <MKBox
                  key={item?.id}
                  component="img"
                  sx={() => ({
                    width: 250,
                    height: 330,
                  })}
                  loading="lazy"
                  src={item?.image}
                  alt="testimonial"
                />
              ))}
            </Carousel> */}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default LandingTestimonial;
