import { useState } from "react";
import PropTypes from "prop-types";

// import material components
import MKButton from "components/MKButton";
import Snackbar from "@mui/material/Snackbar";
import MKSpinner from "components/MKSpinner";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Fade from "@mui/material/Fade";
import colors from "assets/theme/base/colors";
// api call
// import { getCookie } from "api";
const { light } = colors;
// App context
// import AuthContext from "context/AuthContext";

function MKPaymentRequestModal({ isOpen, close }) {
  const [loading, setLoading] = useState(false);
  const [responseMssg, setResponseMssg] = useState("");
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  // const jtoken = getCookie("taprr-token");
  // let paymentEmail = "";
  // if (state.userProfile?.payment_method !== undefined) {
  //   paymentEmail = state.userProfile?.payment_method[0]?.email;
  // }

  const handleSnackbarClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  const handlePaymentRequest = async () => {
    setLoading(!loading);
    // const { username, email: useremail } = state?.userProfile;
    // const req = await requestEarning(jtoken, username, useremail);
    // if (req?.success) {
    //   setLoading(false);
    //   setResponseMssg("Payment request sent to Admin");
    //   setOpenToolSnack({
    //     open: true,
    //     Fade,
    //   });
    //   close(false);
    // } else {
    //   setLoading(false);
    //   setResponseMssg("Payment request failed, please try again later");
    //   setOpenToolSnack({
    //     open: true,
    //     Fade,
    //   });
    // }
  };

  return (
    <div>
      <Snackbar
        open={openToolSnack.open}
        onClose={handleSnackbarClose}
        TransitionComponent={openToolSnack.Transition}
        message={responseMssg}
        className="snackBar_container"
      />
      <Modal
        open={isOpen}
        aria-labelledby="stripe-payment-request"
        aria-describedby="stripe payment request"
        onClose={(e, reason) => {
          if (reason && reason === "backdropClick" && "escapeKeyDown") {
            close(false);
          }
        }}
      >
        <MKBox
          sx={({ breakpoints }) => ({
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: light.main,
            borderRadius: 3,
            border: `1px solid ${light.main}`,
            boxShadow: 24,
            p: 2,
            width: "340px",
            [breakpoints.up("md")]: {
              minWidth: "480px",
              maxWidth: "480px",
            },
          })}
        >
          <Grid container mx="auto" mb={2}>
            <Grid item xs={12} md={12} lg={12} sm={12}>
              <MKTypography
                textAlign="center"
                id="modal-modal-title"
                variant="h5"
                fontWeight="bold"
                mb={3}
              >
                Account
              </MKTypography>
              <MKTypography variant="h6" fontWeight="light">
                Create a free stripe account to receive all payouts of your shop sales from Taprr.
              </MKTypography>
              <MKBox mt={3}>
                <MKButton variant="gradient" color="primary" onClick={handlePaymentRequest}>
                  {loading ? <MKSpinner color="light" size={20} /> : "Connect"}
                </MKButton>
              </MKBox>
            </Grid>
          </Grid>
        </MKBox>
      </Modal>
    </div>
  );
}

MKPaymentRequestModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default MKPaymentRequestModal;
