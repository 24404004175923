import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function MKModal({
  open = false,
  close = () => {},
  confirm = () => {},
  cancel = () => {},
  title = "",
  children = null,
  width = "auto",
  height = "auto",
  maxWidth = 400,
  maxHeight = "90vh",
  sx = {},
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : width,
    height: height,
    maxWidth: isMobile ? "95%" : maxWidth,
    maxHeight: isMobile ? "80vh" : maxHeight,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    ...sx,
  };

  const handleCancel = () => {
    cancel(false);
  };

  const handleConfirm = () => {
    confirm();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => cancel(false)}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          {/* Header */}
          {title && (
            <MKBox p={isMobile ? 2 : 3} borderBottom="1px solid" borderColor="grey.300">
              <MKTypography
                variant={isMobile ? "h6" : "h5"}
                sx={{
                  fontSize: isMobile ? "1.1rem" : "1.5rem",
                  lineHeight: 1.2,
                }}
              >
                {title}
              </MKTypography>
            </MKBox>
          )}

          {/* Content */}
          <MKBox
            sx={{
              flex: 1,
              overflowY: "auto",
              p: isMobile ? 2 : 3,
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.2)",
                borderRadius: "4px",
              },
            }}
          >
            {children}
          </MKBox>

          {/* Footer */}
          <MKBox
            p={isMobile ? 2 : 3}
            borderTop="1px solid"
            borderColor="grey.300"
            sx={{
              backgroundColor: "background.paper",
              position: "sticky",
              bottom: 0,
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              direction={isMobile ? "column" : "row"}
            >
              <Grid item xs={isMobile ? 12 : "auto"}>
                <MKButton
                  variant="outlined"
                  color="dark"
                  onClick={handleCancel}
                  fullWidth={isMobile}
                >
                  Cancel
                </MKButton>
              </Grid>
              <Grid item xs={isMobile ? 12 : "auto"}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={handleConfirm}
                  fullWidth={isMobile}
                >
                  Confirm
                </MKButton>
              </Grid>
            </Grid>
          </MKBox>
        </Box>
      </Fade>
    </Modal>
  );
}

MKModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  confirm: PropTypes.func,
  cancel: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sx: PropTypes.object,
};

export default MKModal;
