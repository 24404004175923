import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

function Table({ columns, rows, handleDelete, handelBusinessCardModel }) {
  const [openRows, setOpenRows] = useState({});

  const handleRowClick = (index) => {
    setOpenRows((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <MKBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        color="secondary"
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold }, borders: { borderWidth, borderColor } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          borderBottom: `${borderWidth[1]} solid ${borderColor}`,
        })}
      >
        {name.toUpperCase()}
      </MKBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;
    const isOpen = openRows[key] || false;

    const tableRow = columns.map(({ name, align }, columnIndex) => {
      let template;

      if (columnIndex === 0) {
        template = (
          <MKBox
            key={`${rowKey}-column-${columnIndex}`}
            component="td"
            p={1}
            sx={({ borders: { borderWidth, borderColor } }) => ({
              borderBottom: row.hasBorder ? `${borderWidth[1]} solid ${borderColor}` : 0,
            })}
          >
            <IconButton onClick={() => handleRowClick(key)}>
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </MKBox>
        );
      } else if (Array.isArray(row[name])) {
        template = (
          <MKBox
            key={`${rowKey}-column-${columnIndex}`}
            component="td"
            p={1}
            sx={({ borders: { borderWidth, borderColor } }) => ({
              borderBottom: row.hasBorder ? `${borderWidth[1]} solid ${borderColor}` : 0,
            })}
          >
            <MKBox display="flex" alignItems="center" py={0.5} px={1}>
              <MKBox mr={2}>
                <MKAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </MKBox>
              <MKTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][1]}
              </MKTypography>
            </MKBox>
          </MKBox>
        );
      } else {
        template = (
          <MKBox
            key={`${rowKey}-column-${columnIndex}`}
            component="td"
            p={1}
            textAlign={align}
            sx={({ borders: { borderWidth, borderColor } }) => ({
              borderBottom: row.hasBorder ? `${borderWidth[1]} solid ${borderColor}` : 0,
            })}
            onClick={() => handleRowClick(key)}
          >
            <MKTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={{ display: "inline-block", width: "max-content" }}
            >
              {row[name]}
            </MKTypography>
          </MKBox>
        );
      }

      return template;
    });

    const detailsRow = (
      <TableRow key={`${rowKey}-details`}>
        <MKBox component="td" colSpan={columns.length} p={1}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1} padding={1} bgcolor="grey.100">
              {row?.photo && (
                <>
                  <MKBox
                    component="img"
                    src={row?.photo}
                    sx={{
                      width: 100,
                      height: 100,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    alt={`business for collected for ${row?.ownername}`}
                    loading="lazy"
                    onClick={() => handelBusinessCardModel(row?.photo)}
                  />
                  <br />
                </>
              )}
              <MKTypography variant="caption" color="dark">
                Name: <b>{row.ownername}</b>
              </MKTypography>
              <br />
              <MKTypography variant="caption" color="dark">
                Email: <b>{row.owneremail}</b>
              </MKTypography>
              <br />
              <MKTypography variant="caption" color="dark">
                Contact No: <b>{row.ownerphone}</b>
              </MKTypography>
              <br />
              <MKTypography variant="caption" color="dark">
                Company: <b>{row.ownercompany}</b>
              </MKTypography>
              <br />
              {row?.details && (
                <>
                  <MKTypography variant="caption" color="dark">
                    Notes: <b>{row.details}</b>
                  </MKTypography>
                  <br />
                </>
              )}
              <MKTypography variant="caption" color="dark">
                Connected: <b>{row.date}</b>
              </MKTypography>
              <MKBox alignItems="left" textAlign="left">
                <IconButton
                  color="error"
                  size="small"
                  component="span"
                  onClick={() => handleDelete(row.id)}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </MKBox>
            </Box>
          </Collapse>
        </MKBox>
      </TableRow>
    );

    return (
      <React.Fragment key={rowKey}>
        <TableRow key={`${rowKey}-row`}>{tableRow}</TableRow>
        {detailsRow}
      </React.Fragment>
    );
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <MKBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </MKBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows, openRows]
  );
}

Table.defaultProps = {
  columns: [],
  rows: [{}],
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
