// Routes
import SignIn from "views/signin";
import SignUp from "views/signup";
import Admin from "views/admin";
import NoMatch from "views/404";
import FAQ from "views/faq";
import Terms from "views/terms";
import PublicProfile from "views/public-profile";
import ForgottenPassword from "views/forgotten";
import ResetPassword from "views/resetPasswordPage";
import TaprrCookies from "views/taprr-cookies";
import Pricing from "views/pricing";
import ContactForm from "views/contactForm";
import SuccessPayment from "views/success-payment";
import FailedPayment from "views/failed-payment";
import LandingPage from "views/landing-page";
import QRGENERATOR from "views/qrgenerator";
import AUTHCODE from "views/authcode";
import AuthRedirect from "components/AuthRedirect";
import EmailVerification from "views/email-verification";
import SubUserVerification from "views/subuser-verification";

// Protected Route
import ProtectedRoute from "pageRoutes/ProtectedRoute";

const indexRoutes = [
  { route: "/faq", name: "faq", component: <FAQ /> },
  { route: "/terms", name: "terms", component: <Terms /> },
  { route: "/pricing", name: "pricing", component: <Pricing /> },
  { route: "/contactus", name: "contactus", component: <ContactForm /> },
  {
    route: "/signup",
    name: "signup",
    component: (
      <AuthRedirect>
        <SignUp />
      </AuthRedirect>
    ),
  },
  { route: "/authcode", name: "authcode", component: <AUTHCODE /> },
  {
    route: "/signup/:username",
    name: "signup",
    component: (
      <AuthRedirect>
        <SignUp />
      </AuthRedirect>
    ),
  },
  {
    route: "/signin",
    name: "signin",
    component: (
      <AuthRedirect>
        <SignIn />
      </AuthRedirect>
    ),
  },
  {
    route: "/login",
    name: "login",
    component: (
      <AuthRedirect>
        <SignIn />
      </AuthRedirect>
    ),
  },
  {
    route: "/resetpass",
    name: "resetpass",
    component: (
      <AuthRedirect>
        <ResetPassword />
      </AuthRedirect>
    ),
  },
  {
    route: "/forgotten",
    name: "forgotten",
    component: (
      <AuthRedirect>
        <ForgottenPassword />
      </AuthRedirect>
    ),
  },
  {
    route: "/email-verification",
    name: "email-verification",
    component: <EmailVerification />,
  },
  {
    name: "Sub-user Verification",
    route: "/email-subuser",
    component: <SubUserVerification />,
  },
  { route: "/", name: "LandingPage", component: <LandingPage /> },
  { route: "/taprr-cookies", name: "taprr-cookies", component: <TaprrCookies /> },
  { route: "/free-qr-code-generator", name: "qr-code-generator", component: <QRGENERATOR /> },
  {
    route: "/success-payment",
    name: "success-payment",
    component: (
      <ProtectedRoute>
        <SuccessPayment />
      </ProtectedRoute>
    ),
  },
  {
    route: "/failed-payment",
    name: "failed-payment",
    component: (
      <ProtectedRoute>
        <FailedPayment />
      </ProtectedRoute>
    ),
  },
  {
    route: "/admin",
    name: "admin",
    component: (
      <ProtectedRoute>
        <Admin />
      </ProtectedRoute>
    ),
  },
  { route: "/user/:username", name: "username", component: <PublicProfile /> },
  { route: "/:username", name: "username", component: <PublicProfile /> },
  { route: "*", name: "nomatch", component: <NoMatch /> },
];

export default indexRoutes;
