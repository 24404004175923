import { useState, useContext, useEffect, useCallback } from "react";

// react-router-dom components
import { useLocation, useNavigate, Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import CircularProgress from "@mui/material/CircularProgress";
import MKSnackbar from "components/MKSnackbar";
import MKAlert from "components/MKAlert";

import FooterLogoTxt from "components/utils/FooterLogoTxt";

import BasicLayout from "pages/Authentication/components/BasicLayout";
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import bgImage from "assets/images/fanbies/signin.jpg";

// form validation with Formik
import { useFormik } from "formik";
import * as Yup from "yup";
import * as regex from "regex";

const RESEND_DELAY = 5 * 60 * 1000; // 5 minutes in milliseconds

// api call
import { loginPassCodev3, loginAuthv3 } from "api";

// context
import AuthContext from "context/AuthContext";

function AuthCode() {
  const { dispatch } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState(false);
  const [lastResendTime, setLastResendTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [msg, setMsg] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.paramEmail) {
      navigate("/signin");
    }
  }, [location.state, navigate]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .matches(regex.number, "Please enter number only")
        .min(6, "Code must be 6 digits")
        .max(6, "Code must be 6 digits")
        .required("Enter your code validation to continue.")
        .trim(),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setError("");
      try {
        const res = await loginPassCodev3(values);
        if (!res.success) {
          setError(res.message);
          return;
        }
        setMessage(res.message);
        dispatch.logIn(res.response);
        navigate("/admin", { replace: true });
      } catch (error) {
        setError("An error occurred. Please try again.");
        console.error("Login error:", error);
      } finally {
        setIsLoading(false);
        validation.resetForm();
      }
    },
    validateOnChange: true,
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const elapsed = Date.now() - lastResendTime;
      const remaining = Math.max(0, RESEND_DELAY - elapsed);
      setRemainingTime(remaining);
    }, 1000);

    return () => clearInterval(timer);
  }, [lastResendTime]);

  const canResend = remainingTime === 0;

  const resendCode = async () => {
    if (!canResend) return;

    setError("");
    setIsLoadingReset(true);
    try {
      const res = await loginAuthv3({ email: location.state.paramEmail });
      if (res.success) {
        setSuccess(true);
        setMsg(res?.message);
        setLastResendTime(Date.now());
      } else {
        setError(res.message || "Failed to resend code");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Resend code error:", error);
    } finally {
      setIsLoadingReset(false);
    }
  };

  const formatTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleEvent = (e) => {
    if (pathname === "/authcode" && e.key === "Enter") {
      e.preventDefault();
      if (validation.isValid) {
        validation.submitForm();
      }
    }
  };

  const memoizedHandleEvent = useCallback(handleEvent, [pathname, validation.isValid]);

  useEffect(() => {
    document.addEventListener("keydown", memoizedHandleEvent);
    return () => document.removeEventListener("keydown", memoizedHandleEvent);
  }, [memoizedHandleEvent]);

  const toggleSnackbar = () => setSuccess(false);

  return (
    <>
      <DefaultNavbar routes={[]} sticky transparent light />
      <BasicLayout image={bgImage}>
        <MKTypography
          variant="h3"
          mt={20}
          fontWeight="bold"
          color="white"
          mb={3}
          textAlign="center"
        >
          Please check email for OTP code
          <MKTypography variant="body2" color="white" mb={3} textAlign="center">
            ( Check junk / spam email depending on email provider )
          </MKTypography>
        </MKTypography>
        <Card>
          <MKBox pt={4} pb={3} px={3}>
            <MKTypography variant="body2" fontWeight="regular" mb={3} textAlign="center">
              We’re making sure your data is secure by asking you to verify this login attempt.
              We’ve sent a 6-digit verification code to {location.state?.paramEmail} <br /> This
              verification code expires in 2 minutes.
            </MKTypography>
            {message ? (
              <MKAlert color="info">
                <MKTypography variant="button" color="light">
                  {message}
                </MKTypography>
              </MKAlert>
            ) : null}
            {!message ? (
              <MKBox component="form" role="form">
                <MKBox mb={2}>
                  <MKInput
                    name="code"
                    type="text"
                    label="Code"
                    value={validation.values.code || ""}
                    onChange={validation.handleChange}
                    fullWidth
                  />
                  {validation.touched.code && validation.errors.code ? (
                    <MKTypography variant="button" color="error">
                      {validation.errors.code}
                    </MKTypography>
                  ) : null}
                </MKBox>
                <MKBox mt={4} mb={1}>
                  <MKButton
                    variant="gradient"
                    color="primary"
                    size="large"
                    disabled={!validation.values.code}
                    fullWidth
                    onClick={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress sx={{ color: "#000000" }} size={20} />
                    ) : (
                      "Authenticate"
                    )}
                  </MKButton>
                </MKBox>
                {error && (
                  <MKAlert color="error" dismissible>
                    <MKTypography variant="button" color="light">
                      {error}
                    </MKTypography>
                  </MKAlert>
                )}
                {success ? (
                  <MKSnackbar
                    color="primary"
                    title="Passcode"
                    content={msg}
                    dateTime="1 min ago"
                    autoHideDuration={13000}
                    onClose={toggleSnackbar}
                    open={success}
                    close={toggleSnackbar}
                  />
                ) : null}
                <MKBox mt={4} mb={1}>
                  <MKButton
                    variant="text"
                    color="primary"
                    size="small"
                    fullWidth
                    disabled={!canResend || isLoadingReset}
                    onClick={async (e) => {
                      e.preventDefault();
                      await resendCode();
                      return false;
                    }}
                  >
                    {isLoadingReset ? (
                      <CircularProgress sx={{ color: "#000000" }} size={20} />
                    ) : canResend ? (
                      " Still don't have the code? Resend Code"
                    ) : (
                      `Resend Code (${formatTime(remainingTime)})`
                    )}
                  </MKButton>
                </MKBox>
                <MKTypography
                  component={Link}
                  to="/signin"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                  textAlign="center"
                  sx={{ display: "block" }}
                >
                  Back To SignIn ?
                </MKTypography>
              </MKBox>
            ) : null}
          </MKBox>
        </Card>
        <MKBox mt={5}>
          <FooterLogoTxt dark />
        </MKBox>
      </BasicLayout>
    </>
  );
}

export default AuthCode;
