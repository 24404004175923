import React from "react";
import HubspotForm from "react-hubspot-form";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "molecules/Navbars/DefaultNavbar";
import CenteredFooter from "molecules/Footers/CenteredFooter";
import { useHeaderRoutes } from "hooks/useHeaderRoutes";

// Routes
//import { HeaderRoutes } from "footer.routes";

function ContactForm() {
  const headerRoutes = useHeaderRoutes();

  return (
    <>
      <DefaultNavbar routes={headerRoutes} sticky />
      <MKBox component="section" pt={20} pb={6}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card sx={{ borderWidth: 0 }}>
                <CardContent>
                  <MKBox
                    variant="gradient"
                    bgColor="primary"
                    borderRadius="lg"
                    coloredShadow="dark"
                    p={3}
                    mt={0}
                    mx={2}
                  >
                    <MKTypography variant="h3" color="white" textAlign="center">
                      Contact Us
                    </MKTypography>
                  </MKBox>
                  <MKBox pb={6} px={6} mt={5}>
                    <HubspotForm
                      portalId="139809818"
                      formId="8d4f90a3-0d00-45f4-a095-42188f3d426d"
                      loading={<div>Loading...</div>}
                    />
                  </MKBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox sx={{ backgroundColor: "#1D3E49" }}>
        <Container>
          <CenteredFooter />
        </Container>
      </MKBox>
    </>
  );
}

export default ContactForm;
