// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import { Link } from "react-router-dom";

// Material Kit 2 PRO React examples
import CenteredFooter from "molecules/Footers/CenteredFooter";

function FailedPayment() {
  return (
    <>
      <MKBox component="section" pt={20} pb={6}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="error"
                  borderRadius="lg"
                  coloredShadow="dark"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white" textAlign="center">
                    Payment Failed
                  </MKTypography>
                </MKBox>
                <MKBox
                  className="flip-card"
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-around"
                  alignItems="center"
                  p={3}
                >
                  <MKBox>
                    <MKTypography variant="h4" mt={6} fontWeight="light" textAlign="center" mb={3}>
                      We are not able to process your payment. Try again.
                    </MKTypography>
                    <MKButton
                      sx={{ marginTop: 3 }}
                      component={Link}
                      to="/admin"
                      variant="outlined"
                      color="error"
                      size="medium"
                    >
                      Back Home
                    </MKButton>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox sx={{ backgroundColor: "#1D3E49" }}>
        <Container>
          <CenteredFooter />
        </Container>
      </MKBox>
    </>
  );
}

export default FailedPayment;
