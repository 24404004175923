import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "api";
import { initialState } from "App";

const ProtectedRoute = ({ children }) => {
  const [state, setState] = useState(null);
  const { pathname } = useLocation();
  const token = getCookie("taprr-token");

  useEffect(() => {
    const local = JSON.parse(localStorage.getItem("TAPRR_STORE"));
    setState(local);
  }, []);

  if (!token && (pathname === "/admin" || "/success-payment" || "/failed-payment")) {
    localStorage.setItem("TAPRR_STORE", JSON.stringify(initialState));
    return <Navigate to="/" replace />;
  }
  return children;
};

export default ProtectedRoute;
