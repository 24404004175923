import React, { useState, useEffect, useContext } from "react";
import { Card, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

// App context
import AuthContext from "context/AuthContext";
import { getUserSubscription, getCookie, createCheckoutSession } from "api";

const DISPLAY_PRICES = {
  GBP: {
    symbol: "£",
    amount: "3.99",
  },
  USD: {
    symbol: "$",
    amount: "6",
  },
  NGN: {
    symbol: "₦",
    amount: "8,000.00",
  },
};

const features = [
  "Free 7 days Trial",
  "Advanced Analytics",
  "Add Sub-Accounts",
  "Unlimited Links",
  "More Menu Item Link Option",
  "Unlimited QR Code Download",
  "Download Large CSV Of Lead Generation",
  "Free 1 Branded Digital Business Card",
  "Email and Virtual Call Support",
];

const STRIPE_PRICE_ID = "price_1QK2LPFGWCjRyjPsdx4YgDx1"; // Single price ID for all currencies

const Billing = () => {
  const { state } = useContext(AuthContext);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState("GBP");
  const jtoken = getCookie("taprr-token");

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await getUserSubscription({ jtoken });
        if (response.success) {
          setCurrentSubscription(response.subscription);
          if (response.subscription?.currency) {
            setSelectedCurrency(response.subscription.currency.toUpperCase());
          }
        }
      } catch (error) {
        console.error("Error fetching subscription:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [jtoken]);

  const handleCurrencyChange = (event, newCurrency) => {
    if (newCurrency !== null) {
      setSelectedCurrency(newCurrency);
    }
  };

  const handleSubscribe = async () => {
    try {
      if (state?.isSignout && !jtoken) {
        navigate("/signup", { replace: true });
        return;
      }
      setLoading(true);

      const session = await createCheckoutSession(jtoken);
      if (session?.url) {
        window.open(session.url, "_self");
      }
    } catch (error) {
      console.error("Checkout error:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const getSubscriptionStatus = () => {
    if (!currentSubscription) return null;

    if (currentSubscription.status === "trialing") {
      return `Trial ends on ${formatDate(currentSubscription.trial_end)}`;
    }

    if (currentSubscription?.cancel_at_period_end) {
      return `Subscription will end on ${formatDate(currentSubscription.current_period_end)}`;
    }

    return `Current period ends on ${formatDate(currentSubscription?.current_period_end)}`;
  };

  const isCurrentPlan =
    currentSubscription?.status === "trialing" || currentSubscription?.status === "active";

  return (
    <MKBox component="section" py={12}>
      <MKBox textAlign="center" mb={4}>
        <MKTypography variant="h2" mb={1}>
          Pricing Plans
        </MKTypography>
        <MKTypography variant="body1" color="text">
          Advance to a higher plan to unlock more features
        </MKTypography>
      </MKBox>

      <MKBox textAlign="center" mb={4}>
        <ToggleButtonGroup
          value={selectedCurrency}
          exclusive
          onChange={handleCurrencyChange}
          aria-label="currency selection"
        >
          <ToggleButton value="GBP" aria-label="GBP">
            GBP (£)
          </ToggleButton>
          <ToggleButton value="NGN" aria-label="NGN">
            NGN (₦)
          </ToggleButton>
          <ToggleButton value="USD" aria-label="USD">
            USD ($)
          </ToggleButton>
        </ToggleButtonGroup>
      </MKBox>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={9}>
          <Card sx={{ p: 3, height: "100%" }}>
            <MKTypography variant="h3" mb={2}>
              Premium Plan (7 Days Trial)
            </MKTypography>
            <MKTypography variant="h2" mb={3}>
              {DISPLAY_PRICES[selectedCurrency].symbol}
              {DISPLAY_PRICES[selectedCurrency].amount}
              <span style={{ fontSize: "1rem", color: "secondary" }}>/mo</span>
            </MKTypography>

            <MKBox mb={3}>
              {features.map((feature) => (
                <MKBox display="flex" alignItems="center" mb={1} key={feature}>
                  <CheckIcon sx={{ color: "success.main", mr: 1 }} />
                  <MKTypography variant="body2">{feature}</MKTypography>
                </MKBox>
              ))}
            </MKBox>

            <MKButton
              variant="gradient"
              color="primary"
              fullWidth
              onClick={handleSubscribe}
              disabled={loading || isCurrentPlan}
            >
              {isCurrentPlan ? "Current Plan" : "Subscribe"}
            </MKButton>

            {currentSubscription && (
              <MKTypography variant="body2" color="text" mt={2} textAlign="center" display="block">
                {getSubscriptionStatus()}
              </MKTypography>
            )}
          </Card>
        </Grid>
      </Grid>
    </MKBox>
  );
};

export default Billing;
