import { useMemo } from "react";
import { useContext } from "react";
import AuthContext from "context/AuthContext";
import { getCookie } from "api";

export const useHeaderRoutes = () => {
  const { state } = useContext(AuthContext);
  const jtoken = getCookie("taprr-token");
  const isAuthenticated = !state?.isSignout && jtoken;

  const headerRoutes = useMemo(() => {
    const publicRoutes = [
      {
        name: "Pricing",
        route: "/pricing",
        type: "text",
      },
      {
        name: "Free QR Code Generator",
        route: "/free-qr-code-generator",
        type: "text",
      },
    ];

    const authRoutes = [
      {
        name: "Dashboard",
        route: "/admin",
        type: "text",
      },
      ...publicRoutes,
    ];

    const unauthRoutes = [
      {
        name: "Log in",
        route: "/signin",
        type: "text",
      },
      ...publicRoutes,
      {
        name: "Register for free",
        route: "/signup",
        type: "contained",
      },
    ];

    return isAuthenticated ? authRoutes : unauthRoutes;
  }, [isAuthenticated]);

  return headerRoutes;
};
