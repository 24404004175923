import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";
import taprr_black_card from "assets/images/taprr/taprr_black_card.png";
import bgImg from "assets/images/taprr/taprr_bg.png";
import arrowRight from "assets/images/taprr/arrow_right.png";

const LandingHeader = () => {
  const navigate = useNavigate();
  return (
    <MKBox
      sx={({ breakpoints }) => ({
        minHeight: "100vh",
        display: "flex",
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        [breakpoints.down("sm")]: {
          padding: 0,
        },
      })}
    >
      <Container>
        <Grid container pt={17} mb={5} mt={7}>
          <Grid item xs={12} sm={6} md={6} xl={6} lg={6}>
            <MKTypography
              variant="h1"
              mb={3}
              sx={({ breakpoints }) => ({
                fontSize: "70px",
                color: "#F6F7F7",
                [breakpoints.down("md")]: {
                  fontSize: "55px",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "35px",
                },
              })}
            >
              Create Free QR Codes and <br />
              <MKBox
                mt={3}
                sx={({ breakpoints }) => ({
                  fontSize: "40px",
                  color: "#C5E4D1",
                  fontWeight: "400",
                  [breakpoints.down("md")]: {
                    color: "#C5E4D1",
                    fontWeight: "400",
                    fontSize: "35px",
                  },
                })}
              >
                Digital Business Cards in Seconds!
              </MKBox>{" "}
            </MKTypography>
            <MKTypography
              variant="p"
              sx={{
                color: "#C5CBCA",
                fontSize: 15,
              }}
            >
              Perfect for Bars, Restaurants, and Professionals - Share information instantly, and
              streamline your customers' experience
            </MKTypography>
            <MKBox
              sx={({ breakpoints }) => ({
                marginTop: 5,
                [breakpoints.down("sm")]: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 3,
                  marginBottom: 7,
                },
              })}
            >
              <MKButton
                variant="outlined"
                circular
                textAlign="center"
                sx={{
                  backgroundColor: "#5FBC7F !important",
                  borderColor: "transparent",
                  padding: 1.1,
                }}
                onClick={() => navigate(`/signup`, { replace: true })}
              >
                <MKTypography variant="button" color="dark" fontWeight="bold">
                  Create My Free QR Code or Business Card!
                </MKTypography>
                <MKBox
                  component="img"
                  loading="lazy"
                  src={arrowRight}
                  alt="tappr card"
                  ml={1}
                  sx={{
                    width: 25,
                    height: 25,
                  }}
                />
              </MKButton>
            </MKBox>
          </Grid>
          <Grid item mb={2} xs={12} sm={6} md={6} xl={6} lg={6} textAlign="center">
            <MKBox
              component="img"
              loading="lazy"
              src={taprr_black_card}
              alt="tappr card"
              sx={({ breakpoints }) => ({
                width: 570,
                height: 420,
                [breakpoints.down("sm")]: {
                  width: 340,
                  height: 250,
                },
              })}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default LandingHeader;
