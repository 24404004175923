import PropTypes from "prop-types";

// import material components
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Close as CloseIcon } from "@mui/icons-material";
function MKModalThumbnail({ isOpen, children, cancel }) {
  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={isOpen}>
        <DialogTitle sx={{ textAlign: "center" }}>
          Add Thumbnail
          <IconButton
            sx={{
              position: "absolute",
              right: 15,
              top: 22,
              cursor: "pointer",
            }}
            onClick={() => cancel(false)}
          >
            <CloseIcon fontSize="small" color="dark" />
          </IconButton>
        </DialogTitle>
        <Divider variant="fullWidth" sx={{ margin: 0 }} />
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </>
  );
}

MKModalThumbnail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default MKModalThumbnail;
