import React, { useState, useContext, useEffect } from "react";

import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKSpinner from "components/MKSpinner";
import ExtraLinkDialog from "components/ExtraLinkDialog";
import MenuListingForm from "components/MenuListingForm";
import { useSnackbar } from "context/SnackbarContext";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MenuBookIcon from "@mui/icons-material/MenuBook";

// draggable components
import DraggableList from "components/Draggable/DraggableList";
import { reorder } from "components/Draggable/helpers";

// context
import { getUserProfile, getCookie, createCustomLink, uploadCustomLinkFile } from "api";
import AuthContext from "context/AuthContext";
import { EXTRALINKS, LINKTYPE, EXTERNALURL, LINK_CREATE } from "utils";

// Form validation
import { useFormik } from "formik";
// Regex validation
import * as regex from "regex";

// assets
import calendly from "assets/images/calendly.svg";
import paypal from "assets/images/paypal.svg";
import youtube from "assets/images/youtube.svg";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const UserLink = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);
  const [modaltype, setModaltype] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [linkForm, setLinkForm] = useState({
    title: "",
    link_ref: "",
    visible: true,
    mode: "create",
  });
  const [inputLengthTitle, setInputLengthTitle] = useState(0);
  const [inputLengthURL, setInputLengthURL] = useState(0);
  const { showSnackbar } = useSnackbar();

  const jtoken = getCookie("taprr-token");

  const reFreshIFrame = () => {
    const iframeEle = document.getElementById("profile-preview");
    iframeEle.contentWindow.location.reload();
  };

  const uploadFile = async (file, jtoken, linktitle, linktype, linkvisible) => {
    if (!file) {
      throw new Error("No file provided");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("jtoken", jtoken);
    formData.append("linktitle", linktitle);
    formData.append("linktype", linktype);
    formData.append("linkvisible", linkvisible);

    try {
      const res = await uploadCustomLinkFile(formData);
      return res;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      handle: "",
      amount: "",
      youtube_channel: "",
      calendlyLink: "",
      file: null,
      uploadtitle: "",
      menuItems: [],
    },
    validate: (values) => {
      const errors = {};
      switch (modaltype) {
        case LINKTYPE.PAYPAL:
          if (!regex.username.test(values.handle)) {
            errors.handle = "Please enter a valid username";
          }
          if (!regex.amountCheck.test(values.amount)) {
            errors.amount = "Please enter an amount between 1 to 1000";
          }
          return errors;
        case LINKTYPE.CALENDLY:
          if (!regex.url.test(values.calendlyLink)) {
            errors.calendlyLink = "Please enter a valid link";
          }
          return errors;
        case LINKTYPE.YOUTUBE_SUB:
          if (!regex.youtubeTester.test(values.youtube_channel)) {
            errors.youtube_channel = "Please enter a valid Youtube channel";
          }
          return errors;
        case LINKTYPE.MENU_LISTING:
          if (!values.menuItems || values.menuItems.length < 1) {
            errors.menuItems = "Please add at least one menu item";
          }
          return errors;
        case LINKTYPE.FILE_UPLOAD:
          if (!values.file) {
            errors.file = "A file is required";
          } else {
            const allowedTypes = [
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/gif",
              "application/pdf",
            ];
            if (!allowedTypes.includes(values.file.type)) {
              errors.file = "Unsupported file format. Only images and PDFs are allowed.";
            } else if (values.file.size > MAX_FILE_SIZE) {
              errors.file = "File is too large. Max size is 5MB.";
            }
          }
          if (!values.uploadtitle) {
            errors.uploadtitle = "A title for the file is required";
          }
          return errors;
        default:
          return errors;
      }
    },
    onSubmit: async (values) => {
      setLoading2(true);
      try {
        let newLink;
        switch (modaltype) {
          case LINKTYPE.FILE_UPLOAD:
            newLink = await uploadFile(
              values.file,
              jtoken,
              values.uploadtitle,
              LINKTYPE.FILE_UPLOAD,
              1
            );
            break;
          case LINKTYPE.PAYPAL:
            newLink = await createCustomLink({
              jtoken,
              linktitle: "Buy me a coffee with Paypal",
              linkref: `${EXTERNALURL.PAYPAL}/${values.handle}/${values.amount}`,
              linktype: LINKTYPE.PAYPAL,
              linkvisible: 1,
            });
            break;
          case LINKTYPE.CALENDLY:
            newLink = await createCustomLink({
              jtoken,
              linktitle: "Checkout my calendly slot",
              linkref: `${values.calendlyLink}`,
              linktype: LINKTYPE.CALENDLY,
              linkvisible: 1,
            });
            break;
          case LINKTYPE.YOUTUBE_SUB:
            newLink = await createCustomLink({
              jtoken,
              linktitle: "Subscribe to my Youtube Channel",
              linkref: `${values.youtube_channel}${EXTERNALURL.YOUTUBE_CHANNEL}`,
              linktype: LINKTYPE.YOUTUBE_SUB,
              linkvisible: 1,
            });
            break;
          case LINKTYPE.MENU_LISTING:
            const menuData = {
              jtoken,
              linktitle: "Menu",
              linkref: JSON.stringify(values.menuItems),
              linktype: LINKTYPE.MENU_LISTING,
              linkvisible: 1,
            };
            const response = await createCustomLink(menuData);
            if (response?.success) {
              dispatch.updateCustomLinks(response.response);
              setOpen(false);
              setActiveStep(0);
              setModaltype("");
              reFreshIFrame();
            }
            break;
          default:
            return null;
          // ... handle other link types similarly
        }
        if (newLink?.success) {
          dispatch.updateCustomLinks(newLink.response);
          reFreshIFrame();
          setOpen(false);
          setActiveStep(0);
          setModaltype("");
          showSnackbar("Menu saved successfully!", "success");
          // Reset form values
          validation.resetForm();
        }
      } catch (error) {
        console.error("Error creating link:", error);
        showSnackbar("Failed to save menu. Please try again.", "error");
        // Handle error (e.g., show error message to user)
      } finally {
        setLoading2(false);
      }
    },
    validateOnChange: true,
  });

  const handleNext = (linkType) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setModaltype(linkType);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    showSnackbar("Changes discarded", "info");
  };

  const getUserDetails = async () => {
    const res = await getUserProfile({ username: state?.userProfile?.username, jtoken });
    if (res.success) {
      const response = res.response[0];
      dispatch.getDetails(response);
    }
  };

  useEffect(() => {
    // Fetch User details onces
    getUserDetails();
    return () => null;
  }, []);

  const generateLink = () => {
    setLoading(!loading);
    setTimeout(() => {
      state.userProfile?.custom_links.unshift({
        id: String(Date.now()),
        type: LINKTYPE.NORMAL,
        ...linkForm,
      });
      setLoading(false);
      setInputLengthTitle(0);
      setInputLengthURL(0);
      setLinkForm({ title: "", link_ref: "", visible: true, mode: LINK_CREATE });
    }, 1000);
  };

  const generateExtraLink = (link) => {
    switch (link?.type) {
      case LINKTYPE.YOUTUBE:
        setLoading2(!loading2);
        setOpen(false);
        setTimeout(() => {
          state.userProfile?.custom_links.unshift({
            id: String(Date.now()),
            type: LINKTYPE.YOUTUBE,
            ...linkForm,
          });
          setLoading2(false);
          setInputLengthTitle(0);
          setInputLengthURL(0);
          setLinkForm({ title: "", link_ref: "", visible: true, mode: LINK_CREATE });
        }, 1000);
        break;
      case LINKTYPE.PAYPAL:
        handleNext(LINKTYPE.PAYPAL);
        break;
      case LINKTYPE.CALENDLY:
        handleNext(LINKTYPE.CALENDLY);
        break;
      case LINKTYPE.YOUTUBE_SUB:
        handleNext(LINKTYPE.YOUTUBE_SUB);
        break;
      case LINKTYPE.FILE_UPLOAD:
        handleNext(LINKTYPE.FILE_UPLOAD);
        break;
      case LINKTYPE.MENU_LISTING:
        handleNext(LINKTYPE.MENU_LISTING);
        break;
      default:
        break;
    }
  };

  // const handleMenuSubmit = async (menuItems) => {
  //   try {
  //     setLoading2(true);
  //     const menuData = {
  //       type: LINKTYPE.MENU_LISTING,
  //       title: "Menu",
  //       items: menuItems,
  //       visible: true,
  //     };

  //     const newLink = await createCustomLink({
  //       jtoken,
  //       linktitle: menuData.title,
  //       linkref: JSON.stringify(menuItems), // Store menu items as JSON string
  //       linktype: LINKTYPE.MENU_LISTING,
  //       linkvisible: 1,
  //     });

  //     if (newLink?.success) {
  //       dispatch.updateCustomLinks(newLink.response);
  //       setOpen(false);
  //       setActiveStep(0);
  //       setModaltype("");
  //       reFreshIFrame();
  //     }
  //   } catch (error) {
  //     console.error("Error creating menu:", error);
  //   } finally {
  //     setLoading2(false);
  //   }
  // };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(state.userProfile?.custom_links, source.index, destination.index);
    // dispatch here
    dispatch.updateCustomLinks(newItems);
    reFreshIFrame();
  };

  const onClose = () => {
    setOpen(false);
    setActiveStep(0);
    setModaltype("");
  };

  const CustomOptions = ({ type }) => {
    switch (type) {
      case LINKTYPE.YOUTUBE:
        return (
          <MKBox component="img" src={youtube} alt="youtube logo" width="34px" height="34px" />
        );
      case LINKTYPE.PAYPAL:
        return <MKBox component="img" src={paypal} alt="paypal logo" width="34px" height="34px" />;
      case LINKTYPE.CALENDLY:
        return (
          <MKBox component="img" src={calendly} alt="calendly logo" width="36px" height="36px" />
        );
      case LINKTYPE.FILE_UPLOAD:
        return <AttachFileIcon sx={{ height: "3rem", width: "3rem", color: "#000000" }} />;
      case LINKTYPE.YOUTUBE_SUB:
        return (
          <MKBox component="img" src={youtube} alt="youtube logo" width="34px" height="34px" />
        );
      case LINKTYPE.MENU_LISTING:
        return <MenuBookIcon sx={{ height: "3rem", width: "3rem", color: "#000000" }} />;
      default:
        return null;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <MKBox mt={5} mb={3}>
          <MKTypography variant="body2" fontWeight="bold" className="text-center">
            Drag and drop to rearrange box links
          </MKTypography>
        </MKBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <MKButton
              onClick={generateLink}
              variant="gradient"
              color="primary"
              sx={{ marginTop: 2 }}
              fullWidth
            >
              {loading ? <MKSpinner color="white" size={20} /> : "Add Your Custom Link"}
            </MKButton>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <MKButton
              variant="gradient"
              onClick={() => setOpen(!open)}
              color="primary"
              sx={{ marginTop: 2 }}
              fullWidth
            >
              {loading2 ? <MKSpinner color="white" size={20} /> : "More Link Option"}
            </MKButton>
          </Grid>
        </Grid>
        <MKBox mt={3}>
          {!state.userProfile?.custom_links?.length ? (
            <MKBox display="flex" alignItems="center" flexDirection="column">
              <InsertLinkOutlinedIcon sx={{ height: "3rem", width: "3rem", color: "#c9c2c5" }} />
              <MKTypography
                variant="h5"
                mb={2}
                fontWeight="bold"
                className="text__placeholder_color"
              >
                No Links.
              </MKTypography>
              <MKTypography
                variant="h6"
                fontWeight="bold"
                className="text__placeholder_color"
                mb={2}
              >
                Click "Add Custom Link" Button For More Profile Links.
              </MKTypography>
            </MKBox>
          ) : (
            <DraggableList
              items={state.userProfile?.custom_links}
              linkForm={linkForm}
              setLinkForm={setLinkForm}
              onDragEnd={onDragEnd}
              inputLengthTitle={inputLengthTitle}
              inputLengthURL={inputLengthURL}
            />
          )}
        </MKBox>
        <ExtraLinkDialog isOpen={open} cancel={onClose}>
          <MKBox mb={2}>
            <MKTypography variant="h5" fontWeight="bold" textAlign="start">
              Share your content
            </MKTypography>
            <MKTypography variant="button" textAlign="start" mb={2}>
              We have a wide range of link types to select from below (e.g documents, menus, youtube
              and more).
            </MKTypography>
          </MKBox>
          <Grid container spacing={4}>
            {activeStep === 0 &&
              EXTRALINKS.map((link) => (
                <Grid item key={link.id} xs={12} md={6} lg={6} xl={6}>
                  <Stack direction="row" alignItems="center" mt={1} justifyContent="space-between">
                    <MKBox display="flex" alignItems="center" mt={1} px={3}>
                      {CustomOptions(link)}
                      <MKBox display="flex" flexDirection="column" ml={2}>
                        <MKTypography variant="button" fontWeight="bold">
                          {link.name}
                        </MKTypography>
                        <MKTypography variant="caption">{link.desc}</MKTypography>
                      </MKBox>
                    </MKBox>
                    <MKButton
                      onClick={() => generateExtraLink(link)}
                      variant="outlined"
                      size="small"
                      color="primary"
                    >
                      Add
                    </MKButton>
                  </Stack>
                </Grid>
              ))}

            {activeStep === 1 && modaltype === LINKTYPE.PAYPAL ? (
              <MKBox component="form" role="form" mt={5} ml={4} width="100%">
                <Grid container spacing={3} sx={{ marginBottom: 5 }}>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <MKInput
                      name="handle"
                      value={validation.values.handle}
                      onChange={validation.handleChange}
                      error={!!(validation.touched.handle && validation.errors.handle)}
                      type="text"
                      label="Enter Your Paypal Username"
                      fullWidth
                    />
                    {validation.touched.handle && validation.errors.handle ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.handle}
                      </MKTypography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    <MKInput
                      name="amount"
                      value={validation.values.amount}
                      error={!!(validation.touched.amount && validation.errors.amount)}
                      onChange={validation.handleChange}
                      type="number"
                      label="How much do you want to receive ?"
                      fullWidth
                    />
                    {validation.touched.amount && validation.errors.amount ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.amount}
                      </MKTypography>
                    ) : null}
                  </Grid>
                  <MKBox mt={2} ml={3}>
                    <Stack
                      direction="row"
                      alignItems="flex-end"
                      spacing={2}
                      justifyContent="space-evenly"
                    >
                      <MKButton variant="outlined" color="dark" onClick={handleBack}>
                        Back
                      </MKButton>
                      <MKButton
                        variant="gradient"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        Add Paypal Tip
                      </MKButton>
                    </Stack>
                  </MKBox>
                </Grid>
              </MKBox>
            ) : (
              modaltype === LINKTYPE.CALENDLY &&
              activeStep === 1 && (
                <MKBox component="form" role="form" mt={5} ml={4} width="100%">
                  <Grid container spacing={3} sx={{ marginBottom: 5 }}>
                    <Grid item xs={12} md={8} lg={8} xl={8}>
                      <MKInput
                        name="calendlyLink"
                        value={validation.values.calendlyLink}
                        onChange={validation.handleChange}
                        error={
                          !!(validation.touched.calendlyLink && validation.errors.calendlyLink)
                        }
                        type="text"
                        label="Enter Your Calendly Link"
                        fullWidth
                      />
                      {validation.touched.calendlyLink && validation.errors.calendlyLink ? (
                        <MKTypography variant="caption" color="error">
                          {validation.errors.calendlyLink}
                        </MKTypography>
                      ) : null}
                    </Grid>
                    <MKBox mt={2} ml={3}>
                      <Stack
                        direction="row"
                        alignItems="flex-end"
                        spacing={2}
                        justifyContent="space-evenly"
                      >
                        <MKButton variant="outlined" color="dark" onClick={handleBack}>
                          Back
                        </MKButton>
                        <MKButton
                          variant="gradient"
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          Add CALENDLY Link
                        </MKButton>
                      </Stack>
                    </MKBox>
                  </Grid>
                </MKBox>
              )
            )}
            {activeStep === 1 && modaltype === LINKTYPE.MENU_LISTING && (
              <MKBox component="form" role="form" mt={5} ml={4} width="100%">
                <Grid container spacing={3} sx={{ marginBottom: 5 }}>
                  <Grid item xs={12} md={8} lg={8} xl={8}>
                    <MenuListingForm validation={validation} /> {/* Pass validation prop */}
                    <MKBox mt={2}>
                      <MKButton
                        sx={{ marginRight: "4px" }}
                        variant="outlined"
                        color="dark"
                        onClick={handleBack}
                      >
                        Back
                      </MKButton>
                      <MKButton
                        variant="gradient"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {loading2 ? <MKSpinner color="white" size={20} /> : "Save Your Menu"}
                      </MKButton>
                    </MKBox>
                  </Grid>
                </Grid>
              </MKBox>
            )}
            {activeStep === 1 && modaltype === LINKTYPE.YOUTUBE_SUB && (
              <MKBox component="form" role="form" mt={5} ml={4} width="100%">
                <Grid container spacing={3} sx={{ marginBottom: 5 }}>
                  <Grid item xs={12} md={8} lg={8} xl={8}>
                    <MKInput
                      name="youtube_channel"
                      value={validation.values.youtube_channel}
                      onChange={validation.handleChange}
                      error={
                        !!(validation.touched.youtube_channel && validation.errors.youtube_channel)
                      }
                      type="text"
                      label="Youtube Channel"
                      placeholder="https://www.youtube.com/c/youtubechannel"
                      fullWidth
                    />
                    {validation.touched.youtube_channel && validation.errors.youtube_channel ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.youtube_channel}
                      </MKTypography>
                    ) : null}
                    <MKBox mt={2}>
                      <MKButton
                        sx={{ marginRight: "4px" }}
                        variant="outlined"
                        color="dark"
                        onClick={handleBack}
                      >
                        Back
                      </MKButton>
                      <MKButton
                        variant="gradient"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        Add Youtube channel
                      </MKButton>
                    </MKBox>
                  </Grid>
                </Grid>
              </MKBox>
            )}
            {activeStep === 1 && modaltype === LINKTYPE.FILE_UPLOAD && (
              <MKBox component="form" role="form" mt={5} ml={4} width="100%">
                <Grid container spacing={3} sx={{ marginBottom: 5 }}>
                  <Grid item xs={12} md={8} lg={8} xl={8}>
                    <MKInput
                      type="file"
                      name="file"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        if (file) {
                          validation.setFieldValue("file", file);
                        }
                      }}
                      error={!!(validation.touched.file && validation.errors.file)}
                      inputProps={{
                        accept: "image/*,.pdf",
                      }}
                      fullWidth
                    />
                    {validation.touched.file && validation.errors.file ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.file}
                      </MKTypography>
                    ) : null}
                    <MKInput
                      name="uploadtitle"
                      value={validation.values.uploadtitle}
                      onChange={validation.handleChange}
                      error={!!(validation.touched.uploadtitle && validation.errors.uploadtitle)}
                      type="text"
                      label="File Title"
                      fullWidth
                      sx={{ mt: 2 }}
                    />
                    {validation.touched.uploadtitle && validation.errors.uploadtitle ? (
                      <MKTypography variant="caption" color="error">
                        {validation.errors.uploadtitle}
                      </MKTypography>
                    ) : null}
                    <MKBox mt={2}>
                      <MKButton
                        sx={{ marginRight: "4px" }}
                        variant="outlined"
                        color="dark"
                        onClick={handleBack}
                      >
                        Back
                      </MKButton>
                      <MKButton
                        variant="gradient"
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        disabled={loading2}
                      >
                        {loading2 ? "Uploading..." : "Upload File"}
                      </MKButton>
                    </MKBox>
                  </Grid>
                </Grid>
              </MKBox>
            )}
          </Grid>
        </ExtraLinkDialog>
      </Grid>
    </Grid>
  );
};

export default UserLink;
