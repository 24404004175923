import { useState, useEffect } from "react";

import PropTypes from "prop-types";

// import material components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

// import Material 2 assets
import appearance from "assets/theme/appearance";

function MKMusic({ title, url, userTheme }) {
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (userTheme === undefined) return;
    const mode = appearance.filter((i) => userTheme === i.mode)[0];
    setStyle({ ...mode });
  }, [userTheme]);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <MKBox mt={2}>
      <Accordion
        sx={{
          backgroundColor: `${style?.btnBackground} !important`,
          borderWidth: 2,
          borderColor: style?.borderColor,
          borderRadius: 60,
        }}
        expanded={open}
        onChange={handleToggle}
        className="mk_accordion link_btn"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: style?.btnTextColor }} />}
          aria-controls="panel1a-content"
          className="mk_accordion_header"
        >
          <MKTypography variant="button" fontWeight="bold" sx={{ color: style?.btnTextColor }}>
            {title}
          </MKTypography>
        </AccordionSummary>
        <AccordionDetails className="mk_accordion_content mk_accordion_content_padding">
          <MKBox sx={{ alignSelf: "center", width: "100%" }}>
            <MKBox className="placeholder shimmer">
              <MKBox className="faux-image-wrapper">
                <MKBox className="faux-image" />
              </MKBox>
              <MKBox className="faux-text" />
              <MKBox className="faux-text short" />
            </MKBox>
          </MKBox>
          {open ? (
            <iframe
              src={url}
              width="100%"
              height={0}
              title={title}
              allow="encrypted-media"
              frameBorder="0"
              loading="lazy"
              onLoad={(e) => {
                e.target.previousSibling.classList.add("hidden");
                e.target.setAttribute("height", "350px");
              }}
            />
          ) : null}

          <MKBox display="flex" justifyContent="center">
            <MKButton
              variant="outlined"
              color="dark"
              size="medium"
              iconOnly
              sx={{ borderRadius: 5 }}
              onClick={handleToggle}
            >
              <CloseOutlined color="dark" />
            </MKButton>
          </MKBox>
        </AccordionDetails>
      </Accordion>
    </MKBox>
  );
}

MKMusic.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  userTheme: PropTypes.string.isRequired,
};

export default MKMusic;
