import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CircularProgress from "@mui/material/CircularProgress";

// Context
import AuthContext from "context/AuthContext";
import { useSnackbar } from "context/SnackbarContext";

// API
import { getCookie, verifyEmail } from "api";

const EmailVerification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state, dispatch } = useContext(AuthContext);
  const { showSnackbar } = useSnackbar();
  const [verificationStatus, setVerificationStatus] = useState("pending"); // pending, success, error
  const [loading, setLoading] = useState(true);
  const [hasAttemptedVerification, setHasAttemptedVerification] = useState(false);

  const validateEmail = useCallback(async () => {
    if (hasAttemptedVerification) return;

    const email = searchParams.get("validateemail");
    const token = searchParams.get("token");

    if (!email || !token) {
      setVerificationStatus("error");
      showSnackbar("Invalid verification link", "error");
      setLoading(false);
      setHasAttemptedVerification(true);
      return;
    }

    try {
      const result = await verifyEmail(email, token);

      if (result?.success) {
        dispatch.updateEmailVerification(true);
        setVerificationStatus("success");
        showSnackbar("Email verified successfully!", "success");
      } else {
        throw new Error(result?.message || "Verification failed");
      }
    } catch (error) {
      console.error("Verification error:", error);
      setVerificationStatus("error");
      showSnackbar(error.message || "Email verification failed", "error");
    } finally {
      setLoading(false);
      setHasAttemptedVerification(true);
    }
  }, [searchParams, dispatch, showSnackbar, hasAttemptedVerification]);

  useEffect(() => {
    validateEmail();
  }, [validateEmail]);

  const handleGoToDashboard = () => {
    navigate("/admin", { replace: true });
  };

  const handleAction = () => {
    if (verificationStatus === "success") {
      navigate("/admin", { replace: true });
    } else {
      navigate("/signin", { replace: true });
    }
  };

  return (
    <MKBox
      component="section"
      display="grid"
      minHeight="100vh"
      alignItems="center"
      bgcolor="grey.100"
    >
      <Container maxWidth="sm">
        <Card sx={{ p: 4, textAlign: "center" }}>
          <MKBox mb={3}>
            {loading ? (
              <CircularProgress size={64} color="primary" />
            ) : verificationStatus === "success" ? (
              <CheckCircleIcon sx={{ fontSize: 64, color: "success.main" }} />
            ) : verificationStatus === "error" ? (
              <ErrorIcon sx={{ fontSize: 64, color: "error.main" }} />
            ) : (
              <MailOutlineIcon sx={{ fontSize: 64, color: "primary.main" }} />
            )}
          </MKBox>

          <MKTypography variant="h3" mb={2}>
            {loading
              ? "Verifying your email..."
              : verificationStatus === "success"
              ? "Email Verified!"
              : "Verification Failed"}
          </MKTypography>

          <MKTypography variant="body1" color="secondary" mb={4}>
            {loading
              ? "Please wait while we verify your email address..."
              : verificationStatus === "success"
              ? "Your email has been successfully verified. You can now access all features of your account."
              : "We couldn't verify your email address. The link might be expired or invalid."}
          </MKTypography>

          <MKButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAction}
            disabled={loading}
          >
            {verificationStatus === "success" ? "Go to Dashboard" : "Sign In"}
          </MKButton>
        </Card>
      </Container>
    </MKBox>
  );
};

export default EmailVerification;
