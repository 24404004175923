/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useContext, useState } from "react";

import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKSpinner from "components/MKSpinner";

// @mui material components
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { TikTokIcon } from "utils";
// form validation with Formik
import { useFormik } from "formik";
// App context
import AuthContext from "context/AuthContext";

// api call
import { getCookie, updateUserProfile } from "api";

const IconSocial = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const reFreshIFrame = () => {
    const iframeEle = document.getElementById("profile-preview");
    iframeEle.contentWindow.location.reload();
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      twitter_id: state.userProfile.twitter_id || "",
      tik_id: state.userProfile.tik_id || "",
      ig_id: state.userProfile.ig_id || "",
      fb_id: state.userProfile.fb_id || "",
      whatsapp_id: state.userProfile.whatsapp_id || "",
      linkedin_id: state.userProfile.linkedin_id || "",
      socialposition: state.userProfile.social_position,
    },
    validate: (values) => {
      const errors = {};
      if (values.fb_id.match(/www.|http|https|ftp|ftps|ssh/g)) {
        errors.fb_id = "Please input your facebook handle only, eg taprr";
      }
      if (values.twitter_id.match(/www.|http|https|ftp|ftps|ssh/g)) {
        errors.twitter_id = "Please input your twitter handle only, eg taprr";
      }
      if (values.tik_id.match(/www.|http|https|ftp|ftps|ssh/g)) {
        errors.tik_id = "Please input your tiktok handle only, eg taprr";
      }
      if (values.ig_id.match(/www.|http|https|ftp|ftps|ssh/g)) {
        errors.ig_id = "Please input your instagram handle only, eg taprr";
      }
      if (!values.whatsapp_id.match(/^\d{0,18}$/)) {
        errors.whatsapp_id = "Please input a valid whatsapp number";
      }
      if (values.linkedin_id.match(/www.|http|https|ftp|ftps|ssh/g)) {
        errors.linkedin_id = "Please input your linkedin handle only, eg taprr";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setLoading(!loading);
      const jtoken = getCookie("taprr-token");
      const { twitter_id, tik_id, ig_id, fb_id, socialposition, whatsapp_id, linkedin_id } = values;
      const { name, email, bio, theme, contactnumber } = state?.userProfile;
      const res = await updateUserProfile({
        name,
        useremail: email,
        contactnumber,
        bio,
        theme,
        jtoken,
        fbid: fb_id || "",
        twitterid: twitter_id || "",
        igid: ig_id || "",
        tikid: tik_id || "",
        whatsappid: whatsapp_id || "",
        linkedinid: linkedin_id || "",
        socialposition,
      });
      if (res.success) {
        setLoading(false);
        dispatch.getDetails(res.response);
        dispatch.changeTheme(theme);
        reFreshIFrame();
      }
    },
  });

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <MKBox p={2}>
          <MKBox component="form" role="form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <MKBox mb={2}>
                  <MKInput
                    name="twitter_id"
                    value={validation.values.twitter_id}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder="username"
                    fullWidth
                    error={!!(validation.touched.twitter_id && validation.errors.twitter_id)}
                    InputProps={{
                      className: "fanbies_input_placeholder",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ padding: "0" }}>
                          <TwitterIcon fontSize="medium" sx={{ color: "#000000" }} /> twitter.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validation.touched.twitter_id && validation.errors.twitter_id ? (
                    <MKTypography variant="caption" color="error">
                      {validation.errors.twitter_id}
                    </MKTypography>
                  ) : null}
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <MKBox mb={2}>
                  <MKInput
                    name="fb_id"
                    value={validation.values.fb_id}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder="username"
                    fullWidth
                    error={!!(validation.touched.fb_id && validation.errors.fb_id)}
                    InputProps={{
                      className: "fanbies_input_placeholder",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ padding: "0" }}>
                          <FacebookOutlinedIcon fontSize="medium" sx={{ color: "#000000" }} />{" "}
                          facebook.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validation.touched.fb_id && validation.errors.fb_id ? (
                    <MKTypography variant="caption" color="error">
                      {validation.errors.fb_id}
                    </MKTypography>
                  ) : null}
                </MKBox>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <MKBox mb={2}>
                  <MKInput
                    name="tik_id"
                    value={validation.values.tik_id}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder="username"
                    fullWidth
                    error={!!(validation.touched.tik_id && validation.errors.tik_id)}
                    InputProps={{
                      className: "fanbies_input_placeholder",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ padding: "0" }}>
                          <TikTokIcon width="24px" height="24px" /> tiktok.com/@
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validation.touched.tik_id && validation.errors.tik_id ? (
                    <MKTypography variant="caption" color="error">
                      {validation.errors.tik_id}
                    </MKTypography>
                  ) : null}
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <MKBox mb={2}>
                  <MKInput
                    name="ig_id"
                    value={validation.values.ig_id}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder="username"
                    fullWidth
                    error={!!(validation.touched.ig_id && validation.errors.ig_id)}
                    InputProps={{
                      className: "fanbies_input_placeholder",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ padding: "0" }}>
                          <InstagramIcon fontSize="medium" sx={{ color: "#000000" }} />{" "}
                          instagram.com/
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validation.touched.ig_id && validation.errors.ig_id ? (
                    <MKTypography variant="caption" color="error">
                      {validation.errors.ig_id}
                    </MKTypography>
                  ) : null}
                </MKBox>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <MKBox mb={2}>
                  <MKInput
                    name="whatsapp_id"
                    value={validation.values.whatsapp_id}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder="+1 xxx xxx xxx"
                    fullWidth
                    error={!!(validation.touched.whatsapp_id && validation.errors.whatsapp_id)}
                    InputProps={{
                      className: "fanbies_input_placeholder",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ padding: "0" }}>
                          <WhatsAppIcon fontSize="medium" sx={{ color: "#000000" }} />{" "}
                          whatsapp.com/+
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validation.touched.whatsapp_id && validation.errors.whatsapp_id ? (
                    <MKTypography variant="caption" color="error">
                      {validation.errors.whatsapp_id}
                    </MKTypography>
                  ) : null}
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6} sm={6}>
                <MKBox mb={2}>
                  <MKInput
                    name="linkedin_id"
                    value={validation.values.linkedin_id}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder="username"
                    fullWidth
                    error={!!(validation.touched.linkedin_id && validation.errors.linkedin_id)}
                    InputProps={{
                      className: "fanbies_input_placeholder",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ padding: "0" }}>
                          <LinkedInIcon fontSize="medium" sx={{ color: "#000000" }} />{" "}
                          linkedin.com/in/
                        </InputAdornment>
                      ),
                    }}
                  />
                  {validation.touched.linkedin_id && validation.errors.linkedin_id ? (
                    <MKTypography variant="caption" color="error">
                      {validation.errors.linkedin_id}
                    </MKTypography>
                  ) : null}
                </MKBox>
              </Grid>
            </Grid>
            <MKBox mt={2}>
              <MKTypography textAlign="start" variant="body2" fontWeight="bold">
                Position
              </MKTypography>
              <MKTypography textAlign="start" variant="button">
                Positionning media at the :
              </MKTypography>
              <MKBox>
                <FormControl>
                  <RadioGroup
                    name="socialposition"
                    onChange={validation.handleChange}
                    value={validation.values.socialposition}
                  >
                    <FormControlLabel value="TOP" control={<Radio />} label="Top" />
                    <FormControlLabel value="BOTTOM" control={<Radio />} label="Bottom" />
                  </RadioGroup>
                </FormControl>
                {validation.touched.socialposition && validation.errors.socialposition ? (
                  <MKTypography variant="caption" color="error">
                    {validation.errors.socialposition}
                  </MKTypography>
                ) : null}
              </MKBox>
            </MKBox>
            <MKBox mt={2} mb={1}>
              <MKButton
                variant="gradient"
                onClick={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                color="primary"
              >
                {loading ? <MKSpinner color="white" size={20} /> : "Save"}
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Grid>
    </Grid>
  );
};

export default IconSocial;
