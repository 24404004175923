/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";

import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKSpinner from "components/MKSpinner";
import MKDeleteModal from "components/MKDeleteModal";
import MKModal from "components/MKModal";
import MKInput from "components/MKInput";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Grid } from "@mui/material";

// context
import AuthContext from "context/AuthContext";

// Form validation
import * as Yup from "yup";
import { useFormik } from "formik";

// api call
import { deleteAccount, updateUserPasswordById, sendFeedback, getCookie } from "api";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};
const Settings = () => {
  const { state, dispatch } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [feedbackValue, setFeedbackValue] = useState(1.5);
  const [hover, setHover] = useState(-1);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [open, setOpen] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseMssg, setResponseMssg] = useState("");
  const [openToolSnack, setOpenToolSnack] = useState({ open: false, Transition: Fade });
  const [feedbackMssg, setFeedbackMssg] = useState("");
  const [responseFeedbackMssg, setResponseFeedbackMssg] = useState("");
  const [submitedForm, setSubmittedForm] = useState(false);
  const [openPaymentMethodModal, setPaymentMethodModal] = useState(false);

  const token = getCookie("taprr-token");

  const deleteUserAccount = async () => {
    if (navigator.onLine) {
      const { rand_: userId } = state?.userProfile;
      setOpen(!open);
      const req = await deleteAccount(token, userId);
      if (req.success) {
        setOpen(false);
        dispatch.signOut();
      } else {
        setOpen(false);
        setError("Something went wrong. Please try again.");
      }
    } else {
      setError("You're offline. Please check your network connection...");
    }
  };

  const logOut = (e) => {
    e.preventDefault();
    if (navigator.onLine) {
      setIsLoading(!isLoading);
      setTimeout(() => {
        setIsLoading(false);
        dispatch.signOut();
      }, 2000);
    } else {
      setError("You're offline. Please check your network connection...");
    }
  };

  const getLabelText = (e) => `${e} Star${e !== 1 ? "s" : ""}, ${labels[e]}`;

  const handelFeedbackSubmit = async () => {
    const { username } = state?.userProfile;
    if (feedbackMssg && feedbackValue) {
      const userFeedback = {
        username,
        message: feedbackMssg,
        rating: getLabelText(feedbackValue),
      };
      const res = await sendFeedback(userFeedback);
      if (res?.success) {
        setResponseFeedbackMssg("Thank you for the feedback.");
        setSubmittedForm(true);
      }
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validate: (values) => {
      const errors = {};
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "Password doesn't match. Try again";
      }
      return errors;
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Enter your password"),
    }),
    onSubmit: async (values) => {
      if (values.confirm_password === values.password) {
        const { rand_: userId } = state?.userProfile;
        const userObject = {
          password: values.password,
          uid: userId,
          jwtoken: token,
        };
        const res = await updateUserPasswordById(userObject);
        if (!res?.success) {
          setError(res?.message);
          return;
        }
        if (res.success) {
          setResponseMssg(res.message);
          setOpenToolSnack({
            open: true,
            Fade,
          });
        }
      }
    },
    validateOnChange: true,
  });

  const restRequest = async () => {
    validation.handleSubmit();

    if (!validation.isValid) return;

    setOpenResetModal(false);
  };

  const handleToggle = () => setOpenAccordion(!openAccordion);

  const handleClose = () => {
    setOpenToolSnack({
      ...openToolSnack,
      open: false,
    });
    setResponseMssg("");
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} sm={12}>
        <Snackbar
          open={openToolSnack.open}
          onClose={handleClose}
          TransitionComponent={openToolSnack.Transition}
          message={responseMssg}
          className="snackBar_container"
        />
        <MKDeleteModal
          title="Delete User"
          message="This action is irreversible. Do you want to perform this action ?"
          isOpen={open}
          confirmDelete={deleteUserAccount}
          cancelAction={setOpen}
        />
        <MKTypography textAlign="start" mt={2} mb={2} className="hidden">
          Payout Details: Total Earned Payment Method.
        </MKTypography>
        <MKBox
          color="white"
          bgColor="white"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          className="hidden" // hide payment options here
        >
          <MKBox mt={2} mb={2}>
            <MKBox mb={2}>
              <MKTypography variant="h6" fontWeight="bold">
                Add your preferred payment method for withdrawals from all your total earned.
              </MKTypography>
            </MKBox>
            <MKButton
              variant="outlined"
              color="primary"
              mb={2}
              onClick={() => setPaymentMethodModal(!openPaymentMethodModal)}
            >
              Payout option
            </MKButton>
          </MKBox>
        </MKBox>
        <MKTypography className="text-center" mt={2} mb={2}>
          Action account for Taprr
        </MKTypography>
        <MKBox color="white" bgColor="white" borderRadius="lg" shadow="lg" opacity={1} p={2}>
          <MKBox mt={2} mb={2}>
            <Stack direction="row" spacing={3}>
              <MKButton
                variant="outlined"
                color="secondary"
                size="small"
                mb={2}
                onClick={() => setOpenResetModal(!open)}
              >
                Update password
              </MKButton>
              <MKButton variant="gradient" color="error" onClick={logOut} mb={2} size="small">
                {isLoading ? <MKSpinner color="white" size={20} /> : "Logout"}
              </MKButton>
            </Stack>
            {error ? (
              <MKBox mt={2} mb={1}>
                <MKTypography variant="button" color="error" fontWeight="bold">
                  {error}
                </MKTypography>
              </MKBox>
            ) : null}
          </MKBox>
        </MKBox>
        <MKTypography textAlign="start" mt={2} mb={2}>
          Send your feedback about Taprr
        </MKTypography>
        <MKBox color="white" bgColor="white" borderRadius="lg" shadow="lg" opacity={1}>
          <Accordion
            expanded={openAccordion}
            onChange={handleToggle}
            className="mk_accordion_tool"
            sx={{ padding: "16px 0px" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="dark" className="mediumIcon" />}
              aria-controls="panel1a-content"
            >
              <MKTypography variant="body2" fontWeight="bold">
                Feel free to drop us your feedback
              </MKTypography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent="center">
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <MKTypography variant="body2" color="dark">
                    We value your feedback and are open to creating new tools to making your Taprr
                    experience better.
                  </MKTypography>
                  <MKBox component="form" role="form">
                    <MKBox mt={6} display="flex" alignItems="center">
                      <Rating
                        name="hover-feedback"
                        value={feedbackValue}
                        precision={0.5}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setFeedbackValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={<StarIcon fontSize="inherit" style={{ opacity: 0.55 }} />}
                      />
                      {feedbackValue !== null && (
                        <MKBox sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : feedbackValue]}</MKBox>
                      )}
                    </MKBox>
                    <MKBox mt={6} mb={6}>
                      <MKInput
                        type="text"
                        multiline
                        rows={10}
                        name="message"
                        label="Please tell us your reasons for giving this score here and your suggestions"
                        onChange={(e) => setFeedbackMssg(e.target.value)}
                        fullWidth
                      />
                      {responseFeedbackMssg ? (
                        <MKTypography variant="button" color="dark">
                          {responseFeedbackMssg}
                        </MKTypography>
                      ) : null}
                    </MKBox>
                    <MKBox px={6} mb={6} textAlign="center">
                      <MKButton
                        color="primary"
                        variant="outlined"
                        onClick={handelFeedbackSubmit}
                        disabled={submitedForm}
                      >
                        Send FeedBack
                      </MKButton>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </MKBox>
        <MKTypography textAlign="start" mt={2} mb={2}>
          Danger zone
        </MKTypography>
        <MKBox color="white" bgColor="white" borderRadius="lg" shadow="lg" opacity={1} p={2}>
          <MKBox mb={2}>
            <MKTypography variant="button" fontWeight="bold">
              Click the button below if you would like to delete your account.
            </MKTypography>
          </MKBox>
          <MKButton variant="outlined" size="small" color="error" onClick={() => setOpen(!open)}>
            Delete account?
          </MKButton>
          {error ? (
            <MKBox mt={2} mb={1}>
              <MKTypography variant="button" color="error" fontWeight="bold">
                {error}
              </MKTypography>
            </MKBox>
          ) : null}
        </MKBox>
        <MKModal
          open={openResetModal}
          title="Reset Password"
          confirm={restRequest}
          cancel={setOpenResetModal}
          maxWidth={750}
        >
          <MKBox component="form" role="form" p={2}>
            <MKBox mb={2}>
              <MKInput
                name="password"
                value={validation.values.password || ""}
                onChange={validation.handleChange}
                type="password"
                label="Password"
                fullWidth
                error={!!(validation.touched.password && validation.errors.password)}
              />
              {validation.touched.password && validation.errors.password ? (
                <MKTypography variant="button" color="error">
                  {validation.errors.password}
                </MKTypography>
              ) : null}
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                name="confirm_password"
                value={validation.values.confirm_password || ""}
                onChange={validation.handleChange}
                type="password"
                label="Confirm Password"
                fullWidth
                error={
                  !!(validation.touched.confirm_password && validation.errors.confirm_password)
                }
              />
              {validation.touched.confirm_password && validation.errors.confirm_password ? (
                <MKTypography variant="button" color="error">
                  {validation.errors.confirm_password}
                </MKTypography>
              ) : null}
            </MKBox>
          </MKBox>
        </MKModal>
      </Grid>
    </Grid>
  );
};

export default Settings;
