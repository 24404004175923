import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "context/AuthContext";
import { getCookie } from "api";

const AuthRedirect = ({ children }) => {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const jtoken = getCookie("taprr-token");

  useEffect(() => {
    if (!state?.isSignout && jtoken) {
      navigate("/admin", { replace: true });
    }
  }, [state?.isSignout, jtoken, navigate]);

  return children;
};

export default AuthRedirect;
